import request from 'SRC/utils/request'

const AuthApi = {
  Login: '/auth/api/Login',
  Logout: '/auth/api/logout',
  Verify: '/auth/api/Login/Verify',
  AADLogin: '/auth/api/AAD/Costom',
  AADUsers: '/auth/api/AAD/Users',
  AdminUser: 'auth/api/Admin/users'
}

export function authLogin (parameter: any) {
  return request({
    url: AuthApi.Login,
    method: 'post',
    data: parameter
  })
}

export function authLogout () {
  return request({
    url: AuthApi.Logout,
    method: 'post',
  })
}

export function authVerify(){
  return request({
    url:AuthApi.Verify,
    method:'get'
  })
}

export function aadLogin (token: string | null) {
  return request({
    url: AuthApi.AADLogin,
    method: 'get',
    headers: {
      Authorization:  `Bearer ${token}`
    }
  })
}

export function getAADUsers(){
  return request({
    url:AuthApi.AADUsers,
    method:'get'
  })
}

export function addUsers (parameter: any) {
  return request({
    url: AuthApi.AdminUser,
    method: 'post',
    data: parameter
  })
}

export function deleteUsers (parameter: any) {
  return request({
    url: AuthApi.AdminUser,
    method: 'delete',
    data: parameter
  })
}