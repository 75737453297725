function UnityAvatar(unityConfig, onReadyHandler) {
  /* --------------------------------------------------------------- */
  var buildUrl = unityConfig.buildUrl;
  var loaderUrl = buildUrl + "/webgl.loader.js";
  var config = {
    dataUrl: buildUrl + "/webgl.data.unityweb",
    frameworkUrl: buildUrl + "/webgl.framework.js.unityweb",
    codeUrl: buildUrl + "/webgl.wasm.unityweb",
    streamingAssetsUrl: "StreamingAssets",
    companyName: "DefaultCompany",
    productName: "huduner",
    productVersion: "0.1",
  };
  var isAppReady = false;
  var isDispose = false;
  var container = document.querySelector("#unity-container");
  var canvas = document.querySelector("#unity-canvas");
  var loadingBar = document.querySelector("#unity-loading-bar");
  var progressBarFull = document.querySelector("#unity-progress-bar-full");
  // var fullscreenButton = document.querySelector("#unity-fullscreen-button");
  var mobileWarning = document.querySelector("#unity-mobile-warning");

  if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
    container.className = "unity-mobile";
    config.devicePixelRatio = 1;
    mobileWarning.style.display = "block";
    setTimeout(() => {
      mobileWarning.style.display = "none";
    }, 5000);
  } else {
    // canvas.style.width = "100vw";
    // canvas.style.height = "100vh";
  }
  //loadingBar.style.display = "block";

  var unityGame = null;
  var script = document.createElement("script");
  script.src = loaderUrl;
  script.onload = () => {
    createUnityInstance(canvas, config, (progress) => {
      progressBarFull.style.width = 100 * progress + "%";
      // console.log(progress);
    }).then((unityInstance) => {

      unityGame = unityInstance;
      loadingBar.style.display = "none";
      if (isDispose) {
        console.log("disposed");
        unityGame.Quit();
        unityGame = null;
        window.onAction = null;
        window.OnAppReady = null;
        window.OnVisemeProcessReady = null;
      }
    }).catch((message) => {
      alert(message);
    });
  };
  document.body.appendChild(script);

  window.addEventListener('message', function (event) {
    console.log('received response: ', event.data);
    const { type, data } = event.data;
    if (window[type]) {
      window[type](data);
    }
  }, false)

  /* API */
  let isReady = false;
  this.prepareTalk = (visemeRes) => {
    isReady = false;
    var rawVisemeSequence = JSON.stringify(visemeRes);
    console.log("--- Process Raw Viseme Sequence ---");
    unityGame.SendMessage('avatar', 'prepareTalk', rawVisemeSequence);

    while (!isReady) {
      setTimeout(100);
    }

    return "Already processed";
  }

  this.stopTalk = () => {
    console.log("--- Stop Talking ---");
    unityGame.SendMessage('avatar', 'idle');
    unityGame.SendMessage('avatar', 'stopTalk');
  }

  this.beginTalk = () => {
    console.log("--- Begin Talking ---");
    unityGame.SendMessage('avatar', 'talk');
    unityGame.SendMessage('avatar', 'beginTalk');
  }

  this.animAct = (index) => {
    console.log("--- Act ---");
    console.log(index);
    unityGame.SendMessage('avatar', 'act', Number(index));
  }

  // window.prepareTalk = this.prepareTalk;
  // window.stopTalk = this.stopTalk;
  // window.beginTalk = this.beginTalk;
  // window.act = this.animAct;

  /* js function for unity */

  this.OnAppReady = () => {
    console.log('--- Load Model Ready ---');
    isAppReady = true;
    console.log(isAppReady)
    if (onReadyHandler) {
      onReadyHandler();
    }

    //window.parent.postMessage({type:'loadModelEnd',data:{}}, '*');
  }

  this.OnVisemeProcessReady = () => {
    isReady = true;
    console.log('--- viseme process Ready ---');
    //window.parent.postMessage({type:'visemeprocessEnd',data:{}}, '*');
  }

  this.onAction = (id, state) => {
    console.log('on action: ' + id + ' status:' + state);
  }

  window.onAction = this.onAction;
  window.OnAppReady = this.OnAppReady;
  window.OnVisemeProcessReady = this.OnVisemeProcessReady;

  this.disposeThis = () => {
    isDispose = true;
    if (unityGame) {
      unityGame.Quit(() => {
        console.log("disposed")

        unityGame = null;
        window.onAction = null;
        window.OnAppReady = null;
        window.OnVisemeProcessReady = null;
      });
    }
  }
}

export { UnityAvatar }