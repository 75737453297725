import React, { useEffect, useState } from "react";
import { Select, Popover, Input, Button, Card, Form, FloatButton } from "antd";
import * as echarts from "echarts";
import _ from "lodash";
import {
  getDataStoreMgmt,
  getGraphScenarios,
  getGraphSearchProperty,
  getGraphRelations,
  getConfigEntitycolor,
  getGraphVisulize,
  getGraphSearch,
  postSmartkgBot,
} from "SRC/api/smartkg";
import {
  SearchOutlined,
  ArrowRightOutlined,
  MessageOutlined,
  UserOutlined,
  RobotOutlined,
} from "@ant-design/icons";
import { globalMessage } from "SRC/components/messagebar";
import "./index.less";

export interface IColorProps {
  name: string;
  color: string;
}

export interface IHistroyProps {
  from: string;
  info: string;
  key: number;
  success?: boolean;
}

const selectStyle = {
  width: 228,
};

export const SearchDisplay: React.FunctionComponent = () => {
  const [form] = Form.useForm();

  const [histroyList, setHistroyList] = useState<IHistroyProps[]>([]);
  const [datastoreList, setDatastoreList] = useState([]);
  const [scenariosList, setScenariosList] = useState([]);
  const [selectDataStore, setSelectDataStore] = useState("");
  const [charts, setCharts] = useState<any>(undefined);
  const [edges, setEdges] = useState<any>([]);
  const [nodes, setNodes] = useState<any>([]);
  const [colorList, setColorList] = useState<IColorProps[]>([]);
  const [selectSce, setSelectSce] = useState("");
  const [keyword, setKeyword] = useState("");
  const [searching, setSearching] = useState(false);
  const [list, setList] = useState<any[]>([]);
  const [showList, setShowList] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [currentText, setCurrentText] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [botLoading, setBotLoading] = useState(false);
  const [loadingDatastore, setLoadingDatastore] = useState(false);
  const [loadingScenarios, setLoadingScenarios] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDataStore();
  }, []);

  useEffect(() => {
    setCharts(echarts.init(document.getElementById("echart")));
    if (charts) {
      process();
      generate();
    }
  }, [nodes, edges]);

  useEffect(() => {
    const historyDiv = document.getElementById("history");
    historyDiv?.scroll({ top: historyDiv.scrollHeight, behavior: "smooth" });
  }, [histroyList]);

  const generate = () => {
    var option = {
      title: {
        top: "bottom",
        left: "right",
      },
      tooltip: {
        trigger: "item",
        formatter: (params, ticket) => {
          if (params.data.fullname === undefined) {
            return;
          }
          let str = "";
          for (let count = 0; count < params.data.fullname.length; count++) {
            str += params.data.fullname[count];
            if (count % 20 === 0 && count != 0) {
              str += "<br/>";
            }
          }
          return str;
        },
        textStyle: {
          width: "100px",
        },
        extraCssText: "text-align:left;",
      },
      draggable: true,
      series: [
        {
          nodeScaleRatio: 0,
          zoom: 1,
          animation: false,
          name: "Les Miserables",
          type: "graph",
          edgeSymbol: ["", "arrow"],
          focusNodeAdjacency: true,
          layout: "force",
          force: {
            initLayout: false,
            layoutAnimation: false,
            repulsion: 300,
            edgeLength: 140,
            gravity: 0.1,
          },
          edgeLabel: {
            show: true,
          },
          data: nodes,
          links: edges,
          roam: true,
          label: {
            color: "#000",
            normal: {
              position: "right",
            },
          },
          lineStyle: {
            normal: {
              curveness: 0.2,
            },
          },
        },
      ],
    };
    charts.setOption(option);
    if (charts) {
      charts.off("click");
    }
    charts.on("click", { dataType: "node" }, (e) => {
      charts.showLoading({
        text: "正在加载数据",
        color: "none",
      });
      getChildNode(e.data)
        .then(() => {
          option.series[0].data = nodes;
          option.series[0].links = edges;
          charts.setOption(option);
          charts.resize({
            width: "auto",
          });
          charts.hideLoading();
        })
        .catch((err) => {
          globalMessage(err);
          charts.hideLoading();
        });
    });
  };

  const getDataStore = () => {
    setLoadingDatastore(true)
    getDataStoreMgmt()
      .then((res: any) => {
        const newDatastoreList = res.datastoreNames.map((item) => {
          return {
            value: item,
            label: item,
          };
        });
        setDatastoreList(newDatastoreList);
      })
      .catch((err) => {
        globalMessage(err);
      })
      .finally(() =>  setLoadingDatastore(false))
  };

  const getScenarios = (value) => {
    setLoadingScenarios(true)
    getGraphScenarios(value)
      .then((res: any) => {
        if (res.scenarioNames) {
          const newList = res.scenarioNames.map((item) => {
            return {
              value: item,
              label: item,
            };
          });
          setScenariosList(newList);
        } else {
          globalMessage(res.responseMessage);
        }
      })
      .catch((err) => {
        globalMessage(err);
      })
      .finally(() => setLoadingScenarios(false))
  };

  const send = () => {
    setHistroyList((histroyList) => {
      return [
        ...histroyList,
        {
          from: "user",
          info: currentText,
          key: new Date().valueOf(),
        }
      ]
    });
    setCurrentText("");
    setBotLoading(true);
    const params = {
      userId: "",
      sessionId: sessionId,
      datastoreName: selectDataStore,
      query: currentText,
    };
    postSmartkgBot(params)
      .then((res: any) => {
        setSessionId(res.sessionId);
        setHistroyList((histroyList) => {
          return [
            ...histroyList,
            {
              from: "bot",
              info: res.result.responseMessage,
              key: new Date().valueOf(),
              success: res.result.success,
            }
          ]
        });
      })
      .catch((err) => {
        globalMessage(err);
      })
      .finally(() => setBotLoading(false));
  };

  const changeDataStore = (value: string) => {
    setSelectDataStore(value);
    getScenarios(value);
    setKeyword("");
    setSelectSce("");
  };

  const getScenColor = (value: string) => {
    setSelectSce(value);
    getConfigEntitycolor(selectDataStore, value)
      .then((res: any) => {
        const newColorList = Object.keys(res.entityColorConfig).map((item) => {
          return {
            name: item,
            color: res.entityColorConfig[item],
          };
        });
        setColorList(newColorList);
      })
      .catch((err) => {
        globalMessage(err);
      });
  };

  const getChildNode = (node) => {
    let promise = new Promise<void>((resolve, reject) => {
      if (node.info.indexOf("属性") > -1) {
        getGraphSearchProperty(node.displayName, node.name, selectDataStore)
          .then((res: any) => {
            if (!res.relations || !res.nodes) {
              reject(res.responseMessage);
            } else {
              setEdges(res.relations);
              setNodes(res.nodes);
              resolve();
            }
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        getGraphRelations(node.id, selectDataStore)
          .then((res: any) => {
            if (!res.relations || !res.nodes) {
              reject(res.responseMessage);
            } else {
              setEdges(res.relations);
              setNodes(res.nodes);
              resolve();
            }
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
    return promise;
  };

  const process = () => {
    for (let i = 0; i < nodes.length; i++) {
      nodes[i].draggable = false;
      if (nodes[i].name.length > 7) {
        if (!nodes[i].fullname) {
          nodes[i].fullname = nodes[i].name;
          nodes[i].name = nodes[i].name.substring(0, 6) + "...";
        }
      }
      if (nodes[i].info === undefined) {
        nodes[i].info = nodes[i].label;
      }
      nodes[i].label = {
        show: true,
        position: "bottom",
        width: "30",
        color: "#000",
      };
      nodes[i].symbolSize = 30;
      if (nodes[i].name === "true") {
        nodes[i].symbolSize = 10;
        nodes[i].label = { show: false };
      }
      nodes[i].itemStyle = {
        color: "#cccccc",
        borderColor: "#cccccc",
        shadowColor: "rgba(0, 0, 0, 0.5)",
        shadowBlur: 3,
      };
      for (let k = 0; k < colorList.length; k++) {
        if (nodes[i].info === colorList[k].name) {
          nodes[i].itemStyle = {
            color: colorList[k].color,
            borderColor: "#ffffff",
            shadowColor: "rgba(0, 0, 0, 0.5)",
            shadowBlur: 3,
          };
          nodes[i].label.color = colorList[k].color;
        }
      }
    }
    for (let i = 0; i < edges.length; i++) {
      edges[i].source = edges[i].sourceId;
      edges[i].target = edges[i].targetId;
      for (let j = 0; j < nodes.length; j++) {
        if (edges[i].target === nodes[j].id)
          edges[i].lineStyle = {
            width: 2,
            color: "#000000",
          };
      }
      edges[i].label = {
        formatter: edges[i].value,
      };
    }
  };

  const changeScen = () => {
    setLoading(true)
    charts.showLoading({
      text: "正在加载数据",
      color: "none",
    });
    getGraphVisulize(selectDataStore, selectSce)
      .then((res: any) => {
        if (!res.success) {
          globalMessage(res.responseMessage);
        } else {
          setEdges(res.relations);
          setNodes(res.nodes);
        }
      })
      .catch((err) => {
        globalMessage(err);
      })
      .finally(() => {
        charts.hideLoading();
        setLoading(false)
      });
  };

  const gotochartSmallBtn = () => {
    setSearching(true);
    getGraphSearch(selectDataStore, selectSce, keyword)
      .then((res: any) => {
        if (res.nodes === null) {
          setList([]);
          globalMessage(res.responseMessage);
        } else {
          setList(res.nodes);
          getNodes(res.nodes[0].id);
        }
        setShowList(true);
      })
      .catch((err) => {
        globalMessage(err);
      })
      .finally(() => {
        setSearching(false);
      });
  };

  const getNodes = (id) => {
    setCurrentId(id);
    charts.showLoading({
      text: "正在加载数据",
      color: "none",
    });
    getGraphRelations(id, selectDataStore)
      .then((res: any) => {
        if (!res.relations || !res.nodes) {
          globalMessage(res.responseMessage);
        } else {
          setEdges(res.relations);
          setNodes(res.nodes);
        }
      })
      .catch((err) => {
        globalMessage(err);
      })
      .finally(() => {
        charts.hideLoading();
      });
  };

  const renderUserChatContent = (content: string) => {
    return (
      <div className="chat-user">
        <p className="chat-user-input">{content}</p>
        <div className="chat-user-icon">
          <UserOutlined />
        </div>
      </div>
    );
  };

  const renderBotChatContent = (content: IHistroyProps) => {
    return (
      <div className="chat-bot">
        <div className="chat-bot-icon">
          <RobotOutlined />
        </div>
        {content.success ? (
          <pre className="chat-bot-input">{content.info}</pre>
        ) : (
          <p className="chat-bot-input">{content.info}</p>
        )}
      </div>
    );
  };

  const content = () => {
    return (
      <div className="bot-wrap">
        <div className="chat-wrap">
          <div className="chat-title">SmartKG bot</div>
          <div className="history" id="history">
            {histroyList.map((item: any) => {
              if (item.from === "user") {
                return renderUserChatContent(item.info);
              } else if (item.from === "bot") {
                return renderBotChatContent(item);
              }
            })}
          </div>
          <div className="send">
            <Input
              placeholder="请输入..."
              value={currentText}
              suffix={
                <Button
                  icon={<ArrowRightOutlined />}
                  loading={botLoading}
                  disabled={!currentText || botLoading}
                  onClick={send}
                />
              }
              onChange={(e) => {
                setCurrentText(e.target.value);
              }}
              onPressEnter={!currentText || botLoading ? () => null : send}
            />
          </div>
        </div>
      </div>
    );
  };

  const changeListKey = (item) => {
    return {
      __html: item.replace(
        new RegExp(keyword, "g"),
        `<span style='color:red'>${keyword}</span>`
      ),
    };
  };

  return (
    <div className="searchDisplay">
      <div className="chart-page">
        <div id="echart" className="echarts-wrapper"></div>
        <div id="tip">
          <Popover
            placement="topRight"
            content={content}
            trigger="click"
            className="bot-popover"
          >
            <FloatButton
              icon={<MessageOutlined />}
              type="primary"
              style={{ right: 50 }}
            />
          </Popover>
        </div>
        <div className="chart-wrap">
          <div className="chart-select-wrap">
            <Form form={form} layout="inline" onFinish={changeScen}>
              <Form.Item
                name="selectDataStore"
                rules={[{ required: true, message: "请选择数据库" }]}
              >
                <Select
                  placeholder="请选择数据库"
                  style={selectStyle}
                  onChange={changeDataStore}
                  options={datastoreList}
                  loading={loadingDatastore}
                />
              </Form.Item>
              <Form.Item
                name="selectSce"
                rules={[{ required: true, message: "请选择场景" }]}
              >
                <Select
                  placeholder="请选择场景"
                  style={selectStyle}
                  onChange={getScenColor}
                  options={scenariosList}
                  loading={loadingScenarios}
                />
              </Form.Item>
              <Button htmlType="submit" loading={loading}>展示</Button>
            </Form>
          </div>
          <div className="chart-search-wrap">
            <Input
              disabled={!selectDataStore || !selectSce}
              style={{ width: 472 }}
              placeholder={
                !selectSce ? "请先选择数据库和场景" : "请输入实体名称"
              }
              value={keyword}
              prefix={<SearchOutlined className="site-form-item-icon" />}
              suffix={
                <Button
                  icon={<ArrowRightOutlined />}
                  loading={searching}
                  disabled={!keyword || searching}
                  onClick={gotochartSmallBtn}
                />
              }
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              onPressEnter={
                !keyword || searching ? () => null : gotochartSmallBtn
              }
            />
            <Card
              className="search-result-wrap"
              bordered={false}
              style={{ display: !showList ? "none" : "block" }}
            >
              <label className="search-result-info">
                模糊搜索结果信息：共找到{" "}
                <span style={{ color: "#FFCA85" }}>{list.length}</span> 结果
              </label>
              <div className="search-result-lists">
                {list.map((item) => (
                  <p
                    className={
                      item.id === currentId
                        ? "search-result active"
                        : "search-result"
                    }
                    dangerouslySetInnerHTML={changeListKey(item.name)}
                    onClick={() => getNodes(item.id)}
                  ></p>
                ))}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
