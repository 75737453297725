import * as React from 'react'
import './index.less';
import {
  Stack,
  Image,
  IImageProps,
  ImageFit,
  FontSizes,
  IContextualMenuItem,
  ContextualMenuItemType,
  ContextualMenu,
} from '@fluentui/react'
import {
  IPersonaSharedProps,
  Persona,
  PersonaInitialsColor,
  PersonaPresence,
  PersonaSize,
} from '@fluentui/react/lib/Persona'
import { Icon } from '@fluentui/react/lib/Icon'
import { IThemeRules } from '@fluentui/react/lib/ThemeGenerator'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMsal } from "@azure/msal-react"
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setUserInfo } from 'SRC/utils/slice'
import { Logout } from 'SRC/api/login'
export interface IHeaderProps {
  themeRules?: IThemeRules
}
export interface IHeaderState {
  showPanel: boolean
  jsonTheme: string
  powershellTheme: string
  themeAsCode: any
}

const imageProps: IImageProps = {
  imageFit: ImageFit.contain,
  src:
    require('SRC/assets/imgs/gglogo.png'),
  styles: {
    root: {
      width: 77,
      lineHeight: 48,
    },
  },
}

const settingIconStyle = {
  root: {
    width: 80,
    height: 48,
    color: 'white',
    textAlign: 'center',
    lineHeight: 48,
  },
}

const microsoftLogo = {
  root: {
    marginLeft: 24,
    width: '77px',
    display: 'block',
    verticalAlign: 'middle',
  },
}

const pipeFabricStyles = {
  root: {
    textDecoration: 'none',
    color: 'white',
    fontWeight: '600',
    fontSize: FontSizes.mediumPlus,
    marginLeft: 24,
  },
}

const headerStackStyles = {
  root: {
    backgroundColor: '#0078D4',
    minHeight: 48,
    maxWidth: '100%'
  },
}

const personaStyles = {
  primaryText: {
    color: 'white',
    selectors: {
      ':hover': {
        color: 'white',
      },
    },
  },
}

export const Header: React.FunctionComponent = () => {
  const userInfo = useSelector((state: any) => state.nickName.value)
  const { instance } = useMsal()
  const { t } = useTranslation()
  const userPersona: IPersonaSharedProps = {
    text: userInfo !== null ? userInfo : 'user@email.com',
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const linkRef = React.useRef(null)
  const [showContextualMenu, setShowContextualMenu] = React.useState(false)
  const onShowContextualMenu = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault() // don't navigate
      setShowContextualMenu(true)
    },
    [],
  )
  const onHideContextualMenu = React.useCallback(
    () => setShowContextualMenu(false),
    [],
  )
  const contextMenuItem: IContextualMenuItem[] = [
    {
      key: 'UserInfo',
      text: '个人信息',
      onClick: () => {
        window.open('https://atpportal-aivertical.chinanorth3.cloudapp.chinacloudapi.cn/account', '_blank');
      }
    },
    {
      key: 'Logout',
      text: t('Logout') as string,
      onClick: () => {
        Logout().then(res => {
        localStorage.removeItem("isTokenExpired");
        dispatch(setUserInfo(undefined));
        navigate('/Login')
        })
      }
    }
  ]

  return (
    <React.Fragment>
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={headerStackStyles}
      >
        <Stack horizontal verticalAlign="center">
          <Stack.Item styles={microsoftLogo}>
         {/* <Image
              {...imageProps}
              alt="Guoguang Logo"
              aria-label="Guoguang Logo"
            /> */}
          </Stack.Item>
          <Stack.Item
            title="实训平台"
            aria-label="实训平台"
            styles={pipeFabricStyles}
          >
            实践平台
          </Stack.Item>
        </Stack>
        <Stack horizontal verticalAlign="center" horizontalAlign="space-around">
          <div
            className="PersonaWrapper"
            ref={linkRef}
            onClick={onShowContextualMenu}
          >
            <Persona
              aria-label="User icon"
              initialsColor={PersonaInitialsColor.green}
              presence={PersonaPresence.online}
              {...userPersona}
              size={PersonaSize.size28}
              styles={personaStyles}
            />
          </div>
          <ContextualMenu
            items={contextMenuItem}
            hidden={!showContextualMenu}
            target={linkRef}
            onItemClick={onHideContextualMenu}
            onDismiss={onHideContextualMenu}
          />
        </Stack>
      </Stack>
    </React.Fragment>
  )
}
