import React from 'react'
import {
  Stack,
  IStackItemStyles,
} from '@fluentui/react'
import _ from 'lodash'
import { EditableText } from '../editabletext'
import { EditableButton } from '../editablebutton'
import { UploadableImage } from '../uploadableimage'
interface cardPanelProps {
  card: any[]
  setCard:Function
  nodeId:string
}

export const CardPanel: React.FunctionComponent<cardPanelProps> = (props) => {
  const { card,setCard,nodeId } = props
  const CardBubbleStyle: IStackItemStyles = {
    root: {
      width: 300,
      minHeight: 20,
      background: '#FFFFFF',
      boxShadow: '0px 3px 6px 1px rgba(0,0,0,0.05)',
      borderRadius: '0px 10px 10px 10px',
      textAlign: 'left',
      lineHeight: 20,
      opacity: 1,
      marginTop: 10,
      marginBottom: 10,
      fontFamily: 'Microsoft YaHei-Regular, Microsoft YaHei',
      fontWeight: 400,
      color: '#323130',
      wordWrap: 'break-word',
    },
  }
  const findItem:any = (items: any[],id:string) => {
    return items.filter(item => 
       item.itemId === id
      )[0]
  }

  const findNode:any = (items: any[],id:string) => {
    return items.filter(item => 
       item.nodeId === id
      )
  }

  const [curCard,setCurCard]=React.useState(card)
  const [showCard,SetShowCard]=React.useState(findNode(card,nodeId))
  React.useEffect(() => {SetShowCard(findNode(card,nodeId))},[card,nodeId])
  const onSetContent=React.useCallback((content:string,id:string) => {
    let newCard=_.cloneDeep(curCard)
    findItem(newCard,id).content=content
    setCurCard(newCard)
    SetShowCard(findNode(curCard,nodeId))
  },[curCard,nodeId])

  React.useEffect(() => {
    setCard(curCard)
  },[curCard,setCard])

  const renderCards = (item: any, index: number) => {
    switch (item.type) {
      case 'IMAGE':
        return (
          <UploadableImage item={item} setImage={onSetContent} key={'image'+index}/>
        )
      case 'TEXT':
        return (
          <EditableText item={item} setText={onSetContent} key={'text'+index}/>
        )
      case 'BUTTON':
        return (
          <EditableButton item={item} setText={onSetContent} key={'button'+index}/>
        )
    }
  }

  return (
    <Stack styles={CardBubbleStyle}>
      {showCard.map((item:any, index:any) => renderCards(item, index))}
    </Stack>
  )
}
