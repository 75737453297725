import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "df5bbd6c-ab0e-46f5-853d-72c641e48537", //cn:ffce9be8-2d58-4a05-bcd7-c253a9f8ffc4 global:df5bbd6c-ab0e-46f5-853d-72c641e48537
    authority: "https://login.microsoftonline.com/common/", // cn:https://login.partner.microsoftonline.cn/d821ee8e-409c-4e92-8945-2127b8c879d5/ global:https://login.microsoftonline.com/common/
    redirectUri: `${window.location.origin}/Auth`,
    postLogoutRedirectUri: `${window.location.origin}/Login`,
    navigateToLoginRequestUrl: false, // 设置导航到登录请求Url
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me", // cn:https://microsoftgraph.chinacloudapi.cn  global:https://graph.microsoft.com
};
