import React from 'react'
import ReactDOM from 'react-dom/client'
import _ from 'lodash'
import { BrowserRouter } from 'react-router-dom'
import { initializeIcons } from '@fluentui/font-icons-mdl2'
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { msalConfig } from "SRC/components/aad/authConfig"
import { App } from './App'
import store from 'SRC/utils/store';
import { Provider } from 'react-redux';
import './i18n'
import 'SRC/global.css'

initializeIcons()
// 应用aad配置
const config = {
   ...msalConfig,
   auth: {
      ...msalConfig.auth,
      clientId: _.get(window, '_env_.AAD_ID', msalConfig.auth.clientId),
      authority: _.get(window, '_env_.AAD_URL', msalConfig.auth.authority),
   }
}

const msalInstance = new PublicClientApplication(config)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
   <Provider store={store}>
   <BrowserRouter>
      <MsalProvider instance={msalInstance}>
         <App />
      </MsalProvider>
    </BrowserRouter>
    </Provider>

)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
