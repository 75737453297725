import React from 'react';
import Icon, { HomeOutlined } from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { Space } from 'antd';

const mobileLoginSvg = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
  <path id="验证码" d="M21,42A21,21,0,1,1,42,21,21,21,0,0,1,21,42ZM21,1.312A19.688,19.688,0,1,0,40.688,21,19.687,19.687,0,0,0,21,1.312ZM24.938,31.5H17.063a2.625,2.625,0,0,1-2.625-2.625V13.125A2.625,2.625,0,0,1,17.063,10.5h7.875a2.625,2.625,0,0,1,2.625,2.625v15.75A2.625,2.625,0,0,1,24.938,31.5ZM26.25,13.125a1.312,1.312,0,0,0-1.312-1.312H17.063a1.313,1.313,0,0,0-1.312,1.313v15.75a1.313,1.313,0,0,0,1.313,1.313h7.875a1.312,1.312,0,0,0,1.313-1.312Zm-3.281,1.313H19.031a.656.656,0,0,1,0-1.312h3.938a.656.656,0,0,1,0,1.313ZM21,24.938a1.969,1.969,0,1,1-1.969,1.969A1.969,1.969,0,0,1,21,24.938Zm0,2.625a.656.656,0,1,0-.656-.656A.656.656,0,0,0,21,27.563Z" fill="#6e6d79"/>
</svg>
);

const userLoginSvg = () => (
    <svg id="账户" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
    <path id="路径_3" data-name="路径 3" d="M246.513,264.209a5.978,5.978,0,1,0-6.985,0,11.524,11.524,0,0,0-8.012,10.961h1.68a9.825,9.825,0,0,1,19.649,0h1.68A11.524,11.524,0,0,0,246.513,264.209Zm-7.791-4.842a4.3,4.3,0,1,1,4.3,4.3A4.3,4.3,0,0,1,238.722,259.367Z" transform="translate(-222.02 -242.996)" fill="#6e6d79"/>
    <path id="路径_4" data-name="路径 4" d="M21,0A21,21,0,1,0,42,21,21,21,0,0,0,21,0Zm0,40.32A19.32,19.32,0,1,1,40.32,21,19.342,19.342,0,0,1,21,40.32Z" fill="#6e6d79"/>
    </svg>
)

const fileSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g id="文件" transform="translate(-306 -281)">
    <g id="组_1172" data-name="组 1172" transform="translate(195.333 249)">
      <path id="路径_525" data-name="路径 525" d="M112.8,32a.767.767,0,0,0-.56.24.849.849,0,0,0-.24.56V47.2a.767.767,0,0,0,.24.56.79.79,0,0,0,.56.24h11.733a.767.767,0,0,0,.56-.24.79.79,0,0,0,.24-.56V36.533L120.8,32Z" fill="#5acc9b"/>
      <path id="路径_526" data-name="路径 526" d="M644.533,36.533H640.8a.82.82,0,0,1-.8-.8V32Z" transform="translate(-519.2)" fill="#bdebd7"/>
      <path id="路径_527" data-name="路径 527" d="M360.347,388.133l-1.813-2.533h1.253l1.187,1.813,1.24-1.813h1.213l-1.854,2.533,1.947,2.693h-1.267l-1.306-1.92-1.307,1.933H358.4Z" transform="translate(-242.293 -347.707)" fill="#fff"/>
    </g>
    <rect id="矩形_934" data-name="矩形 934" width="16" height="16" transform="translate(306 281)" fill="none"/>
  </g>
</svg>
)

const folderSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g id="数据库" transform="translate(-306 -247)">
    <g id="组_1163" data-name="组 1163" transform="translate(306 171.2)">
      <path id="路径_516" data-name="路径 516" d="M0,77.593A.8.8,0,0,1,.8,76.8H6.273a.8.8,0,0,1,.735.481l.392.907h7.8a.8.8,0,0,1,.8.793v8.325a.8.8,0,0,1-.8.793H.8a.8.8,0,0,1-.8-.793Z" fill="#ffa000"/>
      <path id="路径_517" data-name="路径 517" d="M89.6,249.6m.792,0h11.486a.7.7,0,0,1,.792.78V257.4a.7.7,0,0,1-.792.78H90.392a.7.7,0,0,1-.792-.78V250.38A.7.7,0,0,1,90.392,249.6Z" transform="translate(-88.135 -169.975)" fill="#fff"/>
      <path id="路径_518" data-name="路径 518" d="M0,332.8m.8,0H15.2a.7.7,0,0,1,.8.785v7.846a.7.7,0,0,1-.8.785H.8a.7.7,0,0,1-.8-.785v-7.846a.7.7,0,0,1,.8-.785Z" transform="translate(0 -251.815)" fill="#ffca28"/>
    </g>
    <rect id="矩形_933" data-name="矩形 933" width="16" height="16" transform="translate(306 247)" fill="none"/>
  </g>
</svg>
)


export const MobileLoginIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon  component={mobileLoginSvg} {...props} />
  );

export const UserLoginIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon  component={userLoginSvg} {...props} />
  );

export const FileIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon  component={fileSvg} {...props} />
  );

export const FolderIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon  component={folderSvg} {...props} />
  );