import React, { useState, useEffect } from 'react'
import { Toggle } from '@fluentui/react/lib/Toggle'
import { Stack } from '@fluentui/react/lib/Stack'
import { Table } from 'antd'
import _ from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Dropdown,
  IDropdownStyles,
  IDropdownOption,
  SearchBox,
  ISearchBoxStyles,
  IStackStyles,
  PrimaryButton,
  DefaultButton,
  Pivot,
  PivotItem,
  IPivotStyles,
  TooltipHost,
  TooltipDelay,
  DirectionalHint,
  ActionButton,
  Dialog,
  DialogType,
  Modal,
  getTheme,
  mergeStyleSets,
  IconButton,
  MessageBarType
} from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { globalMessage } from 'SRC/components/messagebar'
import {
  getByMerchantId,
  getSearchByQuestion,
  getSearchByAnswer,
  deleteQuestionAndAnswer,
  editQuestionAndAnswer
}  from 'SRC/api/q&a'
import './index.css'
import { MessageType } from 'microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common/ConnectionMessage'
export interface IScenario {
  id: string,
  isEnabled: boolean,
  questionType: string,
  active: boolean,
  topic: string,
  answers: [],
  questions: []
}

interface IPivotItems {
  key: string,
  povitText?: string | undefined
}


const stackWrapperStyles: IStackStyles = {
  root: {
    padding: '18px 38px'
  }
}

const stackStyles: IStackStyles = {
  root: {
    background: '#fff',
    padding: '10px'
  },
}

const dropdownStyles: Partial<IDropdownStyles> = {
  root: { width: '80px' },
  title: {
    color: "#605E5C",
    borderRadius: '2px',
    borderColor: '#ccc'
  },
  dropdown: {
    ':hover .ms-Dropdown-title': {
      color: "#605E5C",
      borderColor: '#ccc'
    }
  },
  callout: {
    maxHeight: '200px !important',
    overflow: 'auto',
    selectors: {
      '.ms-Callout-main': {
        maxHeight: 'inherit !important'
      }
    }
  }
}

const searchboxStyles: Partial<ISearchBoxStyles> = {
  root: {
    width: 'calc(100% - 120px)',
    borderColor: '#ccc',
    ':hover': {
      borderColor: '#ccc',
    }
 }
}

const pivotStyles: Partial<IPivotStyles> = {
  root: {
    borderBottom: '1px solid #EDEBE9',
    paddingBottom: '5px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-around'
  }
}

const questionStackItemStyles = {
  root: { display: 'flex' }
}

const theme = getTheme()
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '1000px',
    maxHeight: '1000px'
  },
  header: [
    {
      flex: '1 1 auto',
      display: 'flex',
    }
  ],
  body: {
    padding: '0 10px 10px',
    overflowY: 'auto',
    maxHeight: '600px',
    height: '600px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    objectFit: 'contain',
    maxWidth: '900px',
    maxHeight: '580px'
  }
})

const iconButtonStyles = mergeStyleSets({
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
})

export const QASetting: React.FunctionComponent = () => {
  const { t } = useTranslation()
  let navigate = useNavigate()
  dayjs.extend(utc)

  const [searchType, setSearchType] = useState('question')
  const [searchValue, setSearchValue] = useState('')
  const [items, setItems] = useState<any[]>([])
  const [pivotKey, setPivotKey] = useState<string | null>()
  const [answerKey, setAnswerKey] = useState<string | undefined>()
  const [hideDeleteDialog, setHideDeleteDialog] = useState(true)
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState<any>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalImg, setModalImg] = useState('')

  useEffect(() => {
    setLoading(true)
    getByMerchantId()
        .then((res: any) => {
          setItems(res.questionAndAnswerList)
          const newDate = dayjs().utc().format()
          setDate(newDate)
        })
        .catch(err => globalMessage(err || '请求失败'))
        .finally(() => setLoading(false))
  }, [])

  const columns = [
    {
      key: 'buyerQuestion',
      title: t('pages.QAManagement.setting.buyerQuestion'),
      dataIndex: 'buyerQuestion',
      render: (_text: any, item: IScenario) => {
        return (
          <Stack>
            <Stack.Item styles={questionStackItemStyles}>
              <div className='list-question-label'>{t('pages.QAManagement.setting.listQuestionContentTheme')}</div>
              <div>{item.topic}</div>
            </Stack.Item>
            <Stack.Item styles={questionStackItemStyles}>
              <div className='list-question-label'>{t('pages.QAManagement.setting.listQuestionContentQuestion')}</div>
              <ul className='list-question-ul'>
                {item.questions.map((que: any, index: number) => {
                  if (index < 4) {
                    return (
                      <li key={index} className='list-question-li'>{que.languageTextDict.zh}</li>
                    )
                  } else {
                    return null
                  }
                })}
                {item.questions.length > 4 && (
                  <TooltipHost
                    tooltipProps={{
                      onRenderContent: () => (
                        <ul className='list-question-ul'>
                          {item.questions.map((que: any, index: number) => {
                            return (
                              <li key={index} className='list-question-li'>{que.languageTextDict.zh}</li>
                            )
                          })}
                        </ul>
                      ),
                    }}
                    delay={TooltipDelay.zero}
                    id='tooltip'
                    directionalHint={DirectionalHint.bottomCenter}
                    styles={{ root: { display: 'inline-block' } }}
                  >
                    <li className='list-question-li-more'>{t('pages.QAManagement.setting.listQuestionContentMoreQuestion')}</li>
                  </TooltipHost>
                )}
              </ul>
            </Stack.Item>
          </Stack>
        )
      },
    },
    {
      key: 'reply',
      title: t('pages.QAManagement.setting.reply'),
      dataIndex: 'reply',
      render: (_text: any, item: IScenario) => {
        return (
          <Stack>
            {item.answers.map((answer: any) => {
              const dateStatus = answer.effectiveDate < date && answer.expirationDate > date ? 'isActive' : 'notActive'
              const blocksText: any[] = []
              const blocksImage: any[] = []
              answer.responseBlocks.forEach((block: any) => {
                if (block.type === 'text') {
                  blocksText.push(block)
                } else {
                  blocksImage.push(block)
                }
              })
              return (
                <Stack horizontal className='list-answer' styles={{root: {padding: '5px 0px'}}} key={answer.id}>
                  <Stack.Item styles={{root: {display: 'flex', alignItems: 'center', marginRight: '20px'}}}>
                    {
                      answer.alwaysActive ? (
                        <span className='list-status-always'>{t('pages.QAManagement.setting.listReplyContentAlways')}</span>
                      ) : dateStatus === 'isActive' ? (
                        <span className='list-status-isActive'>{t('pages.QAManagement.setting.listReplyContentActive')}</span>
                      ) : (
                        <span className='list-status-notActive'>{t('pages.QAManagement.setting.listReplyContentNotActive')}</span>
                      )
                    }
                  </Stack.Item>
                  <Stack.Item styles={{root: {display: 'flex', alignItems: 'center'}}}>
                    <div className='list-answer-wrapper'>
                      {blocksText.map((block: any, index: number) => {
                        return (
                          <span className='list-answer-span' key={index}>
                            {answer.responseBlocks.length > 1 ? `[${index + 1}]` : ''}
                            {block.languageTextDict[block.inputLanguage]}
                          </span>
                        )
                      })}
                    </div>
                    <div className='list-answer-image-wrapper'>
                      {blocksImage.map((block: any, index: number) => {
                        return (
                          <img
                            className='list-answer-img'
                            src={block.imageUrl}
                            alt=''
                            key={index}
                            onClick={() => showModal(block.imageUrl)}
                          />
                        )
                      })}
                    </div>
                  </Stack.Item>
                </Stack>
              )
            })}
            {
              item.answers.length === 0 && (
                <div>
                  {t('pages.QAManagement.setting.listReplyContentNoInfo')}
                  <span className='list-question-li-more' onClick={() => onEdit(item)}>{t('pages.QAManagement.setting.listReplyContentCreate')}</span>
                </div>
              )
            }
          </Stack>
        )
      }
    },
    {
      key: 'questionType',
      title: t('pages.QAManagement.setting.questionType'),
      dataIndex: 'questionType',
      width: 100,
      render: (_text: any, item: IScenario) => {
        return (
          <Stack style={{textAlign: 'center'}}>{item.questionType}</Stack>
      )}
    },
    {
      key: 'action',
      title: t('pages.QAManagement.setting.listActionContentEdit'),
      dataIndex: 'action',
      width: 100,
      render: (_text: any, item: IScenario) => {
        return (
          <Stack horizontalAlign="center">
            <Toggle checked={item.isEnabled} onChange={(e, checked) => onChangeDetail(item, e, checked)} />
            <ActionButton styles={{textContainer: {color: '#0078D4'}}} onClick={() => onEdit(item)}>
              {t('pages.QAManagement.setting.listActionContentEdit')}
            </ActionButton>
            <ActionButton
              styles={{textContainer: {color: '#F54141'}}}
              onClick={() => {
                setHideDeleteDialog(false)
                setAnswerKey(item.id)
              }}
            >
              {t('pages.QAManagement.setting.listActionContentDelete')}
            </ActionButton>
          </Stack>
        )
      },
    }
  ]

  const onDeprecated = () => {
    deleteQuestionAndAnswer(answerKey)
      .then(_res => {
        const newItems = items.filter(item => item.id !== answerKey)
        setItems(newItems)
        globalMessage('success', MessageBarType.success)
      })
      .catch(err => globalMessage(err))
      .finally(() => setHideDeleteDialog(true))
  }

  const onChangeDetail = (
    item: IScenario,
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    setLoading(true)
    const params = {
      ...item,
      isEnabled: checked
    }
    editQuestionAndAnswer(params)
      .then((_res: any) =>{
        const newItems= _.cloneDeep(items)
        const index = newItems.findIndex(i => i.id === item.id)
        newItems[index] = params
        setItems(newItems)
      })
      .catch(err => globalMessage(err))
      .finally(() => setLoading(false))
  }

  const onEdit = (item: IScenario) => {
    navigate(`QADetail/${item.id}`)
  }

  const createAQ = () => {
    navigate('QADetail')
  }

  const onChangeSearchType = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) : void => {
    setSearchType(String(option?.key))
  }

  const onChangeSearch = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) : void => {
    setSearchValue(newValue || '')
  }

  const onSearch = (newValue: string) => {
    setLoading(true)
    if (newValue === '') {
      getByMerchantId()
        .then((res: any) => {
          setPivotKey('all')
          setItems(res.questionAndAnswerList)
        })
        .catch(err => globalMessage(err))
        .finally(() => setLoading(false))
    } else if (searchType === 'question') {
      getSearchByQuestion(newValue)
        .then((res: any) =>{
          setPivotKey('all')
          setItems(res)
        })
        .catch(err => globalMessage(err))
        .finally(() => setLoading(false))
    } else {
      getSearchByAnswer(newValue)
        .then((res: any) =>{
          setPivotKey('all')
          setItems(res)
        })
        .catch(err => globalMessage(err))
        .finally(() => setLoading(false))
    }
  }

  const changePivotKey = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) : void => {
    const key = item?.props.itemKey
    setPivotKey(key)
    setLoading(true)
    const params = `?functiontype=${key}`
    getByMerchantId(key !== 'all' ? params : '')
      .then((res: any) => {
        setItems(res.questionAndAnswerList)
      })
      .catch(err => globalMessage(err))
      .finally(() => setLoading(false))
  }

  const hideModal = () => {
    setIsModalOpen(false)
  }

  const showModal = (img: string) => {
    setModalImg(img)
    setIsModalOpen(true)
  }

  const options: IDropdownOption[] = [
    { key: 'question', text: t('pages.QAManagement.setting.searchOptionQuestion') },
    { key: 'answer', text: t('pages.QAManagement.setting.searchOptionAnswer') }
  ];
  
  const pivotItems: IPivotItems[] = [
    {
      key: 'all',
      povitText: t('pages.QAManagement.setting.povitTextAll')
    },
    {
      key: 'chat',
      povitText: t('pages.QAManagement.setting.povitTextChat')
    },
    {
      key: 'commodityProblem',
      povitText: t('pages.QAManagement.setting.commodityProblem')
    },
    {
      key: 'promotions',
      povitText: t('pages.QAManagement.setting.promotions')
    },
    {
      key: 'purchaseOperation',
      povitText: t('pages.QAManagement.setting.purchaseOperation')
    },
    {
      key: 'logisticIssue',
      povitText: t('pages.QAManagement.setting.logisticIssue')
    },
    {
      key: 'afterSalesQuestion',
      povitText: t('pages.QAManagement.setting.afterSalesQuestion')
    },
    {
      key: 'other',
      povitText: t('pages.QAManagement.setting.other')
    }
  ]

  return (
    <Stack styles={stackWrapperStyles}>
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{root: {marginBottom: '28px'}}}
      >
        <Stack horizontal styles={{root: {width: '50%'}}}>
          <Dropdown
            selectedKey={searchType}
            options={options}
            styles={dropdownStyles}
            onChange={onChangeSearchType}
          />
          <SearchBox
            placeholder={t('pages.QAManagement.setting.searchBoxPlaceholder')}
            onSearch={newValue => onSearch(newValue)}
            onChange={onChangeSearch}
            styles={searchboxStyles}
          />
          <DefaultButton
              styles={{
                root: {
                  padding: '0px',
                  minWidth: '40px',
                  borderLeft: '0px',
                  borderColor: '#ccc'
                }
              }}
              iconProps={{ iconName: 'Forward' }}
              onClick={() => onSearch(searchValue)}
            />
        </Stack>
        <Stack>
          <PrimaryButton
            text={t('pages.QAManagement.setting.btnCreateQA')}
            onClick={createAQ}
          />
        </Stack>
      </Stack>
      <Stack styles={stackStyles}>
        <Pivot
          selectedKey={pivotKey}
          styles={pivotStyles}
          onLinkClick={changePivotKey}
        >
          {
            pivotItems.map((pivot:IPivotItems) => {
              return (
                <PivotItem
                  key={pivot.key}
                  id={pivot.key} 
                  itemKey={pivot.key} 
                  headerText={pivot.povitText} 
                >
                  <Table
                    rowKey="id"
                    className='qa-table-row'
                    columns={columns}
                    dataSource={items}
                    pagination={{pageSize: 4}}
                    loading={loading}
                    bordered
                  />
                </PivotItem>
            )})
          }
        </Pivot>
      </Stack>
      <Dialog
        hidden={hideDeleteDialog}
        onDismiss={() => setHideDeleteDialog(true)}
        dialogContentProps={{
          type: DialogType.normal,
          title: String(t('pages.QAManagement.setting.dialogTitle')),
          closeButtonAriaLabel: 'Close',
          subText: t('pages.QAManagement.setting.dialogSubText'),
        }}
        modalProps={{
          isBlocking: true,
          styles: {
            main: { maxWidth: '450px !important', width: '450px !important' }
          }
        }}
      >
        <div className='dialog-footer'>
          <PrimaryButton
            onClick={onDeprecated}
            text={t('pages.QAManagement.setting.dialogBtnConfirm')}
            styles={{root: {marginRight: '10px'}}}
          />
          <DefaultButton
            onClick={() => setHideDeleteDialog(true)}
            text={t('pages.QAManagement.setting.dialogBtnCancel')}
          />
        </div>
      </Dialog>
      <Modal
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <IconButton
            styles={iconButtonStyles}
            iconProps={{ iconName: 'Cancel' }}
            onClick={hideModal}
          />
        </div>
        <div className={contentStyles.body}>
          <img className={contentStyles.image} src={modalImg} alt='' />
        </div>
      </Modal>
    </Stack>
  )
}
