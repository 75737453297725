import React from 'react'
import { Spin } from 'antd';
import './index.less'

const Loading: React.FunctionComponent = () => {

  return (
    <div className='loading-wrapper'>
      <Spin size="large" />
    </div>
  )
}

export default Loading