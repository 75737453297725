import * as THREE from 'three';
class AnimationController {

    constructor(model, animClips, actionIndices, interval) {
        //Init mixer for model
        this.mixer = new THREE.AnimationMixer(model);
        //Process animation clips to actions
        this.actions = animClips.map(val => {
            return this.mixer.clipAction(val);
        });

        //Restore index of action in idle, talk and act
        this.idleIndices = actionIndices.idleIndices;
        this.talkIndices = actionIndices.talkIndices;
        this.actIndices = actionIndices.actIndices;
        this.idleWeights = this.normalizeWeights(actionIndices.idleWeights);
        this.talkWeights = this.normalizeWeights(actionIndices.talkWeights);

        //Restore current action index
        this.currActionIndex = 2;

        this.isTalk = false;
        this.isAct = false;

        //Set fade out speed
        this.fSpeed = interval;

        //this.actions[this.idleActionIndex].play();
        this.startLoopAnimation();
    }

    normalizeWeights(weights) {
        var sum = 0;
        for (var i = 0; i < weights.length; i++) {
            sum += weights[i];
        }
        return weights.map(val => {
            return val /= sum;
        })
    }

    //[Public Method]
    //update the animation through this.mixer
    updateAnimation(delta) {
        if (this.mixer) {
            this.mixer.update(delta);
        }
    }

    //[Private Method]
    startLoopAnimation() {
        if (!this.isAct) {
            var nextActionIndex;

            if (!this.isTalk) {
                nextActionIndex = this.getRandomWithProb2(this.idleIndices, this.idleWeights);
            } else {
                nextActionIndex = this.getRandomWithProb2(this.talkIndices, this.talkWeights);
            }

            this.transferAction(this.actions[this.currActionIndex], this.fSpeed, this.actions[nextActionIndex]);
            this.currActionIndex = nextActionIndex;

            setTimeout(() => {
                this.startLoopAnimation();
            }, this.actions[nextActionIndex]._clip.duration * 1000 - this.fSpeed * 1000 * 2);

        }

    }

    //[Public Method]
    //Trigger Animation with index
    triggerActionAnimation(targetActionIndex) {
        // from curr action to target action 
        this.isAct = true;
        //console.log(this.currActionIndex);
        this.transferAction(this.actions[this.currActionIndex], this.fSpeed * 10, this.actions[targetActionIndex]);
        this.currActionIndex = targetActionIndex;
        //get back to idle action
        setTimeout(() => {
            this.isAct = false;
            this.startLoopAnimation();
        }, this.actions[targetActionIndex]._clip.duration * 1000 - this.fSpeed * 1000 * 2);
    }

    //[Private Method]
    //Input: 
    //Get random index
    getRandomWithProb(weights) {
        return weights[Math.floor(Math.random() * weights.length)];
    }
    //[Private Method]
    //Input: 
    //Get random index with probability
    getRandomWithProb2(indices, weights) {

        var rand = Math.random();
        for (var i = 0, currProb = 0; i < weights.length; i++) {
            currProb += weights[i];
            if (rand < currProb) {
                return indices[i];
            }
        }
    }

    //[Private Method]
    //Transfer one action to another action
    transferAction(fromAction, fSpeed, toAction) {
        toAction.setLoop(THREE.LoopOnce);
        toAction.reset();
        toAction.play();
        fromAction.crossFadeTo(toAction, fSpeed, false);
    }

    //[Public Method]
    talk() {
        this.isTalk = true;
    }

    //[Public Method]
    idle() {
        this.isTalk = false;
    }

    //[Public Method]
    act(index) {
        if (!this.isAct) {
            this.triggerActionAnimation(index);
        }
    }

};

export { AnimationController };
