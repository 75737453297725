import request from 'SRC/utils/request'

const MerchantApi = {
    VirtualSalesConfig: '/merchantmanagement/api/VirtualSalesConfig'
}

export function getLanguages() {
    return request({
        url: MerchantApi.VirtualSalesConfig,
        method: 'get'
    })
}