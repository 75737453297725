import React, {
    useState,
    forwardRef,
    useRef,
    useEffect,
    useImperativeHandle
} from 'react';
import _ from 'lodash'
import {
    Label,
    Stack,
    IButtonStyles,
    TextField,
    ITextFieldStyles,
    PrimaryButton,
    DefaultButton,
    Modal,
    IModalStyles,
    IconButton,
    IIconProps,
    ILabelStyles,
    ChoiceGroup,
    IChoiceGroupOption,
    MessageBarType,
    Spinner,
    SpinnerSize,
    DetailsList,
    SelectionMode,
    DetailsHeader,
    DetailsRow,
    Toggle,
    Dropdown,
    IDropdownOption,
    IDropdownStyles
} from '@fluentui/react';
import { Upload, Tabs, InputNumber } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import type { RcFile } from 'antd/es/upload'
import { useTranslation } from 'react-i18next';
import {
    getThreejsConfig,
    uploadAvatar
} from 'SRC/api/avatarmanagement'
import { uploadImage }  from 'SRC/api/q&a'
import { getAADUsers }  from 'SRC/api/auth'
import { globalMessage } from 'SRC/components/messagebar';
import { AvatarLoader } from 'SRC/pages/avatarManagement'
import { updateMorphTarget, onGetConfig } from './avatar/threejs/ThreejsAvatar'
import './create.css'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

/* styles */
const avatarDivStyles = {
    backgroundColor: '#F2F2F2',
    height: 560,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '5px',
    position: 'relative'
}

const textFieldStyles: Partial<ITextFieldStyles> = {
    fieldGroup: {
        border: '1px solid #DCDFE6'
    },
    field: {
        margin: 16,
        padding: 0
    }
}

const comboBoxLabelStyles: Partial<ILabelStyles> = {
    root: {
        fontSize: 14,
        fontWeight: 400,
        width: 56,
        height: 19,
        textAlign: 'end'
    }
}

const toggleLabelStyles: Partial<ILabelStyles> = {
    root: {
        fontSize: 14,
        fontWeight: 400,
        width: 56,
        height: 19,
        textAlign: 'end',
        padding: 0
    }
}

const buttonStyles: IButtonStyles = {
    root: {
        marginTop: 10,
        borderRadius: '2px',
        width: 80,
        height: 32,
    },

    label: {
        fontSize: 14,
        fontWeight: 400,
        fontFamily: 'Microsoft YaHei-Regular, Microsoft YaHei',
    }
}

const modalStyles: Partial<IModalStyles> = {
    main: {
        width: 1000,
        height: 800
    }
}

interface IAdvancedModal {
    ref: any
    setOpen: Function
    isOpen: boolean
    threejsConfig: any
    setThreejs: Function
    avatarConfig: any
    setAvatarConfig: Function
    morphTargetList: any[],
    aadUsers: string[],
    setMorphTargetList: Function,
    message: any,
    setMessage: Function,
    actionsItems: any[],
    setActionsItems: Function
}

export const AvatarCreate: React.FunctionComponent = () => {
    const { t } = useTranslation();
    let navigate = useNavigate()
    // get languages & from multiLingual settings has not been implemented
    const [isAvatarReady, setAvatarReady] = useState<boolean>(false);
    const [isCreate, setIsCreate] = useState(false);
    const [avatarConfig, setAvatarConfig] = useState<any>();
    const [threejsConfig, setThreejsConfig] = useState<any>();
    const [isOpen, setIsOpen] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [avatarActions, setAvatarActions] = useState<any>([]);
    const [morphTargetList, setMorphTargetList] = useState<any>([]);
    const [aadUsers, setAadUsers] = useState<any>([]);
    const [modelChanged, setModelChanged] = useState(false)
    const [message, setMessage] = useState({ name: '', description: '', blendshapeMesh: '' })
    const [actionsItems, setActionsItems] = useState<any[]>([])
    const merchantID = useSelector((state: any) => state.userInfo.value)

    useEffect(() => {
        getThreejsConfig()
            .then((res: any) => setThreejsConfig(res))
            .catch(err => globalMessage(err))
        getAADUsers()
            .then((res: any) => setAadUsers(res))
            .catch(err => globalMessage(err))
    }, [])

    
    useEffect(() => {
        // 在上传模型后初始化动作的信息列表
        if (modelChanged) {
            const items = avatarActions.map((action: any, index: number) => {
                const idleIndices = threejsConfig.actionIndices.idleIndices.includes(index)
                const talkIndices = threejsConfig.actionIndices.talkIndices.includes(index)
                const idleWeightIndex = threejsConfig.actionIndices.idleIndices.findIndex((i: number) => i === index)
                const talkWeightIndex = threejsConfig.actionIndices.talkIndices.findIndex((i: number) => i === index)
                return {
                    actionIndex: index,
                    actionName: action.name || `动作${index}`,
                    available: true,
                    idleIndices,
                    idleWeights: idleIndices ? threejsConfig.actionIndices.idleWeights[idleWeightIndex] : 1,
                    talkIndices,
                    talkWeights: talkIndices ? threejsConfig.actionIndices.talkWeights[talkWeightIndex] : 1
                }
            })
            setModelChanged(false)
            setActionsItems(items)
        }
    }, [avatarActions])

    useEffect(() => {
        if (isAvatarReady) {
            console.log("yes avatar is ready");
            setUploadLoading(false)
        }
    }, [isAvatarReady])

    const avatarRef = useRef<any>(null);
    const avatarInfoRef = useRef<any>(null);
    const advancedModalRef = useRef<any>(null);

    const handleAdvanced = () => {
        if (avatarConfig && !uploadLoading) {
            onChangeThreeJSConfig()
            setIsOpen(true)
        } else {
            globalMessage(t('pages.VAManagement.Create.MessageOpenAdvanceError'))
        }
    }

    const handleBack = () => {
        navigate('/DEVA/VAManagement')
    }

    const handleSaveClick = () => {
        // 判断输入框不为空
        const { avatarInfo } = avatarInfoRef.current
        const { shares } = advancedModalRef.current
        const newMessage = {
            name: !avatarInfo.name ? t('pages.VAManagement.Create.MessageNameError') : '',
            description: !avatarInfo.description ? t('pages.VAManagement.Create.MessageDescriptionError') : ''
        }
        setMessage({
            ...message,
            ...newMessage
        })
        if (!avatarInfo.iconUrl || !avatarInfo.profile || !avatarConfig) {
            // 未完成上传模型或图片
            globalMessage(t('pages.VAManagement.Create.MessageImgUrlError'))
        } else {
            const errorMsg = Object.values(newMessage).find(msg => msg !== '')
            if(!errorMsg) {
                setIsCreate(true)
                onChangeThreeJSConfig()
                const availableActions:any[] = []
                actionsItems.forEach((item: any) => {
                    if (item.available) {
                        availableActions.push({
                            actionIndex: item.actionIndex,
                            actionName: item.actionName
                        })
                    }
                })
                shares.push(merchantID)
                const paramters = {
                    avatarInfo,
                    threejsConfig,
                    availableActions,
                    solution: 'Threejs',
                    shares
                }
                uploadAvatar(paramters)
                    .then((_res) => {
                        globalMessage('success', MessageBarType.success)
                        handleBack()
                    })
                    .catch(err => globalMessage(err))
                    .finally(() => setIsCreate(false))
            } 
        }
    }

      // upload image
    const beforeUploadGlb = (file: RcFile) => {
        const type = file.name.substring(file.name.length - 3)
        const isGlb = type === 'glb'
        if (!isGlb) {
            globalMessage(t('pages.VAManagement.Create.MessageModelTypeError'))
        } else {
            const reader = new FileReader()
            reader.readAsArrayBuffer(file)
        }
        return isGlb
    }

      // 上传模型
    const onUploadGlbImg = (option: { file: string | Blob }) => {
        setUploadLoading(true)
        setAvatarConfig(null)
        const formData = new FormData()
        formData.append('files', option.file)
        uploadImage(formData)
        .then((res: any) => {
            const newConfig = {
                ...threejsConfig,
                modelUrl: res[0].url
            }
            setAvatarConfig({
                solution: 'Threejs',
                threejsConfig: newConfig
            })
            setThreejsConfig(newConfig)
            setAvatarReady(false)
            setModelChanged(true)
        })
        .catch((err: { code: string }) => {
            globalMessage(err.code)
        })
    }

    const onChangeThreeJSConfig = () => {
        const newConfig = onGetConfig()
        setThreejsConfig(newConfig)
    }
    //获取模型的动作列表
    const onGetActions = (actions: any) => {
        setAvatarActions(actions)
    }
    //获取模型的口型列表
    const onGetMorphTarget = (morphTarget: any) => {
        if (morphTarget) {
            const morphTargetList = Object.values(morphTarget.morphTargetDictionary)
            setMorphTargetList(morphTargetList)
        } else {
            setMessage({
                ...message,
                blendshapeMesh: t('pages.VAManagement.Create.MessageBlendshapeMeshWrong')
            })
        }
    }

    return (
        <Stack
            horizontal
            horizontalAlign='space-between'
            styles={{root: {
                width: 1000,
                margin: '32px 0px 0px 30px',
                padding: '20px',
                background: '#FFFFFF',
                maxWidth: 1000,
                borderRadius: '5px'
            }}}
        >
            <Stack
                horizontal={false}
                styles={{ root: { width: 560 } }}
            >
                <Stack horizontal styles={{ root: { marginBottom: 16 } }}>
                    <Upload
                        name="uploadGlb"
                        showUploadList={false}
                        beforeUpload={beforeUploadGlb}
                        customRequest={(option: { file: string | Blob; }) => onUploadGlbImg(option)}
                    >
                        <DefaultButton>{t('pages.VAManagement.Create.Upload')}</DefaultButton>
                    </Upload>
                    <Stack styles={{root: {fontSize: 14, paddingTop: 10, color: '#999'}}}>
                        {t('pages.VAManagement.Create.UploadInfo')}
                    </Stack>
                </Stack>
                <Stack
                    styles={{root: {
                        position: 'relative',
                        minHeight: 560,
                        background: 'rgba(0, 0, 0, 0.02)',
                        border: !message.blendshapeMesh ? '1px dashed #d9d9d9' : '1px solid #a4262c'
                }}}>
                {uploadLoading && (
                    <Spinner
                        label={t('page_loading') || ''}
                        size={SpinnerSize.small}
                        styles={{root: {
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            zIndex: 10
                        }}}
                    />
                )}
                {avatarConfig && (
                    <AvatarLoader
                        datGui
                        avatarDivStyles={{...avatarDivStyles, opacity: uploadLoading ? 0 : 1}}
                        ref={avatarRef}
                        voiceConfig={[]}
                        avatarConfig={avatarConfig}
                        setAvatarReady={setAvatarReady}
                        onGetActions={onGetActions}
                        onGetMorphTarget={onGetMorphTarget}
                    />
                )}             
                </Stack>
                <Label
                    styles={{root: {
                        color: '#a4262c',
                        height: 30,
                        fontWeight: 400
                    }}}
                >
                    {message.blendshapeMesh}
                </Label>
                <Stack
                    horizontal
                    tokens={{ childrenGap: 0 + ' ' + 8 }}
                    styles={{ root: { marginTop: '10px!important' } }}
                >
                    <PrimaryButton
                        styles={buttonStyles}
                        onClick={handleSaveClick}
                        disabled={isCreate}
                    >
                        {t('pages.VAManagement.Create.CreateButton')}
                    </PrimaryButton>
                    <DefaultButton 
                        styles={buttonStyles}
                        onClick={handleBack}
                    >
                        {t('pages.VAManagement.Create.BackButton')}
                    </DefaultButton>
                </Stack>
            </Stack>
            <Stack horizontal={false} id="avatar Setting" styles={{ root: { marginLeft: 32, width: 350 } }}>
                <Stack horizontalAlign={'end'} styles={{ root: { marginBottom: 28} }}>
                    <Label
                        onClick={handleAdvanced}
                        styles={{
                            root: {
                                color: '#0078D4', fontWeight: 400, padding: 0, selectors: {
                                    ':hover': {
                                        color: 'black',
                                        cursor: 'pointer'
                                    }
                                }
                            }
                        }}
                    >
                        {t('pages.VAManagement.Create.Advanced')}
                    </Label>
                    <AdvancedModal
                        ref={advancedModalRef}
                        isOpen={isOpen}
                        threejsConfig={threejsConfig}
                        avatarConfig={avatarConfig}
                        morphTargetList={morphTargetList}
                        aadUsers={aadUsers}
                        message={message}
                        actionsItems={actionsItems}
                        setActionsItems={setActionsItems}
                        setMessage={setMessage}
                        setAvatarConfig={setAvatarConfig}
                        setOpen={setIsOpen}
                        setThreejs={setThreejsConfig}
                        setMorphTargetList={setMorphTargetList}
                    />
                </Stack>
                <AvatarInfo
                    ref={avatarInfoRef}
                    message={message}
                />
            </Stack>
        </Stack>
    )
}

const AdvancedModal: React.FunctionComponent<IAdvancedModal> = forwardRef((props: IAdvancedModal, ref) => {
    const { t } = useTranslation();
    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const { setOpen, isOpen, threejsConfig, setThreejs, aadUsers, 
        avatarConfig, setAvatarConfig, morphTargetList,
        setMorphTargetList, message, setMessage, actionsItems, setActionsItems } = props

    const [activeKey, setActiveKey] = useState('actions')
    const [tabItems, setTabItems] = useState<any[]>([])
    const [config, setConfig] = useState<any>()
    const [modalMessage, setModalMessage] = useState<any>({blendshapeMesh: ''})
    // const [options, setOptions] = useState<IDropdownOption[]>([])
    const [selectedKeys, setSelectedKeys] = useState<any>([])
    const [verifyValue, setVerifyValue] = useState<any>()

    useEffect(() => {
        // 初始化tabItems
        setTabItems(_.cloneDeep(actionsItems))
    }, [actionsItems])

    useEffect(() => {
        // 初始化threejsConfig
        const newConfig = {
            blendshapeMesh: _.get(threejsConfig, 'blendshapeMesh', ''),
            visemeMap: _.get(threejsConfig, 'visemeMap', []),
            actionIndices: _.get(threejsConfig, 'actionIndices', null),
            interval: _.get(threejsConfig, 'interval', 0)
        }
        setConfig(newConfig)
    }, [threejsConfig])

    useEffect(() => {
        // 更新Config
        const idleIndicesList:any = {}
        const talkIndicesList:any = {}
        tabItems.forEach(item => {
            if (item.idleIndices) {
                idleIndicesList[item.actionIndex] = item.idleWeights
            }
            if (item.talkIndices) {
                talkIndicesList[item.actionIndex] = item.talkWeights
            }
        })
        const actionIndices = {
            idleIndices: Object.keys(idleIndicesList).map(Number),
            idleWeights: Object.values(idleIndicesList),
            talkIndices: Object.keys(talkIndicesList).map(Number),
            talkWeights: Object.values(talkIndicesList)
        }
        setConfig({
            ...config,
            actionIndices
        })
    }, [tabItems])

    // useEffect(() => {
    //     // 初始化options, selectedKeys
    //     const newOptions = aadUsers.map(user => ({
    //         key: user,
    //         text: user,
    //         disabled: user === merchantID
    //     }))
    //     setOptions(newOptions)
    //     setSelectedKeys([merchantID])
    // }, [aadUsers])

    useImperativeHandle(ref, () => ({
        shares: selectedKeys
    }))

    // 验证BlendshapeMesh
    const onVerifyBlendshapeMesh = () => {
        if (!config.blendshapeMesh) {
            setModalMessage({
                ...modalMessage,
                blendshapeMesh: t('pages.VAManagement.Create.MessageBlendshapeMeshNull')
            })
        } else {
            const morphTarget = updateMorphTarget(config.blendshapeMesh)
            if (morphTarget) {
                const morphList = Object.values(morphTarget.morphTargetDictionary || {})
                setMorphTargetList(morphList)
                setModalMessage({
                    ...modalMessage,
                    blendshapeMesh: ''
                })
            } else {
                setModalMessage({
                    ...modalMessage,
                    blendshapeMesh: t('pages.VAManagement.Create.MessageBlendshapeMeshError')
                })
            }
        }
    }
    // 关闭Modal
    const onCancel = () => {
        setTabItems(_.cloneDeep(actionsItems))
        setOpen(false)
    }

    const onSave = () => {
        const morphTarget = updateMorphTarget(config.blendshapeMesh)
        // 初始口型不用校验
        const visemeMap = config.visemeMap.filter((viseme: number) => viseme !== -1)
        // blendshapeMesh验证通过， interval不能为0， visemeMap不能少
        const newMessage = {
            blendshapeMesh: !config.blendshapeMesh ? t('pages.VAManagement.Create.MessageBlendshapeMeshNull')
                : (!morphTarget ? t('pages.VAManagement.Create.MessageBlendshapeMeshError') : ''),
            interval: config.interval === 0 ? t('pages.VAManagement.Create.MessageIntervalError') : '',
            visemeMap: visemeMap.find((viseme: number) => !morphTargetList.includes(viseme)) ? t('pages.VAManagement.Create.MessageVisemeMapError') : ''
        }
        setModalMessage(newMessage)
        const errorMsg = Object.values(newMessage).find(message => message)
        if (!errorMsg) {
            const newThreejsConfig = {  
                ...threejsConfig,
                ...config
            }
            setThreejs(newThreejsConfig)
            setAvatarConfig({
                ...avatarConfig,
                threejsConfig: newThreejsConfig
            })
            setMessage({
                ...message,
                blendshapeMesh: ''
            })
            setActionsItems(tabItems)
            setOpen(false)
        } else {
            globalMessage(errorMsg || t('pages.VAManagement.Create.MessageThreejsConfigError'))
        }
    }

    const onChangeTab = (newActiveKey: string) => {
        //切换高级设置内容页
        setActiveKey(newActiveKey)
    }

    const onChangeAvailable = (item: any, checked: boolean | undefined) => {
        // 改变动作的available
        const newItems = tabItems.map(i => {
            if (i.actionIndex === item.actionIndex) {
                i.available = checked
            }
            return i
        })
        setTabItems(newItems)
    }

    const onChangeActionName = (item: any, name: string) => {
        // 改变动作的名称
        const newItems = tabItems.map(i => {
            if (i.actionIndex === item.actionIndex) {
                i.actionName = name || `动作${item.actionIndex}`
            }
            return i
        })
        setTabItems(newItems)
    }

    const onChangeActionIndices = (item: any, checked: boolean | undefined, type: string) => {
        // 改变threejsConfig的ActionIndices
        const newItems = tabItems.map(i => {
            if (i.actionIndex === item.actionIndex) {
                i[type] = checked
            }
            return i
        })
        setTabItems(newItems)
    }

    const onChangeActionWeights = (item: any, value: string, type: string) => {
        // 改变threejsConfig的Weights
        const newItems = tabItems.map(i => {
            if (i.actionIndex === item.actionIndex) {
                i[type] = Number(value) < 1 ? 1 : Number(value)
            }
            return i
        })
        setTabItems(newItems)
    }

    const onChangeConfig = (type: string, value: string | number) => {
        //更新blendshapeMesh和interval
        setConfig({
            ...config,
            [type]: value
        })
        setModalMessage({
            ...modalMessage,
            [type]: ''
        })
    }
    // 改变visemeMap
    const onChangeVisemeMap = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        const newVisemeMap = config.visemeMap.map((viseme: string | number, ind: number | undefined) => {
            if (ind === index) {
                viseme = option?.key || 1
            }
            return viseme
        })
        setConfig({
            ...config,
            visemeMap: newVisemeMap
        })
    }

    const onChangeUsers = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        // 改变授权用户
        setVerifyValue(value)
        if (value) {
            let nameList: string[] = []
            const enList = value.split(',') // 英文逗号分隔NAME
            enList.forEach(item => {
                const zhList = item.split('，') // 中文逗号分隔NAME
                const newList = zhList.map(list => list.trim()) // 去除用户名前后的空格
                nameList = [...nameList, ...newList]
            })
            setSelectedKeys(nameList)
        } else {
            setSelectedKeys([])
        }
    }

    // 分页内容
    const tabContent = () => {
        const headerStyle = {
            root: {
                paddingTop: 0
            }
        }
        const rowStyle = {
            cell: {
                display: 'flex',
                alignItems: 'center'
            }
        }
        const toggleStyles = {
            root: {marginBottom: '0px'}
        }
        const weightStyles = {
            root: {width: '80px'}
        }
        if (activeKey === 'actions') {
            // 高级设置动作的信息
            const columns = [
                {
                    key: 'actionIndex',
                    name: 'Action Index',
                    fieldName: 'actionIndex',
                    minWidth: 100,
                    maxWidth: 100
                },
                {
                    key: 'actionName',
                    name: 'Action Name',
                    fieldName: 'actionName',
                    minWidth: 300,
                    maxWidth: 300,
                    onRender: (item: any) => (
                        <TextField
                            styles={{root: {width: '100%'}}}
                            defaultValue={item.actionName}
                            placeholder={`动作${item.actionIndex}`}
                            onBlur={(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => onChangeActionName(item, ev.target.value)}
                        />
                    )
                },
                {
                    key: 'available',
                    name: 'isAvailabled',
                    fieldName: 'available',
                    minWidth: 100,
                    maxWidth: 100,
                    onRender: (item: any) => (
                        <Toggle
                            styles={toggleStyles}
                            checked={item.available}
                            onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => onChangeAvailable(item, checked)}
                        />
                    )
                }
            ]
            return (
              <Stack>
                <DetailsList
                    items={tabItems}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    setKey="none"
                    onRenderDetailsHeader={(props: any) => {
                    return <DetailsHeader {...props}/>
                    }}
                    onRenderRow={(props: any) => {
                    return <DetailsRow {...props} styles={rowStyle} />
                    }}
                    isHeaderVisible={true}
                />
              </Stack>
            )
        } else if (activeKey === 'threejsConfig') {
            // 高级设置threejsConfig
            const columns = [
                {
                    key: 'actionIndex',
                    name: 'Action Index',
                    fieldName: 'actionIndex',
                    minWidth: 90,
                    maxWidth: 90
                },
                {
                    key: 'actionName',
                    name: 'Action Name',
                    fieldName: 'actionName',
                    minWidth: 200,
                    maxWidth: 200,
                    onRender: (item: any) => (
                        <Stack
                            styles={{root: {
                                width: '100%',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap'
                            }}}
                            title={item.actionName}
                        >
                            {item.actionName}
                        </Stack>
                    )
                },
                {
                    key: 'idleIndices',
                    name: 'IdleIndices',
                    fieldName: 'idleIndices',
                    minWidth: 90,
                    maxWidth: 90,
                    onRender: (item: any) => (
                        <Toggle
                            styles={toggleStyles}
                            checked={item.idleIndices}
                            onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => onChangeActionIndices(item, checked, 'idleIndices')}
                        />
                    )
                },
                {
                    key: 'idleWeights',
                    name: 'IdleWeights',
                    fieldName: 'idleWeights',
                    minWidth: 90,
                    maxWidth: 90,
                    onRender: (item: any) => (
                        <InputNumber
                            className='modal-config-number'
                            min={1}
                            placeholder='1'
                            defaultValue={item.idleWeights}
                            onBlur={(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => onChangeActionWeights(item, ev.target.value, 'idleWeights')}
                        />
                    )
                },
                {
                    key: 'talkIndices',
                    name: 'TalkIndices',
                    fieldName: 'talkIndices',
                    minWidth: 90,
                    maxWidth: 90,
                    onRender: (item: any) => (
                        <Toggle
                            styles={toggleStyles}
                            checked={item.talkIndices}
                            onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => onChangeActionIndices(item, checked, 'talkIndices')}
                        />
                    )
                },
                {
                    key: 'talkWeights',
                    name: 'TalkWeights',
                    fieldName: 'talkWeights',
                    minWidth: 90,
                    maxWidth: 90,
                    onRender: (item: any) => (
                        <InputNumber
                            className='modal-config-number'
                            min={1}
                            placeholder='1'
                            defaultValue={item.talkWeights}
                            onBlur={(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => onChangeActionWeights(item, ev.target.value, 'talkWeights')}
                        />
                    )
                }
            ]
            const labelStyles: Partial<ILabelStyles> = {
                root: {
                    fontSize: 16,
                    fontWeight: 600,
                    width: 128,
                    height: 32,
                    textAlign: 'end',
                    padding: 0
                }
            }
            const dropdownStyles: Partial<IDropdownStyles> = {
                root: {
                    width: '60px',
                    margin: '16px 16px 0px 16px'
                },
                title: {
                  color: "#605E5C",
                  borderRadius: '2px',
                  borderColor: '#ccc'
                },
                callout: {
                  maxHeight: '200px !important',
                  overflow: 'auto',
                  selectors: {
                    '.ms-Callout-main': {
                      maxHeight: 'inherit !important'
                    }
                  }
                }
            }
            const visemeMapOptions = morphTargetList.map(list => ({
                key: list,
                text: list
            }))
            return (
                <Stack tokens={{ childrenGap: 10 }} styles={{ root: { marginBottom: 10 } }}>
                    <Stack horizontal>
                        <Stack horizontal tokens={{ childrenGap: 0 + ' ' + 16 }}>
                            <Label styles={labelStyles}>BlendshapeMesh:</Label>
                            <TextField
                                styles={{fieldGroup: {border: '1px solid #DCDFE6'}}}
                                value={config.blendshapeMesh}
                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
                                    onChangeConfig('blendshapeMesh', value ? value : "")
                                }}
                                errorMessage={modalMessage.blendshapeMesh}
                            />
                            <PrimaryButton
                                text={t('pages.VAManagement.Create.ButtonVerify') || ''}
                                styles={{root: {
                                    minWidth: 40,
                                    margin: '0px !important',
                                    padding: '0px 5px'
                                }}}
                                onClick={onVerifyBlendshapeMesh}
                            />
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 0 + ' ' + 16 }}>
                            <Label styles={labelStyles}>Interval:</Label>
                            <TextField
                                type='number'
                                min='0'
                                styles={{fieldGroup: {border: '1px solid #DCDFE6'}}}
                                value={config.interval}
                                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
                                    onChangeConfig('interval', value ? Number(value) : 0)
                                }}
                                errorMessage={modalMessage.interval}
                            />
                        </Stack>
                    </Stack>
                    <Stack horizontal={false} styles={{root: {marginBottom: 20}}}>
                        <Label styles={{root: {fontSize: 16}}}>
                            VisemeMap:
                            <span style={{color: '#a4262c', fontSize: 14, paddingLeft: 20}}>
                                {modalMessage.visemeMap}
                            </span>
                        </Label>
                        <Stack
                            horizontal
                            styles={{root: {flexWrap: 'wrap'}}}
                        >
                            {config.visemeMap.map((value: number, index: number) => 
                                index === 0 ? (
                                    <TextField
                                        disabled
                                        value='-1'
                                        styles={{root: {width: 60, margin: '16px 16px 0px 16px'}}}
                                    />
                                ) : (
                                    <Dropdown
                                        options={visemeMapOptions}
                                        selectedKey={value}
                                        styles={dropdownStyles}
                                        onChange={(event, option) => onChangeVisemeMap(event, option, index)}
                                    />
                            ))}
                        </Stack>
                    </Stack>
                    <Stack horizontal={false} >
                        <Label styles={{root: {fontSize: 16}}}>ActionIndices:</Label>
                        <DetailsList
                            items={tabItems}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                            setKey="none"
                            onRenderDetailsHeader={(props: any) => {
                                return <DetailsHeader {...props} styles={headerStyle} />
                            }}
                            onRenderRow={(props: any) => {
                                return <DetailsRow {...props} styles={rowStyle} />
                            }}
                            isHeaderVisible={true}
                        />
                    </Stack>
                </Stack>
            )
        } else {
            return (
                <Stack>
                    <TextField
                        styles={textFieldStyles}
                        multiline
                        rows={4}
                        resizable={false}
                        value={verifyValue}
                        label={t('pages.VAManagement.Create.LabelSelectUsers') || ''}
                        onChange={onChangeUsers}
                    />
                    <Stack styles={{root: {fontSize: 14, paddingTop: 10, color: '#999'}}}>
                        {t('pages.VAManagement.Create.VerifyInfo')}
                    </Stack>
                </Stack>
            )
        }
    }

    const panes = [
        {
            label: t('pages.VAManagement.Create.LabelActions'),
            children: tabContent(),
            key: 'actions'
        },
        {
            label: 'ThreejsConfig',
            children: tabContent(),
            key: 'threejsConfig'
        },
        // { todo
        //     label: t('pages.VAManagement.Create.LabelAuthorizes'),
        //     children: tabContent(),
        //     key: 'authorize'
        // }
    ]

    return (
        <Modal
            isOpen={isOpen}
            isModeless={true}
            styles={modalStyles}
        >
            <Stack
                horizontal={false}
                styles={{root: {
                    padding: '20px'
                }}}
            >
                <Stack
                    horizontal
                    horizontalAlign='space-between'
                >

                    <Label styles={{
                        root: {
                            fontSize: 18,
                            color: '#323130',
                            fontWeight: 400,
                            padding: 0
                        }
                    }}>
                        {t('pages.VAManagement.Create.ModalHeaderTitle')}
                    </Label>
                    <IconButton
                        iconProps={cancelIcon}
                        styles={{
                            root: {
                                width: 32,
                                height: 32
                            }
                        }}
                        ariaLabel="Close popup modal"
                        onClick={onCancel}
                    />
                </Stack>
                <Stack
                    styles={{ root: { height: 685 } }}
                >
                    <Tabs
                        tabPosition='left'
                        style={{ width: '100%', height: '100%' }}
                        activeKey={activeKey}
                        items={panes}
                        onChange={onChangeTab}
                    />
                </Stack>
                <Stack horizontal>
                    <PrimaryButton
                        styles={buttonStyles}
                        onClick={onSave}>
                        {t('pages.VAManagement.Yes')}
                    </PrimaryButton>
                </Stack>
            </Stack>
        </Modal>
    )
})

interface IAvatarInfoProps {
    ref: any,
    message: any
}

const AvatarInfo: React.FunctionComponent<IAvatarInfoProps> = forwardRef((props: IAvatarInfoProps, ref) => {
    const { t } = useTranslation();
    const ChoiceGroupoptions: IChoiceGroupOption[] = [
        { key: 'Male', text: t('pages.VAManagement.Create.GenderMale') },
        { key: 'Female', text: t('pages.VAManagement.Create.GenderFemale'), styles: { field: { marginLeft: 15 } } },
    ]
    const [name, setName] = useState('');
    const [gender, setGender] = useState('Male');
    const [description, setDescription] = useState('');
    const [iconUrl, setIconUrl] = useState('');
    const [profile, setProfile] = useState('');
    const [infoMessage, setInfoMessage] = useState(props.message)

    // 更新message
    useEffect(() => {
        setInfoMessage(props.message)
    }, [props.message])

    // 清空message
    const onChangeMessage = (type: string) => {
        setInfoMessage({
            ...infoMessage,
            [type]: ''
        })
    }

    const handleName = (value: string) => {
        setName(value);
        onChangeMessage('name')
    }

    const handleDescription = (value: string) => {
        onChangeMessage('description')
        setDescription(value);
    }

    const handleGender = (ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined) => {
        setGender(option?.key ?? 'Male');
    }

    useImperativeHandle(ref, () => ({
        avatarInfo: {
            'name': name,
            'gender': gender,
            'description': description,
            'iconUrl': iconUrl,
            'profile': profile
        }
    }))

    const uploadButton = (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>{t('pages.QAManagement.detail.btnUploadImage')}</div>
        </div>
    )

    // upload image
    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            globalMessage(t('pages.QAManagement.detail.messageImageTypeError'))
        }
        return isJpgOrPng
    }

    // 上传图片
    const onUploadImg = (option: { file: string | Blob }, type: string) => {
        const formData = new FormData()
        formData.append('files', option.file)
        uploadImage(formData)
        .then((res: any) => {
            if (type === 'icon') {
                setIconUrl(res[0].url)
            } else if (type === 'profile') {
                setProfile(res[0].url)
            }
        })
        .catch((err: { code: string }) => {
            globalMessage(err.code)
        })
    }

    return (
        <Stack tokens={{ childrenGap: 10 }} styles={{ root: { marginBottom: 10 } }}>
            <Stack horizontal tokens={{ childrenGap: 0 + ' ' + 16 }} verticalAlign='center'>
                <Label styles={toggleLabelStyles}>{t('pages.VAManagement.Create.Name')}</Label>
                <TextField
                    styles={{fieldGroup: {border: '1px solid #DCDFE6'}}}
                    value={name}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => { handleName(value ? value : ""); }}
                    errorMessage={infoMessage.name}
                />
            </Stack>
            <Stack
                horizontal
                styles={{ root: { marginBottom: 10 } }}
                tokens={{ childrenGap: 0 + ' ' + 16 }}
            >
                <Label styles={comboBoxLabelStyles} style={{ padding: '10px 0px' }}>{t('pages.VAManagement.Create.Gender')}</Label>
                <ChoiceGroup
                    styles={{ flexContainer: { display: "flex" } }}
                    options={ChoiceGroupoptions}
                    onChange={handleGender}
                    defaultSelectedKey={gender}
                />
            </Stack>
            <Stack
                horizontal
                styles={{ root: { marginBottom: 10 } }}
                tokens={{ childrenGap: 0 + ' ' + 16 }}
            >                <Label styles={toggleLabelStyles}>{t('pages.VAManagement.Create.Description')}</Label>
                <TextField
                    styles={textFieldStyles}
                    multiline
                    rows={4}
                    resizable={false}
                    value={description}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => { handleDescription(value ? value : ""); }}
                    errorMessage={infoMessage.description}
                />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 0 + ' ' + 16 }} styles={{root: {zIndex: 1}}}>
                <Label styles={toggleLabelStyles}>{t('pages.VAManagement.Create.ProfileImage')}</Label>
                <Upload
                  name="ProfileImage"
                  listType="picture-circle"
                  className="avatar-profile-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  customRequest={(option: { file: string | Blob; }) => onUploadImg(option, 'profile')}
                >
                  {profile ? <img src={profile} alt="img" className='detail-answer-upload-img circle-upload-img' /> : uploadButton}
                </Upload>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 0 + ' ' + 16 }} styles={{root: {zIndex: 1}}}>
                <Label styles={toggleLabelStyles}>{t('pages.VAManagement.Create.IconImage')}</Label>
                <Upload
                  name="IconImage"
                  listType="picture-card"
                  className="avatar-icon-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  customRequest={(option: { file: string | Blob; }) => onUploadImg(option, 'icon')}
                >
                    {iconUrl ? <img src={iconUrl} alt="img" className='detail-answer-upload-img' /> : uploadButton}
                </Upload>
            </Stack>
        </Stack>
    )
})
