import axios from "axios";
import { globalMessage } from "SRC/components/messagebar";
import { RefreshToken } from "SRC/api/login"

const MessegeCode = {
  1000: "OK",
  1001: 'global.message.created',
  2000: "global.message.badrequest",
  2001: "global.message.user.not.exist.or.inactive",
  2002: "global.message.user.already.exist",
  2003: "global.message.invalid.input",
  2004: "global.message.unauthorized",
  2005: "global.message.forbidden",
  2006: "global.message.too.many.requests",
  2007: "global.message.verification.code.expired",
  2008: "global.message.auth.token.not.exist",
  3000: "global.message.internal.server.error"
}

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: "",
  timeout: 60000, // 请求超时时间
});

// 异常拦截处理器
let isRefreshing = false
let promiseArray = []

// push所有请求到数组中
const subscribeTokenRefresh = (item) => {
  promiseArray.push(item)
}

const onRefreshed = () => {
  promiseArray.map(item => item())
}

// 获取token中的过期时间
export const setexpireTime = (time) => {
  const expireTime = new Date(time).getTime()
  return expireTime
}

// 设置token过期时间
export const setExpire = (key, value, expireTime) => {
  let obj = {
    data: value,
    expireTime,
  }
  localStorage.setItem(key, JSON.stringify(obj))
}
// 判断token是否过期
const getItem = (key) => {
  let val = localStorage.getItem(key);
  if (!val) {
    return val
  }
  val = JSON.parse(val);
  // 获取本地时间的UTC时间戳
  const currentDate = new Date()
  const utcString = currentDate.toISOString().split('.')[0]
  const utcTime = new Date(utcString).getTime()
  if (utcTime > val.expireTime) {
    localStorage.removeItem(key)
    return null
  }
  return val
}
// 获取cookie指定参数
// const getCookieValue = (name) => {
//   const regex = new RegExp(`(^| )${name}=([^;]+)`)
//   const match = document.cookie.match(regex)
//   if (match) {
//     return match[2]
//   }
// }

const errorHandler = async (error) => {
  console.log(error);
  if (error.code === 'ERR_CANCELED') {
    return Promise.reject(error);
  }
  const status = error.response.status;
  if (status === 400) {
    if (error.response.data.custom_msg === "username or password is incorrect.") {
      globalMessage("global.message.wrong.username.or.password");
    }
    else {
      globalMessage(MessegeCode[error.response.data.custom_code]);
    }
  } else if (status === 401) {
    if (window.location.pathname !== "/login") {
      globalMessage("global.message.user.login.expired");
      window.location.href = "/login"
    }
    console.log(error.config)
  }
  else if (status === 403) {
    globalMessage("global.message.user.not.permitted");
  }
  else if (status === 404) {
    globalMessage("global.message.user.not.found");
  }
  else if (status === 500) {
    globalMessage("global.message.internal.server.error");
  }
  return Promise.reject(error);
};

// request interceptor
request.interceptors.request.use((config) => {
  // 免token请求API
  const excludeLoginUrls = [
    '/api/user/atptp_login',
    '/api/user/verification_code',
    '/api/user/atptp_refresh',
  ]
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  const isTokenExpired = getItem('isTokenExpired')
  if (!isTokenExpired && !excludeLoginUrls.some(UrlParts=>config.url.includes(UrlParts))) {
    if (!isRefreshing) {
      isRefreshing = true
      RefreshToken()
        .then(res => {
          isRefreshing = false
          const expTime = setexpireTime(res.access_token_expires_at)
          setExpire('isTokenExpired', 'true', expTime)
          onRefreshed()
          // 发起后将promiseArry置空
          promiseArray = []
        })
        .catch((err) => {
          console.log(err)
          isRefreshing = false
          if (window.location.pathname !== "/Login") {
            globalMessage("用户过期，请重新登录");
            window.location.href = "/Login"
          }
        })
    }
    // 如果正在刷新token，将发起的请求暂存在promiseArry中
    return new Promise((resolve) => {
      subscribeTokenRefresh(() => {
        // 将请求挂起
        resolve(config)
      })
    })
  }
  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use(async (response) => {
  const responseType = ["blob", "stream"]
  if (responseType.includes(response.config.responseType)) {
    return response;
  } else {
    return response.data;
  }
}, errorHandler);

export default request;