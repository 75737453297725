import * as React from 'react';
import {
    useState,
    forwardRef,
    useRef,
    useEffect,
    useImperativeHandle
} from 'react';
import _ from 'lodash'
import {
    Label,
    Slider,
    ISliderStyles,
    Stack,
    IButtonStyles,
    VirtualizedComboBox,
    IComboBoxStyles,
    IComboBoxOption,
    TextField,
    ITextFieldStyles,
    IComboBox,
    PrimaryButton,
    DefaultButton,
    Modal,
    IModalStyles,
    IconButton,
    IIconProps,
    ILabelStyles,
    Toggle,
    ChoiceGroup,
    IChoiceGroupOption,
    Image,
    ImageFit,
    MessageBarType,
    ProgressIndicator
} from '@fluentui/react';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { UnityAvatar } from './avatar/unity/UnityAvatar';
import { ThreejsAvatar } from './avatar/threejs/ThreejsAvatar';
import { VirtualAnchorHandler } from './avatar/VirtualAnchorHandler';
import {
    getAvatars,
    getAvatarVoices,
    getVirtualSales,
    getMerchant,
    updateAvatar,
    getVoiceConfig,
    deleteAvatar
} from 'SRC/api/avatarmanagement'
import {
    getLanguages
} from 'SRC/api/merchangmanagement'
import { globalMessage } from 'SRC/components/messagebar';
import { DeleteCallout } from 'SRC/components/deleteCallout';
import { useSelector } from 'react-redux';

const defaultVoicesConfig = {
    "Male": [{
        "language": "en",
        "voice": "en-US-GuyNeural",
        "style": "newscast",
        "rate": 1,
        "pitch": 0
    }],
    "Female": [{
        "language": "zh",
        "voice": "zh-CN-XiaoxiaoNeural",
        "style": "cheerful",
        "rate": 1,
        "pitch": 0,
    }],
    "DefaultRate": 1,
    "DefaultPitch": 0
}

/* styles */
const avatarDivStyles = {
    backgroundColor: '#F2F2F2',
    height: 445,
    width: 381,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '5px',
    position: 'relative'
}

const textFieldStyles: Partial<ITextFieldStyles> = {
    root: {
        height: 108,
    },

    fieldGroup: {
        width: 344,
        height: 108,
        marginLeft: 28,
        border: '1px solid #DCDFE6'
    },

    field: {
        margin: 16,
        padding: 0
    }
}

const LabelActionStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: 400,
        fontSize: 14,
        width: 40,
        height: 24,
        padding: 0
    }
}

const comboBoxStyles: Partial<IComboBoxStyles> = {
    root: {
        borderRadius: '2px',
        width: 344,
        height: 36,
        selectors: {
            ':after': {
                borderColor: '#DCDFE6'
            }
        }
    },

    label: {
        color: '#323130',
        fontSize: 12,
        fontWeight: 400
    },
}

const comboBoxLabelStyles: Partial<ILabelStyles> = {
    root: {
        fontSize: 14,
        fontWeight: 400,
        width: 56,
        height: 19,
        textAlign: 'end'
    }
}

const toggleLabelStyles: Partial<ILabelStyles> = {
    root: {
        fontSize: 14,
        fontWeight: 400,
        width: 56,
        height: 19,
        textAlign: 'end',
        padding: 0
    }
}

const buttonStyles: IButtonStyles = {
    root: {
        marginTop: 10,
        border: 'none',
        borderRadius: '2px',
        width: 80,
        height: 32,
    },

    label: {
        fontSize: 14,
        fontWeight: 400,
        fontFamily: 'Microsoft YaHei-Regular, Microsoft YaHei',
    }
}

const buttonActionStyles: IButtonStyles = {
    root: {
        backgroundColor: '#ECF5FF',
        color: '#0078D4',
        alignItems: 'center',
        borderRadius: '2px',
        border: '1px solid #0078D4',
        height: 24,
        minWidth: 70,
        display: 'flex',
        justifyContent: 'center',
        padding: 0,
        margin: '0px 5px 10px 5px'
    },

    label: {
        fontWeight: 400,
        fontSize: 12,
        fontFamily: 'Microsoft YaHei-Regular, Microsoft YaHei',
    },
}

const sliderStyles: Partial<ISliderStyles> = {
    root: {
        width: 344,
        height: 36
    },
    activeSection: {
        backgroundColor: '#0078D4'
    },
}

const modalStyles: Partial<IModalStyles> = {
    main: {
        width: 767,
        height: 565
    }
}

const iconButtonStyles: Partial<IButtonStyles> = {
    icon: {fontSize: 24}
}

const deleteIconStyles = {
    root: {
        color: '#605E5C',
        margin: '0px'
    }
}

interface IVoice {
    language: any
    voice: any
    pitch: number
    style: any
    rate: number
}

interface IVoiceSetting {
    avatarId: string
    voices: Array<IVoice>
}

interface IVoiceDataElement {
    name: string
    displayName: string
    localName: string
    shortName: string
    gender: string
    locale: string
    localeName: string
    styleList: Array<string>
    sampleRateHertz: string
    voiceType: string
    status: string
    RolePlayList: Array<string> | undefined
    wordsPerMinute: string
}

interface IVoiceSettingProps {
    //voicesConfigValue: Array<IVoice>,
    avatarId: string | undefined
    voicesConfigValue: Array<IVoiceSetting>
    voiceData: Array<IVoiceDataElement>,
    avatarGender: any,
    languages: Array<string>
    try: Function
    ref: any
    isAvatarReady: boolean
    handleSaveClick: Function
}

interface IChooseAvatarModal {
    setOpen: Function
    setAvatarId: Function
    setAvatarGender: Function
    setVsConfig: Function
    setAvatarInfos: Function
    avatarId: string | undefined
    avatarGender: string
    isOpen: boolean
    avatarInfos: Array<Object>
    vsConfig: any
}

const currLanguage = 'zh';

export const AvatarManagement: React.FunctionComponent = () => {
    const { t } = useTranslation();
    let navigate = useNavigate()
    // get languages & from multiLingual settings has not been implemented
    const [isAvatarReady, setAvatarReady] = useState<boolean>(false);

    const [languages, setLanguages] = useState();
    console.log("init======================================");
    const [displaySetting, setDisplaySetting] = useState<any>()
    const [voiceData, setVoiceData] = useState<Array<IVoiceDataElement>>();
    const [vsConfig, setVsConfig] = useState<any>();
    const [avatarId, setAvatarId] = useState<string>();
    const [avatarConfig, setAvatarConfig] = useState<any>();

    const [avatarGender, setAvatarGender] = useState<string>("Female");
    const [modelLoading, setModelLoading] = useState(false)
    const [percentComplete, setPercentComplete] = useState(0)
    const [percentTitle, setPercentTitle] = useState('')
    const [isOpen, setIsOpen] = useState(false);

    const [avatarName, setAvatarName] = useState();

    useEffect(() => {
        getLanguages().then((res: any) => setLanguages(res.multilingualSupport)).catch(err => globalMessage(err))
    }, [])

    useEffect(() => {
        getAvatarVoices().then((res: any) => setVoiceData(res)).catch(err => globalMessage(err))
    }, [])

    useEffect(() => {
        getVirtualSales().then((res: any) => {
            setVsConfig(res)
            setAvatarId(res.avatarId)
            setDisplaySetting(res.displaySetting)
        }).catch(err => globalMessage(err))
    }, [])

    useEffect(() => {
        if (avatarId) {
            setAvatarConfig(null)
            setModelLoading(true)
            getMerchant(avatarId).then((res: any) => {
                setAvatarConfig(res)
                const avatarInfo = res.avatarInfo
                setAvatarName(avatarInfo.name)
                setAvatarGender(avatarInfo.gender)
            }).catch(err => globalMessage(err))
        }
        setAvatarReady(false);
    }, [avatarId])

    const [avatarInfos, setAvatarInfos] = useState();

    useEffect(() => {
        getAvatars().then((res: any) => setAvatarInfos(res)).catch(err => globalMessage(err))
    }, [])

    useEffect(() => {
        if (isAvatarReady) {
            console.log("yes avatar is ready");
            setModelLoading(false)
        }
    }, [isAvatarReady])

    const avatarRef = useRef<any>(null);
    const voiceRef = useRef<any>(null);
    const displayRef = useRef<any>(null);

    const handleActionClick = (i: any) => avatarRef.current.animAct(i);

    const handleChangeAvatarClick = () => setIsOpen(true);

    const handleTryClick = (sentence: string, lan: string, voiceConfig: object) => avatarRef.current.start(sentence, lan, voiceConfig);

    const handleSaveClick = () => {
        const paramters = {
            'merchantId': '',
            'voiceSettings': vsConfig.voiceSettings.map((val: any) => (val.avatarId === avatarId) ? { 'avatarId': avatarId, 'voices': voiceRef.current.voicesConfig, 'gender': val.gender } : val),
            'avatarId': avatarId,
            'displaySetting': displayRef.current.displaySetting
        }
        setVsConfig(paramters);
        updateAvatar(paramters)
            .then((_res: any) => globalMessage('success', MessageBarType.success))
            .catch(err => globalMessage(err));

    }

    return (<Stack styles={{ root: { background: '#F5F5F5', height: 700 } }}>
        <Stack
            horizontal={true}
            tokens={{ childrenGap: 0 + ' ' + 36 }}
            style={{ marginTop: 32, width: 1078, minHeight: 675, marginLeft: 30, background: '#FFFFFF', borderRadius: '5px' }}>
            <Stack
                horizontal={false} styles={{ root: { marginLeft: 32, marginTop: 32, width: 381 } }}>
                <Stack horizontal horizontalAlign='space-between' styles={{ root: { marginBottom: 16 } }}>
                    <Label styles={{ root: { fontWeight: 400, marginRight: 5, padding: 0 } }}>{t('pages.VAManagement.CurrentAvatar')} </Label>
                    <Label styles={{ root: { fontWeight: 800, padding: 0 } }}>{avatarName}</Label>
                    <Label
                        onClick={handleChangeAvatarClick}
                        styles={{
                            root: {
                                color: '#0078D4', fontWeight: 400, padding: 0, selectors: {
                                    ':hover': {
                                        color: 'black',
                                        cursor: 'pointer'
                                    }
                                }
                            }
                        }}>{t('pages.VAManagement.ChangAvatar')}
                    </Label>
                </Stack>
                <Stack styles={{root: {
                    position: 'relative',
                    width: avatarDivStyles.width,
                    height: avatarDivStyles.height,
                    backgroundColor: avatarDivStyles.backgroundColor
                }}}>
                    {modelLoading && (
                        <ProgressIndicator
                            label={percentTitle}
                            styles={{
                                root: {
                                    position: 'absolute',
                                    width: '80%',
                                    top: '40%',
                                    left: '10%',
                                    zIndex: 1
                                }
                            }}
                            percentComplete={percentComplete}
                        />
                    )}
                    {avatarId && vsConfig && avatarConfig &&
                        <AvatarLoader
                            avatarDivStyles={{...avatarDivStyles, opacity: modelLoading ? 0 : 1}}
                            ref={avatarRef}
                            voiceConfig={vsConfig.voiceSettings.find((val: any) => val.avatarId === avatarId).voices}
                            avatarConfig={avatarConfig}
                            setAvatarReady={setAvatarReady}
                            setPercentComplete={setPercentComplete}
                            setPercentTitle={setPercentTitle}
                        />
                    }
                </Stack>
                <Stack horizontal horizontalAlign='space-between' style={{ marginTop: 24 }}>
                    <Label styles={LabelActionStyle}>动作</Label>
                    {avatarId && avatarConfig &&
                        <ActionList
                            isAvatarReady={isAvatarReady}
                            act={handleActionClick}
                            availableActions={avatarConfig.availableActions} />
                    }
                </Stack>
            </Stack>
            <Stack horizontal={false} id="avatar Setting" styles={{ root: { marginLeft: 32, marginTop: 50, width: 416 } }}>
                <Stack horizontalAlign={'end'} styles={{ root: { marginBottom: 16 } }}>
                    {avatarInfos && avatarGender && avatarId &&
                        <ChooseAvatarModal
                            isOpen={isOpen}
                            setOpen={setIsOpen}
                            setAvatarId={setAvatarId}
                            setAvatarGender={setAvatarGender}
                            setVsConfig={setVsConfig}
                            setAvatarInfos={setAvatarInfos}
                            avatarGender={avatarGender}
                            avatarId={avatarId}
                            avatarInfos={avatarInfos}
                            vsConfig={vsConfig}
                        />
                    }
                </Stack>
                {displaySetting &&
                    <DisplaySetting
                        DisplaySetting={displaySetting}
                        ref={displayRef} />
                }
                {vsConfig && voiceData && languages &&
                    <VoiceSetting try={handleTryClick}
                        avatarId={avatarId}
                        isAvatarReady={isAvatarReady}
                        ref={voiceRef}
                        avatarGender={avatarGender}
                        languages={languages}
                        voiceData={voiceData}
                        voicesConfigValue={vsConfig.voiceSettings}
                        handleSaveClick={handleSaveClick}
                    />
                }
            </Stack>
            <PrimaryButton
                styles={buttonStyles}
                style={{ marginLeft: 60, marginTop: 24, width: 'auto' }}
                onClick={() => navigate('VACreate')}
            >
                {t('pages.VAManagement.VACreate')}
            </PrimaryButton>
        </Stack>
    </Stack>)
}

const ChooseAvatarModal: React.FunctionComponent<IChooseAvatarModal> = (props: IChooseAvatarModal) => {
    const { t } = useTranslation();
    const [iconListLeft, setIconListLeft] = useState(0)
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const handleYesClick = () => {
        const isVoiceExist = props.vsConfig.voiceSettings.find((setting: any) => setting.avatarId === checkedId)
        if (!isVoiceExist) {
            getVoiceConfig(gender)
                .then((res: any) => {
                    const newVoiceSettings = {
                        ...props.vsConfig,
                        voiceSettings:[
                            ...props.vsConfig.voiceSettings,
                            {
                                avatarId: checkedId,
                                gender,
                                voices: res.voices
                            }
                        ]
                    }
                    props.setVsConfig(newVoiceSettings)
                    props.setAvatarId(checkedId);
                    props.setAvatarGender(gender);
                })
                .catch(err => globalMessage(err))
                .finally(() => props.setOpen(false))
        } else {
            props.setAvatarId(checkedId);
            props.setAvatarGender(gender);
            props.setOpen(false);
        }
    }
    const handleNoClick = () => {
        props.setOpen(false);
        setCheckedId(props.avatarId);
        setGender(props.avatarGender);
    }

    const [checkedId, setCheckedId] = useState(props.avatarId);
    const [gender, setGender] = useState(props.avatarGender)
    const handleChooseAvatar = (id: string) => setCheckedId(id);

    const changeIconListPosition = (num: number) => {
        setIconListLeft(iconListLeft + num)
    }

    const onDeleteAvatar = (item: any) => {
        if (item.id === checkedId) {
            globalMessage('请先切换为其他形象后，再删除该虚拟形象')
        } else {
            deleteAvatar(item.id)
                .then(_res => {
                    const infos = _.cloneDeep(props.avatarInfos).filter((info: any) => info.id !== item.id)
                    props.setAvatarInfos(infos)
                    // 填补删除时出现的空白
                    if (iconListLeft !== 0 && !(iconListLeft > (infos.length - 3) * -220)) {
                        changeIconListPosition(220)
                    }
                })
                .catch(err => globalMessage(err))
        }
    }

    return (
        <Modal
            isOpen={props.isOpen}
            isModeless={true}
            styles={modalStyles}
        >
            <Stack horizontal={false}>
                <Stack
                    horizontal
                    style={{ marginBottom: 59 }}
                >
                    <Label styles={{
                        root: {
                            fontSize: 18,
                            color: '#323130',
                            fontWeight: 400,
                            marginLeft: 16,
                            marginTop: 23,
                            marginRight: 451,
                            width: 256,
                            padding: 0
                        }
                    }}>选择虚拟形象</Label>
                    <IconButton
                        iconProps={cancelIcon}
                        styles={{
                            root: {
                                marginTop: 10,
                                width: 32,
                                height: 32
                            }
                        }}
                        ariaLabel="Close popup modal"
                        onClick={handleNoClick}
                    />
                </Stack>
                <Stack
                    horizontal
                    horizontalAlign='space-between'
                    verticalAlign='center'
                    styles={{ root: { height: 398, padding: '0 12px'} }}
                >
                    <IconButton
                        iconProps={{ iconName: 'ChevronLeft' }}
                        styles={iconButtonStyles}
                        disabled={iconListLeft === 0}
                        onClick={() => changeIconListPosition(220)}
                    />
                    <Stack styles={{root: {width: 660, height: '100%', overflow: 'hidden', position: 'relative'}}}>
                        <Stack
                            horizontal
                            styles={{root: {
                                position: 'absolute',
                                transition: 'left 300ms linear',
                                top: 0,
                                left: iconListLeft
                            }}}
                        >
                            {props.avatarInfos.map((item: any) => {
                                return (
                                    <Stack horizontal={false} style={{ width: 160, margin: '0 30px' }}>
                                        <Stack styles={{root: {position: 'relative'}}}>
                                            <Label style={{ padding: 0, height: 40, fontWeight: 400, textAlign: 'center' }}>
                                                {item.avatarInfo.name}
                                            </Label>
                                            {item.owner === useSelector((state: any) => state.userInfo.value) && (
                                                <DeleteCallout
                                                    content='确定删除该虚拟形象？'
                                                    doneText='确定'
                                                    cancelText='取消'
                                                    doneFunction={() => onDeleteAvatar(item)}
                                                    buttonRender={() => (
                                                        <IconButton
                                                            styles={{root: {
                                                                width: 'auto',
                                                                height: 'auto',
                                                                padding: 0
                                                            }}}
                                                            iconProps={{
                                                                iconName: 'Delete',
                                                                styles: deleteIconStyles
                                                            }}
                                                            title="delete"
                                                        />
                                                    )}
                                                    renderStyles={{
                                                        root: {
                                                            position: 'absolute',
                                                            right: -15,
                                                            top: 0
                                                        }
                                                    }}
                                                />
                                            )}
                                        </Stack>
                                        <div>
                                            <Image
                                                imageFit={ImageFit.contain}
                                                src={item.avatarInfo.iconUrl}
                                                styles={{
                                                    root: {
                                                        height: 240,
                                                        width: 160,
                                                        marginBottom: 16,
                                                        cursor: 'pointer',
                                                        border: checkedId === item.id ? '1px solid #0078D4' : '1px solid #FFFFFF',
                                                    }
                                                }}
                                                onClick={() => { handleChooseAvatar(item.id); setGender(item.avatarInfo.gender) }}
                                            />
                                        </div>
                                        <div>
                                            {item.avatarInfo.description}
                                        </div>
                                    </Stack>)
                            })}
                        </Stack>
                    </Stack>
                    <IconButton
                        iconProps={{ iconName: 'ChevronRight' }}
                        styles={iconButtonStyles}
                        disabled={!(iconListLeft > (props.avatarInfos.length - 3) * -220)}
                        onClick={() => changeIconListPosition(-220)}
                    />
                </Stack>

                <Stack
                    horizontal
                    tokens={{ childrenGap: 0 + ' ' + 8 }}
                    styles={{ root: { marginLeft: 16 } }}>
                    <PrimaryButton
                        styles={buttonStyles}
                        onClick={handleYesClick}>
                        {t('pages.VAManagement.Yes')}
                    </PrimaryButton>
                    <DefaultButton
                        styles={buttonStyles}
                        style={{ border: '1px solid #8A8886', color: '#323130' }}
                        onClick={handleNoClick}>
                        {t('pages.VAManagement.No')}
                    </DefaultButton>
                </Stack>
            </Stack>
        </Modal>
    )
}

interface IDisplaySetting {
    isAvatarDisplayed: boolean,
    position: string,
    isVoicePlayed: boolean
}
interface IDisplaySettingProps {
    DisplaySetting: IDisplaySetting
    ref: any
}

const ChoiceGroupoptions: IChoiceGroupOption[] = [
    { key: 'left', text: '左侧' },
    { key: 'right', text: '右侧', styles: { field: { marginLeft: 15 } } },
]

const DisplaySetting: React.FunctionComponent<IDisplaySettingProps> = forwardRef((props: IDisplaySettingProps, ref) => {
    const { t } = useTranslation();
    const [isAvatarDisplayed, setIsAvatarDisplayed] = useState<boolean>(props.DisplaySetting.isAvatarDisplayed);
    const [position, setPosition] = useState(props.DisplaySetting.position);
    const [isVoicePlayed, setIsVoicePlayed] = useState<boolean>(props.DisplaySetting.isVoicePlayed);

    const handleDisplayChange = (ev: React.MouseEvent<HTMLElement>, checked: boolean | undefined) =>
        setIsAvatarDisplayed(checked ?? true);


    const handleVoiceChange = (ev: React.MouseEvent<HTMLElement>, checked: boolean | undefined) =>
        setIsVoicePlayed(checked ?? true);


    const handlePositionChange = (ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined) =>
        setPosition(option?.key ?? 'right');


    useImperativeHandle(ref, () => ({
        displaySetting: {
            'isAvatarDisplayed': isAvatarDisplayed,
            'position': position,
            'isVoicePlayed': isVoicePlayed
        }
    }))

    return (
        <Stack tokens={{ childrenGap: 10 }} styles={{ root: { marginBottom: 10 } }}>
            <Stack horizontal
                tokens={{ childrenGap: 0 + ' ' + 16 }}>
                <Label styles={toggleLabelStyles}>{t('pages.VAManagement.DisplaySetting.IsAvatarDisplayed')}</Label>
                <Toggle
                    defaultChecked={isAvatarDisplayed}
                    onChange={handleDisplayChange}
                />
            </Stack>

            <Stack horizontal
                styles={{ root: { marginBottom: 10 } }}
                tokens={{ childrenGap: 0 + ' ' + 16 }}>
                <Label styles={comboBoxLabelStyles} style={{ paddingTop: 10, paddingBottom: 10 }}>{t('pages.VAManagement.DisplaySetting.Position')}</Label>
                <ChoiceGroup
                    styles={{ flexContainer: { display: "flex" } }}
                    options={ChoiceGroupoptions}
                    onChange={handlePositionChange}
                    defaultSelectedKey={position}
                />
            </Stack>
            <Stack horizontal
                tokens={{ childrenGap: 0 + ' ' + 16 }}>
                <Label styles={toggleLabelStyles}>{t('pages.VAManagement.DisplaySetting.IsVoicePlayed')}</Label>
                <Toggle
                    defaultChecked={isVoicePlayed}
                    onChange={handleVoiceChange}
                />
            </Stack>
        </Stack>)
})

const VoiceSetting: React.FunctionComponent<IVoiceSettingProps> = forwardRef((props: IVoiceSettingProps, ref) => {
    const { t } = useTranslation()
    const [voicesConfigValue, setVoicesConfigValue] = useState<Array<IVoice>>(() => 
        props.voicesConfigValue.find(val => val.avatarId === props.avatarId)?.voices ?? getVoiceValueByDefault());
    const voiceOptionsFilter = (languages: Array<string>, gender: string, val: IVoiceDataElement): Boolean => {
        let flag = false;
        const valGender = val.gender;
        const valLanguage = val.locale.split('-')[0];
        for (let lan in languages) {
            flag ||= (valLanguage === languages[lan]);
        }
        flag &&= (valGender === gender);
        return flag;
    }

    const [TTSOptions, setTTSOptions] = useState(() => props.voiceData.filter((val: IVoiceDataElement) => (voiceOptionsFilter(props.languages,
        props.avatarGender,
        val))));

    const getLanguageOptions = (languages: Array<string>) => {
        const languageNames = new Intl.DisplayNames([currLanguage], { type: 'language' });
        return languages.map(lan =>
        ({
            text: languageNames.of(lan) ??
                TTSOptions.find((val: IVoiceDataElement) => val.locale.split('-')[0] === lan)?.localeName.split(' ')[0] ??
                lan,
            key: lan
        }))
    }
    /* Get Voice Options */
    const getVoiceOptions = (language: string | number | undefined, TTSOptions: Array<IVoiceDataElement>) => {
        return TTSOptions.filter((val: IVoiceDataElement) => (val.locale.split('-')[0] === language)).map((val: IVoiceDataElement) =>
        ({
            text: val.localName,
            key: val.shortName
        }));
    }

    /* Get Style Options */
    const getStyleOptions = (voice: string | number | undefined, TTSOptions: Array<IVoiceDataElement>) => {
        const currVoice = TTSOptions.find((val: IVoiceDataElement) => (val.shortName === voice));
        if (currVoice === undefined) {
            return [{ text: "general", key: "general" }];
        } else {
            return currVoice.styleList ? currVoice.styleList.map((val: string) =>
            ({
                text: t('pages.VAManagement.VoiceStyle.' + val),
                //voiceStyle[val],
                key: val
            })).concat([{ text: t('pages.VAManagement.VoiceStyle.general'), key: "general" }]) :
                [{ text: t('pages.VAManagement.VoiceStyle.general'), key: "general" }];
        }
    }

    const [languageList, setLanguageList] = useState<Array<IComboBoxOption>>(getLanguageOptions(props.languages))

    const [language, setLanguage] = useState<string | number | undefined>(() => voicesConfigValue[0].language);

    const [voiceList, setVoiceList] = useState<Array<IComboBoxOption>>(() => getVoiceOptions(language, TTSOptions));
    const [voice, setVoice] = useState<string | number | undefined>(() => voicesConfigValue[0].voice);

    const [styleList, setStyleList] = useState<Array<IComboBoxOption>>(() => getStyleOptions(voice, TTSOptions));
    const [style, setStyle] = useState<string | number | undefined>(() => voicesConfigValue[0].style);

    console.log("==== *** ====")

    const [pitch, setPitch] = useState(voicesConfigValue[0].pitch + 50);
    const [rate, setRate] = useState(voicesConfigValue[0].rate);

    const [trySentence, setTrySentence] = useState<string>("Hello, nice to meet you!")

    /* To pass the voicesConfig to parent element */
    useImperativeHandle(ref, () => ({
        voicesConfig: voicesConfigValue
    }))

    const getVoiceValueByDefault = () => {
        /*      
        update the TTSOptions
        update the valueConfigValues

        1. from default by languageList
        2. by rules choose first       
        */
        const currGenderVoiceConfig = props.avatarGender === 'Female' ? defaultVoicesConfig.Female : defaultVoicesConfig.Male;
        return (languageList.map(val => {
            const defaultVoice = currGenderVoiceConfig.find((defaultVal: IVoice) => defaultVal.language === val.key);
            if (defaultVoice) {
                return {
                    'language': String(val.key),
                    'voice': defaultVoice.voice,
                    'style': defaultVoice.style,
                    'rate': defaultVoice.rate,
                    'pitch': defaultVoice.pitch
                }
            } else {
                return {
                    'language': String(val.key),
                    'voice': TTSOptions.find((voice: IVoiceDataElement) => voice.locale.split('-')[0] === val.key)?.shortName ?? 'JennyMultilingualNeural',
                    'style': 'general',
                    'rate': defaultVoicesConfig.DefaultRate,
                    'pitch': defaultVoicesConfig.DefaultPitch
                }
            }
        }))
    }
    /* update option and optionList */
    useEffect(() => {
        setVoicesConfigValue(() => props.voicesConfigValue.find(val => val.avatarId === props.avatarId)?.voices ?? getVoiceValueByDefault());
    }, [props.avatarId])
    //Once change gender
    useEffect(() => {
        /*      
        update the TTSOptions   
        */
        const currTTSOptions = props.voiceData.filter((val: IVoiceDataElement) => (voiceOptionsFilter(props.languages,
            props.avatarGender,
            val)))
        setTTSOptions(currTTSOptions);

        console.log("************change*************");

    }, [props.avatarGender])

    /* update option and optionList */
    useEffect(() => {
        setVoiceList(getVoiceOptions(language, TTSOptions));
    }, [language, TTSOptions])

    useEffect(() => {
        console.log("-----curr voice -----")
        const currVoicesConfigValue = voicesConfigValue.find(val => val.language === language);
        if (currVoicesConfigValue === undefined) {
            setVoice(voiceList[0].key);
            setRate(defaultVoicesConfig.DefaultRate * 50);
            setPitch(defaultVoicesConfig.DefaultPitch + 50)
        } else {
            setVoice(currVoicesConfigValue?.voice);
            setRate(currVoicesConfigValue.rate * 50);
            setPitch(currVoicesConfigValue.pitch + 50);
        }
    }, [voiceList])

    useEffect(() => {
        setStyleList(getStyleOptions(voice, TTSOptions))
        setVoicesConfigValue(
            voicesConfigValue.map(config => config.language === language ? { ...config, voice: voice } : config)
        );
    }, [voice])

    useEffect(() => {
        console.log("-----curr style -----")
        const currStyle = voicesConfigValue.find((val) => val.voice === voice)?.style;
        const currStyleElement = styleList.find((val) => val.key === currStyle);
        setStyle(currStyleElement !== undefined ? currStyleElement.key : styleList[0].key);
    }, [styleList])

    useEffect(() => {
        setVoicesConfigValue(
            voicesConfigValue.map(config => config.language === language ? { ...config, style: style } : config)
        );
    }, [style])

    /* ------------Handler--------------- */
    const onChangeLanguage =
        (ev: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => setLanguage(option?.key);

    const onChangeVoice =
        (ev: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => setVoice(option?.key);

    const onChangeStyle =
        (ev: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => setStyle(option?.key);

    const onChangePitch = (value: number) => setPitch(value);

    const onChangedPitch = (event: MouseEvent | TouchEvent, value: number) => {
        setVoicesConfigValue(
            voicesConfigValue.map(config => config.language === language ? { ...config, pitch: value - 50 } : config)
        );
    }

    const onChangeRate = (value: number) => setRate(value);

    const onChangedRate = (event: MouseEvent | TouchEvent, value: number): void => {
        setVoicesConfigValue(
            voicesConfigValue.map(config => config.language === language ? { ...config, rate: value / 50 } : config)
        );
    }

    return (
        <Stack horizontal={false}
            tokens={{ childrenGap: 24 + ' ' + 0 }}>
            <Stack horizontal
                tokens={{ childrenGap: 0 + ' ' + 16 }}>
                <Label styles={comboBoxLabelStyles}>{t('pages.VAManagement.Language')}</Label>
                <VirtualizedComboBox
                    selectedKey={language}
                    onChange={onChangeLanguage}
                    options={languageList}
                    styles={comboBoxStyles} />
            </Stack>
            <Stack horizontal
                tokens={{ childrenGap: 0 + ' ' + 16 }}>
                <Label styles={comboBoxLabelStyles}>{t('pages.VAManagement.Voice')}</Label>
                <VirtualizedComboBox
                    selectedKey={voice}
                    options={voiceList}
                    onChange={onChangeVoice}
                    styles={comboBoxStyles} />
            </Stack>
            <Stack horizontal
                tokens={{ childrenGap: 0 + ' ' + 16 }}>
                <Label styles={comboBoxLabelStyles}>{t('pages.VAManagement.Style')}</Label>
                <VirtualizedComboBox
                    selectedKey={style}
                    options={styleList}
                    onChange={onChangeStyle}
                    styles={comboBoxStyles} />
            </Stack>
            <Stack horizontal
                tokens={{ childrenGap: 0 + ' ' + 16 }}>
                <Label styles={comboBoxLabelStyles}>{t('pages.VAManagement.Rate')}</Label>
                <Slider
                    min={0}
                    max={100}
                    value={rate}
                    onChange={onChangeRate}
                    onChanged={onChangedRate}
                    showValue
                    styles={sliderStyles}
                />
            </Stack>
            <Stack horizontal
                tokens={{ childrenGap: 0 + ' ' + 16 }}>
                <Label styles={comboBoxLabelStyles}>{t('pages.VAManagement.Pitch')}</Label>
                <Slider
                    min={0}
                    max={100}
                    value={pitch}
                    onChange={onChangePitch}
                    onChanged={onChangedPitch}
                    showValue
                    styles={sliderStyles}
                />
            </Stack>
            <TextField
                styles={textFieldStyles}
                multiline
                rows={4}
                resizable={false}
                value={trySentence}
                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => { setTrySentence(value ? value : ""); }}
            />
            <Stack
                horizontal
                tokens={{ childrenGap: 0 + ' ' + 8 }}
                styles={{ root: { marginLeft: 26, marginTop: '12px!important' } }}
            >
                <PrimaryButton
                    allowDisabledFocus
                    styles={buttonStyles}
                    onClick={() => {
                        props.try(trySentence === "" ? "please input the test sentence" : trySentence, language, {
                            'rate': rate / 50,
                            'pitch': pitch - 50,
                            'voice': voice,
                            'style': style,
                        })
                    }}
                    disabled={!props.isAvatarReady}
                >
                    {t('pages.VAManagement.Try')}
                </PrimaryButton>
                <PrimaryButton
                    styles={buttonStyles}
                    style={{ marginLeft: 50 }}
                    onClick={() => props.handleSaveClick()}
                    disabled={!props.isAvatarReady}
                >
                    {t('pages.VAManagement.Save')}
                </PrimaryButton>
            </Stack>
        </Stack>)
})

interface IActionListProps {
    availableActions: Array<any>
    act: Function
    isAvatarReady: boolean
}

const ActionList: React.FunctionComponent<IActionListProps> = (props: IActionListProps) => {
    return (
        <Stack
            horizontal
            wrap
            styles={{root: {
                width: '350px',
                height: '100%',
                maxHeight: '130px',
                overflowY: 'overlay',
                overflowX: 'hidden'
            }}}
        >
            {props.availableActions.map((value: any, index: number) => (
                <DefaultButton
                    onClick={(event) => { props.act(value.actionIndex) }}
                    styles={buttonActionStyles}
                    disabled={!props.isAvatarReady}
                >
                    {value.actionName}
                </DefaultButton>
            ))}
        </Stack>
    )
}
interface IAvatarLoaderProps {
    avatarDivStyles: any
    avatarConfig: any
    setAvatarReady: Function,
    setPercentComplete: Function,
    setPercentTitle: Function,
    voiceConfig: Array<IVoice>
    ref: any
    datGui?: boolean,
    onGetActions?: Function,
    onGetMorphTarget?: Function
}
export const AvatarLoader: React.FunctionComponent<IAvatarLoaderProps> = forwardRef((props: IAvatarLoaderProps, ref) => {
    const [avatarObject, setAvatar] = useState<any>("yes avatar");

    const [avatarConfig, setAvatarConfig] = useState(props.avatarConfig)
    console.log("*********************avatarLoader")
    console.log(avatarConfig)
    console.log(avatarObject)

    let solutionConfig: any;
    let avatar: any = null;

    const vahandlerConfig = {
        'tokenEndpoint': '/avatarmanagement/avatar/api/Speech/token',
        'subscriptionRegion': 'chinaeast2',
        'avatar': avatarObject,
        'voices': props.voiceConfig
    };
    const virtualAnchor = new VirtualAnchorHandler(vahandlerConfig);
    const animAct = (i: number) => {
        virtualAnchor.act(i);
    }

    const start = (sentence: string, lan: string, voiceConfig: object) => {
        virtualAnchor.start(sentence, lan, true, voiceConfig);
    }

    useImperativeHandle(ref, () => ({
        animAct: animAct,
        start: start,
    }))

    useEffect(() => {
        setAvatarConfig(props.avatarConfig);
    }, [props.avatarConfig])

    useEffect(() => {
        console.log("== Set Avatar == ");
        console.log(avatarObject)
        const avatarObj = new avatar(
            solutionConfig,
            () => props.setAvatarReady(true),
            (loaded, total) => {
                const percentComplete = Math.floor(loaded/total*100)/100
                const percentTitle = `${(loaded/1024/1024).toFixed(2)}MB / ${(total/1024/1024).toFixed(2)}MB`
                props.setPercentComplete(percentComplete)
                props.setPercentTitle(percentTitle)
            },
            props.datGui,
            props.onGetActions,
            props.onGetMorphTarget
        );
        setAvatar(avatarObj)
        return () => {
            if (avatarObj !== undefined) {
                console.log("---- dispose avatar ---- ")
                console.log(avatarObj)
                avatarObj.disposeThis();
            }
        }
    }, [avatarConfig])

    if (avatarConfig.solution === 'Threejs') {
        avatar = ThreejsAvatar;
        solutionConfig = avatarConfig.threejsConfig;
        return (
            <div id="currentAvatar" style={props.avatarDivStyles}>
                <canvas id='c' style={{ height: '100%' }} />
            </div>
        )
    } else {
        avatar = UnityAvatar;
        solutionConfig = avatarConfig.unityConfig;
        return (
            <div id="currentAvatar" style={props.avatarDivStyles}>
                <div id="unity-container" className="unity-desktop">
                    <canvas id="unity-canvas" style={{ height: '100%', width: '100%' }} />
                    <div id="unity-loading-bar">
                        <div id="unity-logo"></div>
                        <div id="unity-progress-bar-empty">
                            <div id="unity-progress-bar-full"></div>
                        </div>
                    </div>
                    {/* <div id="unity-mobile-warning">
                            WebGL builds are not supported on mobile devices.
                        </div> */}
                </div>
            </div>
        )
    }
});

