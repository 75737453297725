import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
export class VirtualAnchorHandler {
    //Constructor of the class
    //Input:[dict] configurations to init class
    constructor(config) {
        this.tokenEndpoint = config.tokenEndpoint;
        this.subscriptionRegion = config.subscriptionRegion;
        this.virtualAnchorElement = config.virtualAnchorElement;
        this.language = config.language;
        this.voices = {};
        for (var voice of config.voices) {
            this.voices[voice.language] = {
                "voice": voice.voice,
                "rate": voice.rate,
                "pitch": voice.pitch,
                "style": voice.style
            };
        };
        this.avatar = config.avatar;
        this.expired = true;
        //this.#tokenRefresh();
    }

    //[Private]tokenRefresh will refresh token from tokenEndpoint
    #tokenRefresh() {
        var getToken = new XMLHttpRequest();
        getToken.open('GET', this.tokenEndpoint, false);
        getToken.send();
        this.token = getToken.responseText;
        this.expired = false;
        this.#tokenExpireTimer();
        return this;
    }

    //[Private]tokenExpireTimer will count down 5 minute until token expired.
    #tokenExpireTimer() {
        console.log("timer")
        setTimeout(() => { this.expired = true }, 300000);
    }

    //[Private]textToSSML transfer text to SSML format to get personalized experience
    //Input: [string] text need to be transfered to SSML format
    //Output: [string] encoded ssml
    #textToSSML(message, language = 'zh') {
        var voiceConfig = this.voices[language];
        console.log(voiceConfig);
        return `<speak version="1.0" xmlns="https://www.w3.org/2001/10/synthesis" xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang="${language
            }">
              <voice name='${voiceConfig.voice}'>
              <prosody rate="${voiceConfig.rate}" pitch="${voiceConfig.pitch}%"><mstts:express-as style="${voiceConfig.style}" role="Default">
              ${message}</mstts:express-as></prosody>
            </voice>
            </speak>`;
    }
    #textToSSMLMerchant(message, language, voiceConfig) {
        return `<speak version="1.0" xmlns="https://www.w3.org/2001/10/synthesis" xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang="${language
            }">
              <voice name='${voiceConfig.voice}'>
              <prosody rate="${voiceConfig.rate}" pitch="${voiceConfig.pitch}%"><mstts:express-as style="${voiceConfig.style}" role="Default">
              ${message}</mstts:express-as></prosody>
            </voice>
            </speak>`;
    }

    //[Private] visemeHandler drives virtual anchor in iframe to show corresponding viseme
    //Input:[dict] visime dict need to be showed by the virtual anchor
    async #visemeHandler(visemeRes) {
        console.log(visemeRes);
        //for threejs demo
        //await this.virtualAnchorElement.contentWindow.postMessage({type:'prepareTalk', data:visemeRes}, '*');
        await this.avatar.prepareTalk(visemeRes);
    }

    //[Public] start synthesize the input text and the corresponding viseme, then play it with virtual anchor.
    //Input:[string] text need to be played
    start(text, language, isMerchant, voiceConfig) {
        if (this.expired) {
            this.#tokenRefresh();
        }
        this.stop();
        var speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(this.token, this.subscriptionRegion);

        //let player stop when audio has arrived
        this.player = new SpeechSDK.SpeakerAudioDestination();

        this.player.onAudioStart = () => { console.log("--- Player Pause ---"); this.player.pause(); };
        this.player.onAudioEnd = () => { console.log("--- Player Stop ---"); this.stop(); };
        var audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(this.player);

        var synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
        var ssml = isMerchant ? this.#textToSSMLMerchant(text, language, voiceConfig) : this.#textToSSML(text, language);
        var visemeRes = { 'visemeInfoList': [] };

        synthesizer.synthesisCompleted = function (s, e) {
            console.log("Synthesis Completed");
        };

        synthesizer.speakSsmlAsync(ssml,
            async () => {
                synthesizer.close();
                //complete viseme sequence processing
                await this.#visemeHandler(visemeRes);
                console.log("viseme Processed");
                //Let player play when visemes have been processed
                this.resume();
            },
            () => {
                synthesizer.close();
            });

        synthesizer.synthesizing = function (s, e) {
            console.log("Synthesizing");
        };

        synthesizer.visemeReceived = (o, e) => {
            visemeRes.visemeInfoList.push({ 'visemeId': e.visemeId, 'audioOffset': e.audioOffset / 10000000 });
            //console.log('Viseme Received');
            //console.log(o);
        }
    }

    //[Public] stop playing and viseme showing
    stop() {
        if (this.player) {
            this.player.pause();
        }
        console.log("on stop");
        //this.virtualAnchorElement.contentWindow.postMessage({type:'stopTalk'}, '*');       
        this.avatar.stopTalk();
    }

    resume() {
        console.log("on talk");
        //this.virtualAnchorElement.contentWindow.postMessage({type:'beginTalk'}, '*');
        this.avatar.beginTalk();
        setTimeout(() => { this.player.resume(); }, 50);

    }

    act(index) {
        console.log("on act");
        this.avatar.animAct(index);
        //this.virtualAnchorElement.contentWindow.postMessage({type:'act', data:index}, '*');
    }
}

