import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import ReactFlow, {
  useReactFlow,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  NodeMouseHandler,
} from "reactflow";
import {
  IStackStyles,
  Stack,
  TextField,
  Dropdown,
  IDropdownOption,
  Panel,
  DefaultButton,
  PrimaryButton,
  PanelType,
  MessageBarType,
} from "@fluentui/react";
import { globalMessage } from "SRC/components/messagebar";
import { useTranslation } from "react-i18next";
import "reactflow/dist/style.css";
import { EventPanel } from "SRC/components/eventpanel";
import { CardPanel } from "SRC/components/cardpanel";
import VSNode from "SRC/components/vsnode";
import {
  fetchScenarioDetail,
  deleteScenarioByName,
  createNewScenario,
} from "SRC/api/scenario";
import { scenarioGraphData } from "./scenarioGraphData";
import { TextPanel } from "SRC/components/textpanel";
import { useSelector } from "react-redux";
import _ from "lodash";
import "./index.css";
const getNodes = (predefinedScenarioType: string) => {
  const scenarioGraph = scenarioGraphData.scenarioGraphs.filter((item) => {
    return item.predefinedScenarioType === predefinedScenarioType;
  });
  return scenarioGraph[0].nodes;
};
const getEdges = (predefinedScenarioType: string) => {
  const scenarioGraph = scenarioGraphData.scenarioGraphs.filter((item) => {
    return item.predefinedScenarioType === predefinedScenarioType;
  });
  return scenarioGraph[0].edges;
};

export const Flow: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.scenarioConfigurate",
  });
  const {
    scenarioName: savedScenarioName,
    predefinedScenarioType: savedPredefinedScenarioType,
    triggerEventType: savedTriggerEventType,
    periodType: savedPeriodType,
    tag: savedTag,
  } = location.state as {
    scenarioName: string;
    predefinedScenarioType: string;
    triggerEventType: string;
    periodType: string;
    tag: string;
  };
  const merchantID = useSelector((state: any) => state.userInfo.value);
  const [scenarioName, setScenarioName] = React.useState(savedScenarioName);
  const [triggerEventType, setTriggerEventType] = React.useState(
    savedTriggerEventType
  );
  const [triggerCondition, setTriggerCondition] =
    React.useState(savedPeriodType);
  const [tag, setTag] = React.useState(savedTag);
  const [item, setItem] = React.useState([]);
  const [panelStatus, setPanelStatus] = React.useState(false);
  const [panelHeaderText, setPanelHeaderText] = React.useState("");
  const [panelContent, setPanelContent] = React.useState(<div />);
  const [nodes, setNodes, onNodesChange] = useNodesState(
    getNodes(savedPredefinedScenarioType)
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    getEdges(savedPredefinedScenarioType)
  );
  const { setViewport, zoomIn, zoomOut } = useReactFlow();
  const proOptions = { hideAttribution: true };
  const nodeTypes = React.useMemo(
    () => ({
      VSNode: VSNode,
    }),
    []
  );
  const [saving, setSaving] = React.useState(false);
  useEffect(() => {
    fetchScenarioDetail(merchantID, savedScenarioName)
      .then((res: any) => {
        setItem(res.wfRecord.itemDict.zh);
      })
      .catch((err) => {
        globalMessage(err);
      });
  }, []);

  useEffect(() => {
    if (saving) onSave();
  }, [saving]);

  useEffect(() => {
    setViewport({ x: -100, y: 0, zoom: 1 }, { duration: 800 });
  }, [setViewport]);

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const functionBarStyle: IStackStyles = {
    root: {
      width: "calc(100% - 228px)",
      minWidth: 1000,
      position: "absolute",
      zIndex: 1,
      paddingLeft: 30,
      paddingRight: 30,
      justifyContent: "space-between",
    },
  };

  const TextFieldStyle = {
    root: {
      width: 250,
      paddingRight: 50,
    },
  };

  const options: IDropdownOption[] = [
    { key: "Promotion", text: t("IncreaseSales") },
    { key: "Welcome", text: t("UserService") },
    { key: "GetUserInfo", text: t("GetUserInfo") },
  ];

  const dropDownStyles = {
    dropdown: { width: 250 },
  };

  const panelStyles = {
    main: {
      marginTop: 180,
      paddingLeft: 5,
      paddingRight: 5,
    },
    header: {
      marginBottom: 20,
    },
    content: {
      padding: 10,
    },
  };

  const findItem: any = (items: any[], id: string) => {
    return items.filter((item) => item.itemId === id)[0];
  };

  const setSingleItem = useCallback(
    (content: any, id: string) => {
      let newItem = _.cloneDeep(item);
      findItem(newItem, id).content = content;
      setItem(newItem);
    },
    [item]
  );

  const onNodeClick: NodeMouseHandler = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>, node: any) => {
      switch (node.data.label) {
        case "onEvent":
          setPanelStatus(true);
          setPanelHeaderText(t("Event") as string);
          setPanelContent(
            <EventPanel
              Event={triggerEventType}
              TriggerCondition={triggerCondition}
              setEvent={setTriggerEventType}
              setTriggerCondition={setTriggerCondition}
            />
          );
          break;
        case "Card":
          setPanelStatus(true);
          setPanelHeaderText(t("Card") as string);
          setPanelContent(
            <div className="scenarioConfiguratePanelWrapper">
              <CardPanel card={item} setCard={setItem} nodeId={node.id} />
            </div>
          );
          break;
        case "Text":
          setPanelStatus(true);
          setPanelHeaderText(t("Text") as string);
          setPanelContent(
            <div className="scenarioConfiguratePanelWrapper">
              <TextPanel
                items={item}
                setText={setSingleItem}
                nodeId={node.id}
              />
            </div>
          );
          break;
        default:
          break;
      }
    },
    [triggerEventType, triggerCondition, item, t, setSingleItem]
  );

  const onSave = () => {
    deleteScenarioByName(merchantID, savedScenarioName).then((res) => {
      createNewScenario(
        merchantID,
        scenarioName,
        tag,
        "zh",
        savedPredefinedScenarioType,
        triggerCondition,
        triggerEventType,
        item
      ).then((res) => {
        navigate("/DEVA/ScenarioManagement");
      });
    });
  };

  return (
    <>
      <Stack horizontal styles={functionBarStyle}>
        <Stack.Item align="start">
          <Stack horizontal>
            <TextField
              label={t("ScenarioName") as string}
              styles={TextFieldStyle}
              defaultValue={scenarioName}
              onChange={(ev, newValue) =>
                setScenarioName(newValue || savedScenarioName)
              }
            />
            <Dropdown
              placeholder={t("SelectTagHere") as string}
              label={t("ScenarioType") as string}
              options={options}
              styles={dropDownStyles}
              defaultSelectedKey={tag}
              onChange={(ev, option) => {
                setTag((option?.key as string) || tag);
              }}
            />
          </Stack>
        </Stack.Item>

        <Stack.Item align="end" styles={{ root: {} }}>
          <DefaultButton
            text={t("Cancel") as string}
            onClick={() => {
              navigate("/DEVA/ScenarioManagement");
            }}
            allowDisabledFocus
            styles={{ root: { marginRight: 40 } }}
          />
          <PrimaryButton
            text={t("Save") as string}
            onClick={() => {
              setSaving(true);
              globalMessage("success", MessageBarType.success);
            }}
            allowDisabledFocus
          />
        </Stack.Item>
      </Stack>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        proOptions={proOptions}
        onNodeClick={onNodeClick}
        nodeTypes={nodeTypes}
      >
        <Background style={{ backgroundColor: "#EFF2F6" }} />
        <Controls />
      </ReactFlow>
      <Panel
        headerText={panelHeaderText}
        isBlocking={false}
        isOpen={panelStatus}
        onDismiss={() => setPanelStatus(false)}
        styles={panelStyles}
        type={PanelType.custom}
        customWidth="360px"
      >
        {panelContent}
      </Panel>
    </>
  );
};
