import React, { useState, useEffect } from "react";
import { Input, Button, Drawer, Tooltip, Spin, Popconfirm, Select } from "antd";
import {
  EditOutlined,
  SendOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  UserOutlined,
  PictureOutlined,
  CopyOutlined,
  CloudDownloadOutlined,
  ReloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "./index.less";
import {
  Dalle,
  dalleHistoryRecently,
  dalleHistorySummary,
  dalleHistoryId,
  dalleHistoryIdDelete,
  dalleHistoryIdContentDelete,
} from "SRC/api/gptdalle";
import { globalMessage } from "SRC/components/messagebar";

interface IChatHistory {
  chatHistoryId: string;
  summary: string;
  mostRecentCall: string;
}

interface IChatContent {
  role: string;
  content: string;
  prompt?: string;
}

const dalleOptions = [
  {
    value: "Dalle3",
    label: "DALLE-3",
  },
  {
    value: "Dalle2",
    label: "DALLE-2",
  },
];


export const StableDiffusion: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const [dalleType, setDalleType] = useState("Dalle3");
  const [chatHistory, setChatHistory] = useState<IChatHistory[]>([]);
  const [currentChatID, setCurrentChatID] = useState<string>("");
  const [currentChatContent, setCurrentChatContent] = useState<IChatContent[]>(
    []
  );
  const [generating, setGenerating] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [inputHeight, setInputHeight] = useState<number|undefined>(0);

  //Read old Chat History and load the newest one set the currentChatID and currentChatContent
  useEffect(() => {
    dalleHistorySummary().then((res: any) => {
      setChatHistory(res.data);
    });
  }, []);

  useEffect(() => {
    const dialogueDiv = document.getElementById("dialogueDiv");
    dialogueDiv?.scroll({ top: dialogueDiv.scrollHeight, behavior: "smooth" });
  }, [currentChatContent, inputHeight]);

  const handleRemove = (content: string) => {
    let removeIndex = currentChatContent.findIndex(
      (chat) => chat.content === content
    );

    // Ensure the content was found and it's not the first element
    if (removeIndex !== -1 && removeIndex > 0) {
      setCurrentChatContent((prevChatContent) => {
        return prevChatContent.filter(
          (_, index) => index !== removeIndex && index !== removeIndex - 1
        );
      });
    }
    // If it's the first element, just remove it
    else if (removeIndex === 0) {
      setCurrentChatContent((prevChatContent) => {
        return prevChatContent.filter((_, index) => index !== removeIndex);
      });
    }
  };

  const renderUserChatContent = (content: string) => {
    return (
      <div className="chat-user">
        <p className="chat-user-input">{content}</p>
        <div className="chat-user-icon">
          <UserOutlined />
        </div>
      </div>
    );
  };

  const renderBotChatContent = (
    content: string,
    loading: boolean,
    prompt?: string
  ) => {
    if (loading) {
      return (
        <div className="chat-bot">
          <div className="chat-bot-icon">
            <PictureOutlined />
          </div>
          <div className="chat-bot-input-img">
            <div className="chat-bot-input-img-loading">
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
              />
              <span>为您生成图像...</span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="chat-bot">
          <div className="chat-bot-icon">
            <PictureOutlined />
          </div>
          <div className="chat-bot-input-img">
            <div className="chat-bot-input-img-succeed">
              <p>下面是为你生成的图像：</p>
              <img src={content} className="chat-bot-img" />
              <div className="chat-bot-img-btn">
                <Tooltip title="复制Prompt">
                  <Button
                    type="default"
                    icon={<CopyOutlined />}
                    onClick={() => {
                      navigator.clipboard.writeText(prompt ? prompt : "");
                    }}
                  />
                </Tooltip>
                <Tooltip title="重新生成">
                  <Button
                    type="default"
                    icon={<ReloadOutlined />}
                    onClick={() => {
                      onRegenerateClick(prompt ? prompt : "");
                    }}
                  />
                </Tooltip>
                <Tooltip title="下载图片">
                  <Button
                    type="default"
                    icon={<CloudDownloadOutlined />}
                    onClick={() => {
                      const a = document.createElement("a");
                      a.href = content;
                      a.target = "_blank"; // This will open the link in a new tab
                      a.download = content;
                      a.click();
                    }}
                  />
                </Tooltip>
                <Tooltip title="删除图片">
                  <Button
                    type="default"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      handleRemove(content);
                      dalleHistoryIdContentDelete(currentChatID, content);
                      if (currentChatContent.length == 0) setCurrentChatID("");
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const onRegenerateClick = (prompt: string) => {
    setGenerating(true);
    let newContent = [...currentChatContent, { role: "user", content: prompt }];
    //Generate Image
    Dalle({
      version: dalleType,
      HistoryId: currentChatID,
      Messages: newContent,
    })
      .then((res: any) => {
        newContent.pop();
        newContent.push({
          role: "assistant",
          content: res.imageUrl,
          prompt: newContent[newContent.length - 1].content,
        });
        setCurrentChatContent(newContent);
      })
      .then(() => {
        if (currentChatID == "") {
          dalleHistoryRecently().then((res: any) => {
            setCurrentChatID(res.data.chatHistoryId);
            let newChatHistory = {
              chatHistoryId: res.data.chatHistoryId,
              summary: res.data.summary,
              mostRecentCall: res.data.mostRecentCall,
            };
            setChatHistory([newChatHistory, ...chatHistory]);
          });
        }
      })
      .finally(() => {
        setGenerating(false);
      });
    newContent.push({ role: "assistant", content: "loading" });
    setCurrentChatContent(newContent);
  };

  const onUserClick = () => {
    setGenerating(true);
    let newContent = [
      ...currentChatContent,
      { role: "user", content: userInput },
    ];
    setUserInput("");
    //Generate Image
    Dalle({
      version: dalleType,
      HistoryId: currentChatID,
      Messages: newContent,
    })
      .then((res: any) => {
        newContent.pop();
        newContent.push({
          role: "assistant",
          content: res.imageUrl,
          prompt: newContent[newContent.length - 1].content,
        });
        setCurrentChatContent(newContent);
      })
      .then(() => {
        if (currentChatID == "") {
          dalleHistoryRecently().then((res: any) => {
            setCurrentChatID(res.data.chatHistoryId);
            let newChatHistory = {
              chatHistoryId: res.data.chatHistoryId,
              summary: res.data.summary,
              mostRecentCall: res.data.mostRecentCall,
            };
            setChatHistory([newChatHistory, ...chatHistory]);
          });
        }
      })
      .catch((err) => {
        globalMessage("服务器过载或对话还含有不当内容，请重试", 2);
        newContent.pop();
        newContent.pop();
        setCurrentChatContent(newContent);
      })
      .finally(() => {
        setGenerating(false);
      });
    newContent.push({ role: "assistant", content: "loading" });
    setCurrentChatContent(newContent);
    setUserInput("");
  };

  const deleteHistoryChat = (e: any, history: IChatHistory) => {
    e.stopPropagation();
    setLoading(true);
    dalleHistoryIdDelete(history.chatHistoryId)
      .then((res: any) => {
        // 删除记录中的对话数据
        const newChatHistory = chatHistory.filter(
          (item) => history.chatHistoryId !== item.chatHistoryId
        );
        setChatHistory(newChatHistory);
        if (currentChatID === history.chatHistoryId) {
          setCurrentChatID("");
          setCurrentChatContent([]);
        }
      })
      .catch((err) => {
        globalMessage(err);
      })
      .finally(() => setLoading(false));
  };

  const showDrawer = () => {
    setLoading(true);
    dalleHistorySummary().then((res: any) => {
      setChatHistory(res.data);
      setLoading(false);
    });
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleChange = (value: string) => {
    setDalleType(value);
  };

  return (
    <div className="image-gpt-wrapper">
      <div className="chat-wrapper">
        <div className="gpt-type">
          <label>当前版本：</label>
          <Select
            options={dalleOptions}
            value={dalleType}
            style={{ width: 120 }}
            onChange={handleChange}
          />
        </div>
        {currentChatContent.length > 0 ? (
          <div className="chat-info" id="dialogueDiv">
            {currentChatContent.map((content: IChatContent) => {
              if (content.role == "user")
                return renderUserChatContent(content.content);
              else if (content.content == "loading")
                return renderBotChatContent(
                  content.content,
                  true,
                  content.prompt
                );
              else
                return renderBotChatContent(
                  content.content,
                  false,
                  content.prompt
                );
            })}
          </div>
        ) : (
          <div className="chat-main">
            <label>图片生成</label>
            <p>欢迎来到图片生成模板，你可以在这里生成任何想要的图片！</p>
            <img src={require("SRC/assets/imgs/dalle.jpg")} />
          </div>
        )}
        <div className="chat-input">
          <EditOutlined className="site-form-item-icon" />          
          <Button
            size="small"
            className="site-form-item-btn"
            type="primary"
            icon={<SendOutlined />}
            disabled={!userInput || generating}
            onClick={onUserClick}
          />
          <Input.TextArea
            id='dialogueInput'
            autoSize={{
              minRows: 1,
              maxRows: 12
            }}
            allowClear
            placeholder="请输入..."
            value={userInput}
            onChange={(e) => {
              setUserInput(e.target.value);
              setTimeout(() => {
                const dialogueInput = document.getElementById("dialogueInput")
                setInputHeight(dialogueInput?.offsetHeight)              
              }, 500)
            }}
            onPressEnter={(!userInput || generating) ? () => null : (e) => {
              e.preventDefault(); // 阻止默认行为，即不插入换行符
              onUserClick();
            }}
          />
        </div>
      </div>
      <div className="record-wrapper">
        <Button
          disabled={generating}
          icon={<LeftCircleOutlined />}
          type="primary"
          style={{ position: "static" }}
          onClick={showDrawer}
        />
      </div>
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
        getContainer={false}
      >
        <div className="drawer-header" onClick={onClose}>
          <RightCircleOutlined className="drawer-icon-right" />
          <span className="drawer-header-text">历史记录</span>
        </div>
        <Spin spinning={loading}>
          <div className="drawer-content">
            <Button
              className="drawei-content-create"
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => {
                setCurrentChatID("");
                setCurrentChatContent([]);
                onClose();
              }}
            >
              新建对话
            </Button>
            <div className="drawer-content-date">
              <p className="drawer-content-title">今天</p>
              {chatHistory.map((history: IChatHistory) => {
                return (
                  <p
                    className="drawer-content-input"
                    onClick={() => {
                      dalleHistoryId(history.chatHistoryId).then((res: any) => {
                        setCurrentChatID(history.chatHistoryId);
                        let messages = res.data.messages.map(
                          (chat: any, index: number) => {
                            if (chat.role === "assistant" && index > 0) {
                              const previousUserChat =
                                res.data.messages[index - 1];
                              if (previousUserChat.role === "user") {
                                return {
                                  ...chat,
                                  prompt: previousUserChat.content,
                                };
                              }
                            }
                            return chat;
                          }
                        );
                        console.log(messages);
                        setCurrentChatContent(messages);
                        onClose();
                      });
                    }}
                  >
                    <span>{history.summary}</span>
                    <Popconfirm
                      title="删除对话"
                      description="确认删除该对话？"
                      onConfirm={(e) => deleteHistoryChat(e, history)}
                      onCancel={(e: any) => e.stopPropagation()}
                      okText="确认"
                      cancelText="取消"
                    >
                      <DeleteOutlined
                        className="drawer-content-icon-btn"
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Popconfirm>
                  </p>
                );
              })}
            </div>
          </div>
        </Spin>
        <div className="drawer-footer">系统仅保存30天对话历史</div>
      </Drawer>
    </div>
  );
};
