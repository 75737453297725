export const nodestyles={
    trigger:{
        width: 45,
        height: 45,
        padding: "5px 10px 10px 10px",
        background: "#0078D4",
        boxShadow: "0px 3px 6px 1px rgba(0,0,0,0.16)",
        borderRadius: 22,
        opacity: 1,
        color: "#FFFFFF",
        border: "none",
        fontSize: 25,
    },
    action:{
        width: 45,
        height: 45,
        padding:10,
        background: "#6363F5",
        boxShadow: "0px 3px 6px 1px rgba(0,0,0,0.16)",
        borderRadius:5,
        color: "#FFFFFF",
        border: "none",
        fontSize: 25,
    }
}