import React, { useState, useEffect } from 'react'
import { Upload } from 'antd'
import _ from 'lodash'
import type { RcFile } from 'antd/es/upload'
import { PlusOutlined } from '@ant-design/icons'
import {
  TextField,
  IconButton,
  DefaultButton,
  IContextualMenuProps,
  DetailsList,
  SelectionMode,
  IDragDropEvents,
  IDragDropContext,
  mergeStyles,
  getTheme,
  Selection,
  IColumn,
  ICellStyleProps,
  IDetailsColumnStyles,
  DetailsRow,
  IDetailsRowStyles
} from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { globalMessage } from 'SRC/components/messagebar'
import { uploadImage, getUploadImgUrlPrefix }  from 'SRC/api/q&a'
import './index.css'

interface IResponseBlock {
  id?: string,
  type?: string
  languageTextDict?: any
  imageUrl?: string
  inputLanguage?: string
}

interface IAnswersListProps {
  responseBlocks: IResponseBlock[]
  onChange: (blocks: IResponseBlock[]) => void
}

const textFieldStyles = {
  root: {
    marginBottom: '10px',
    width: '310px'
  },
  fieldGroup: {
    borderColor: '#DCDFE6!important',
    borderRadius: '2px'
  }
}

const addAnswerBtnStyles = {
  root: {
    width: '261px',
    border: 'none',
    display: 'block',
    margin: '0 auto'
  },
  menuIcon: {
    display: 'none'
  },
}

const cellStyle: ICellStyleProps = {
  cellLeftPadding: 3,
  cellRightPadding: 0,
  cellExtraRightPadding: 0,
}

const columnStyles: Partial<IDetailsColumnStyles> = {
  cellTitle: { padding: '0px' },
  cellName: { padding: '0px' }
}

const rowStyle: Partial<IDetailsRowStyles> = {
  root: {
    background: 'transparent',
    ':hover': {
      background: 'transparent'
    }
  },
  cell: {padding: '0px'}
}

const buttonStyles = {
  root: {
    padding: '0px',
    width: 'auto',
    height: 'auto',
    marginLeft: '5px'
  }
}

const iconStyles = {
  root: {
    color: '#605E5C',
    margin: '0px'
  }
}

export const AnswersList: React.FunctionComponent<IAnswersListProps> = (props) => {
  const { t } = useTranslation()
  const { responseBlocks, onChange } = props
  const [prefix, setPrefix] = useState('')
  const [answersList, setAnswersList] = useState<IResponseBlock[]>([])

  useEffect(() => {
    setAnswersList(responseBlocks)
    getUploadImgUrlPrefix()
      .then((res: any) => {
        setPrefix(res)
      })
      .catch((err: string) => globalMessage(err))
  }, [])

  useEffect(() => {
    onChange(answersList)
  }, [answersList])

// upload image
  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      globalMessage(t('pages.QAManagement.detail.messageImageTypeError'))
    }
    return isJpgOrPng
  }

// 上传图片
  const onUploadExamGroup = (option: { file: string | Blob }, index: number) => {
    const formData = new FormData()
    formData.append('files', option.file)
    uploadImage(formData)
      .then((res: any) => {
        const newAnswersList = _.cloneDeep(answersList)
        newAnswersList[index].imageUrl = res[0].url
        setAnswersList(newAnswersList)
      })
      .catch((err: { code: string }) => {
        globalMessage(err.code)
      })
  }

// 改变答案文本内容
  const setBlockText = (value: string, index: number) => {
    const newAnswersList = _.cloneDeep(answersList)
    const answer = newAnswersList[index]
    answer.languageTextDict[answer.inputLanguage || 'en'] = value
    setAnswersList(newAnswersList)
  }
// 添加某条答案内容
const addAnswer = (type: string) => {
  const newAnswersList = _.cloneDeep(answersList)
  if (type === 'text') {
    newAnswersList.push({
      type: "text",
      languageTextDict: { en: "" },
      inputLanguage: "en",
      imageUrl: ''
    })
  } else {
    newAnswersList.push({
      type: "image",
      languageTextDict: { en: "" },
      inputLanguage: "en",       
      imageUrl: ""
    })
  }
  setAnswersList(newAnswersList)
}     
// 删除某条答案内容
  const deleteAnswer = (index: number) => {
    const newAnswersList = _.cloneDeep(answersList)
    newAnswersList.splice(index, 1)
    setAnswersList(newAnswersList)
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{t('pages.QAManagement.detail.btnUploadImage')}</div>
    </div>
  )

  const menuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    items: [
      { 
        key: 'text',
        iconProps: { iconName: 'TextField' },
        text: t('pages.QAManagement.detail.btnAddText') || '',
        onClick:() => addAnswer('text')
      },
      { 
        key: 'image',
        iconProps: { iconName: 'PictureFill' },
        text: t('pages.QAManagement.detail.btnAddImage') || '',
        onClick:() => addAnswer('image')
      }
    ]
  }

  let _draggedItem: IResponseBlock | undefined, _draggedIndex: number, _iconName: string | null
  const _selection = new Selection()

  const _getDragDropEvents = (): IDragDropEvents => {
    const theme = getTheme()  
    const dragEnterClass = mergeStyles({
      backgroundColor: theme.palette.neutralLight,
    })
    return {
      canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
        return true;
      },
      canDrag: (item?: any) => {
        return true;
      },
      onDragEnter: (item?: any, event?: DragEvent) => {
        // return string is the css classes that will be added to the entering element.
        return dragEnterClass
      },
      onDragLeave: (item?: any, event?: DragEvent) => {
        return;
      },
      onDrop: (item?: any, event?: DragEvent) => {
        if (_draggedItem && _iconName === 'BulletedListText') {
          insertBeforeItem(item)
        }
      },
      onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
        _draggedItem = item
        _draggedIndex = itemIndex!
      },
      onDragEnd: (item?: any, event?: DragEvent) => {
        _draggedItem = undefined
        _draggedIndex = -1
        _iconName = null
      },
    };
  }

  const insertBeforeItem = (item: IResponseBlock): void => {
    const draggedItems = _selection.isIndexSelected(_draggedIndex)
      ? (_selection.getSelection() as IResponseBlock[])
      : [_draggedItem!]

    const insertIndex = answersList.indexOf(item)
    const items = answersList.filter(itm => draggedItems.indexOf(itm) === -1)

    items.splice(insertIndex, 0, ...draggedItems)
    setAnswersList(items)
  }

  const setDropEvnet = (event: any) => {
    _iconName = event?.target?.getAttribute('data-icon-name')
  }

  const columns: IColumn[] = [
    {
      key: 'drag',
      name: 'drag',
      fieldName: 'drag',
      minWidth: 25,
      maxWidth: 25,
      isRowHeader: true,
      data: 'string',
      styles: columnStyles,
      onRender: (item: IResponseBlock, index?: number) => {
        return (
          <IconButton
            styles={buttonStyles}
            iconProps={{
              iconName: 'BulletedListText',
              styles: iconStyles
            }}
            title="drag drop"
            className='dropIcon'
          />
        ) 
      }
    },
    {
      key: 'action',
      name: 'action',
      fieldName: 'action',
      minWidth: 260,
      maxWidth: 261,
      isRowHeader: true,
      data: 'string',
      styles: columnStyles,
      onRender: (item: IResponseBlock, index: number | undefined) => {
        const newIndex = index || 0
        return (
          <div style={{display: 'flex'}} key={newIndex} 
            onMouseDown={(ev) => {ev.stopPropagation()}}
            onDragStart={(ev) => {ev.stopPropagation()}}
            onMouseMove={(ev) => {ev.stopPropagation()}}
            onClick={(ev) => {ev.stopPropagation()}}
          >
              {item.type === 'text' ? (
                <TextField
                  multiline
                  styles={textFieldStyles}
                  value={item.languageTextDict[item.inputLanguage || 'en']}
                  onChange={(e: any, newValue: any) => setBlockText(newValue || '', newIndex)}
                />
              ) : (
                <Upload
                  name="image"
                  listType="picture-card"
                  className="detail-img-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  customRequest={(option) => onUploadExamGroup(option, newIndex)}
                >
                  {item.imageUrl ? <img src={item.imageUrl} alt="img" className='detail-answer-upload-img' /> : uploadButton}
                </Upload>
              )}
            </div>
        )
      }
    },
    {
      key: 'delete',
      name: 'delete',
      fieldName: 'delete',
      minWidth: 25,
      maxWidth: 25,
      isRowHeader: true,
      data: 'string',
      styles: columnStyles,
      onRender: (item: IResponseBlock, index: number | undefined) => {
        return (
          <IconButton
            styles={buttonStyles}
            iconProps={{
              iconName: 'Delete',
              styles: iconStyles
            }}
            title="delete"
            onClick={() => deleteAnswer(index || 0)}
          />
        ) 
      }
    }
  ]

  return (
    <div className="detail-answer-content">
      <div className='detail-answer-wrapper'>
        <DetailsList
          setKey="id"
          items={answersList}
          columns={columns}
          selectionMode={SelectionMode.none}
          dragDropEvents={_getDragDropEvents()}
          cellStyleProps={cellStyle}
          isHeaderVisible={false}
          onRenderRow={(props: any) => {
            return <DetailsRow {...props} styles={rowStyle} onMouseDown={(e: any) => setDropEvnet(e)} />
          }}
        />
        <DefaultButton
          styles={addAnswerBtnStyles}
          iconProps={{ iconName: 'AddTo', styles: {root: {color: '#0078D4'}} }}
          // menuProps={menuProps}
          onClick={() => addAnswer('text')}
        />
      </div>
    </div>
  )
}
