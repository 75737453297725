import { createSlice } from '@reduxjs/toolkit';

export const isAdminSlice = createSlice({
  name: 'isAdmin',
  initialState: {
    value: undefined,
  },
  reducers: {
    setIsAdmin: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: {
    value: undefined,
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const appsSlice = createSlice({
  name: 'apps',
  initialState: {
    value: {},
  },
  reducers: {
    setApps: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const nickNameSlice = createSlice({
  name: 'nickName',
  initialState: {
    value: undefined,
  },
  reducers: {
    setNickName: (state, action) => {
      state.value = action.payload;
    },
  },
});

// 为每个 case reducer 函数生成 Action creators
export const { setIsAdmin } = isAdminSlice.actions;
export const { setUserInfo } = userInfoSlice.actions;
export const { setApps } = appsSlice.actions;
export const { setNickName } = nickNameSlice.actions;