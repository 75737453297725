import React, { useRef, useState, useEffect } from 'react'
import { Tabs, DatePicker, Table } from 'antd'
import dayjs from 'dayjs'
import { Stack, StackItem } from '@fluentui/react/lib/Stack'
import {
  IStackStyles,
  IStackItemStyles,
  ILabelStyles,
  Label,
  TextField,
  ActionButton,
  IconButton,
  Dropdown,
  IDropdownOption,
  DefaultButton,
  PrimaryButton,
  ChoiceGroup,
  IChoiceGroupOption,
  Modal,
  mergeStyleSets,
  getTheme,
  FontSizes,
  FontWeights,
  SearchBox,
  TooltipHost,
  Icon,
  Spinner,
  SpinnerSize,
  MessageBarType
} from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  createQuestionAndAnswer,
  editQuestionAndAnswer,
  getSearchByQuestionAndTopic,
  getByMerchantId,
  getQuestionAndAnswer
}  from 'SRC/api/q&a'
import { globalMessage } from 'SRC/components/messagebar'
import { AnswersList } from 'SRC/components/answerslist'
import './index.css'
import _ from 'lodash'
import utc from 'dayjs/plugin/utc'

interface IQuestion {
  inputLanguage: string
  languageTextDict: any
}
interface IResponseBlock {
  id?: string,
  type?: string
  languageTextDict?: any
  imageUrl?: string
  inputLanguage?: string
}

interface IAnswer {
  id?: string
  alwaysActive: boolean
  effectiveDate?: string
  expirationDate?: string
  responseBlocks: IResponseBlock[]
  relatedQuestionIds: React.Key[]
}

const stackWrapperStyles: IStackStyles = {
  root: {
    padding: '18px 0px'
  }
}

const stackContainerStyles: IStackStyles = {
  root: {
    width: '50%',
    marginRight: '28px',
    minWidth: '500px'
  }
}

const stackStyles: IStackStyles = {
  root: {
    background: '#fff',
    padding: '20px',
    marginTop: '20px!important',
    height: 'calc(100vh - 230px)',
    overflow: 'auto'
  },
}

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    marginBottom: '20px'
  },
}

const labelStyles: ILabelStyles = {
  root: {
    width: '75px',
    textAlign: 'right',
    color: '#323130',
    fontWeight: '400',
    marginRight: '20px'
  },
}

const textFieldStyles = {
  root: {
    marginBottom: '10px',
    width: '310px'
  },
  fieldGroup: {
    borderColor: '#DCDFE6!important',
    borderRadius: '2px'
  }
}

const dropdownStyles = {
  root: {
    marginBottom: '10px',
    width: '310px'
  },
  title: {
    borderColor: '#DCDFE6!important',
    borderRadius: '2px'
  }
}

const ActionStyles = {
  root: {
    padding: '0px'
  },
  icon: {
    margin: '0px'
  },
  label : {
    color: '#0078D4'
  }
}

const theme = getTheme()
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '1000px',
    maxHeight: '1000px'
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      fontSize: FontSizes.xLarge,
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px'
    }
  ],
  body: {
    padding: '0 24px 36px',
    overflowY: 'auto',
    maxHeight: '600px',
    height: '600px'
  },
  footer: {
    textAlign: 'right',
    margin: '20px 22px 26px 24px',
    marginBottom: '26px'
  }
})

const iconButtonStyles = mergeStyleSets({
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
})

const searchboxStyles = {
  root: {
    width: 'calc(100% - 120px)',
    borderColor: '#ccc',
    ':hover': {
      borderColor: '#ccc',
    }
  }
}

export const QADetail: React.FunctionComponent = () => {
  const { t } = useTranslation()
  let navigate = useNavigate()
  dayjs.extend(utc)

  const [loading, setLoading] = useState(false)
  const [detailId, setDetailID] = useState('')
  const [functionType, setFunctionType] = useState('chat')
  const [questionType, setQuestionType] = useState('customize')
  const [topic, setTopic] = useState('')
  const [questions, setQuestions] = useState<IQuestion[]>([])
  const [message, setMessage] = useState({ topic: '', questions: '' })
  const [relateDict, setRelateDict] = useState<any>({})
  // 编辑答案
  const [answers, setAnswers] = useState<IAnswer[]>([])
  const [activeKey, setActiveKey] = useState('newTab1')
  const [panes, setPanes] = useState<any[]>([])
  // Modal
  const [tableLoading, setTableLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [modalData, setModalData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const newTabIndex = useRef(0)
  const params = useParams()

  
  useEffect(() => {      
    if (params.id) {
      setLoading(true)
      getQuestionAndAnswer(params.id)
        .then((res: any) => {
          setDetailID(res.questionAndAnswer.id)
          setTopic(res.questionAndAnswer.topic)
          setFunctionType(res.questionAndAnswer.functionType)
          setQuestionType(res.questionAndAnswer.questionType)
          setAnswers(res.questionAndAnswer.answers)
          setQuestions(res.questionAndAnswer.questions)
          setRelateDict(res.relatedQnADict || {})
          if (res.questionAndAnswer.answers.length > 0 ) {
            setActiveKey(res.questionAndAnswer.answers[0].id)
          }
        })
        .catch(err => globalMessage(err))
        .finally(() => setLoading(false))
    }
  }, [])

// 重新渲染答案
  useEffect(() => {
    const newPanes = answers.map((answer: IAnswer, index: number) => {
      const num = index + 1
      const tabId = answer.id || 'newTab1'
      return {
        label: `答案 ${num}`,
        children: tabContent(tabId),
        key: tabId,
        closable: answers.length > 1 
      }
    })
    setPanes(newPanes)
  }, [answers, relateDict, activeKey])

  const AddNewQuestion = () => {
    const newQuestions = [
      ...questions,
      {
        inputLanguage: 'en',
        languageTextDict: {
          en: ''
        }
      }
    ]
    setQuestions(newQuestions)
  }

  const deleteQuestion = (index: number) => {
    const newQuestions = _.cloneDeep(questions)
    newQuestions.splice(index, 1)
    setQuestions(newQuestions)
  }

  const changeQuestion = ( index: number, newValue?: string) => {
    const newQuestions = _.cloneDeep(questions)
    newQuestions[index].languageTextDict[newQuestions[index].inputLanguage] = newValue
    setQuestions(newQuestions)
  }

  const onChangeFunctionType = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) : void => {
    setFunctionType(String(option?.key))
  }

  const functionOptions: IDropdownOption[] = [
    {
      key: 'chat',
      text: t('pages.QAManagement.setting.povitTextChat')
    },
    {
      key: 'commodityProblem',
      text: t('pages.QAManagement.setting.commodityProblem')
    },
    {
      key: 'promotions',
      text: t('pages.QAManagement.setting.promotions')
    },
    {
      key: 'purchaseOperation',
      text: t('pages.QAManagement.setting.purchaseOperation')
    },
    {
      key: 'logisticIssue',
      text: t('pages.QAManagement.setting.logisticIssue')
    },
    {
      key: 'afterSalesQuestion',
      text: t('pages.QAManagement.setting.afterSalesQuestion')
    },
    {
      key: 'other',
      text: t('pages.QAManagement.setting.other')
    }
  ]

// 更新某条答案内容
  const onUpdateBlocks = (blocks: IResponseBlock[]) => {
    const newAnswers = _.cloneDeep(answers)
    const answerIndex = newAnswers.findIndex(ans => ans.id === activeKey)
    newAnswers[answerIndex].responseBlocks = blocks
    setAnswers(newAnswers)
  }

// 改变时效类型
  const onChangeDateType = (ev: React.FormEvent<HTMLInputElement>, option: IChoiceGroupOption | undefined) => {
    const newAnswers = _.cloneDeep(answers)
    const answerIndex = newAnswers.findIndex(ans => ans.id === activeKey)
    newAnswers[answerIndex].alwaysActive = option?.key === 'alwaysActive'
    setAnswers(newAnswers)
  }
// 改变时效日期
  const onChangeDate = (dateString: string[]) => {
    const newAnswers = _.cloneDeep(answers)
    const answerIndex = newAnswers.findIndex(ans => ans.id === activeKey)
    newAnswers[answerIndex].effectiveDate = dateString[0] === '' ? dateString[0] : dayjs(dateString[0]).utc().format()
    newAnswers[answerIndex].expirationDate = dateString[1] === '' ? dateString[1] : dayjs(dateString[1]).utc().format()
    setAnswers(newAnswers)
  }
// 渲染关联问题
  const renderRelateDict = (relateId: React.Key) => {
    if (Object.keys(relateDict).length > 0) {
      const question = relateDict[relateId].questions[0]
      const value = question.languageTextDict[question.inputLanguage]
      return value
    } else {
      return ''
    }
  }
// 答案tab的内容
  const tabContent = (tabId: string) => {
    const answerCurrent = answers.find(ans => ans.id === tabId)
    const options: IChoiceGroupOption[] = [
      {
        key: 'alwaysActive',
        text: t('pages.QAManagement.setting.listReplyContentAlways')
      },
      {
        key: 'date',
        text: '',
        onRenderField: (props, render) => {
          const nullDate = ['0001-01-01T00:00:00Z', '']
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {render!(props)}
              <DatePicker.RangePicker
                showTime
                placeholder={[t('pages.QAManagement.detail.placeholderStartTime'), t('pages.QAManagement.detail.placeholderEndTime')]}
                value={
                  nullDate.includes(answerCurrent?.effectiveDate || '') ? undefined : [
                  dayjs(answerCurrent?.effectiveDate), dayjs(answerCurrent?.expirationDate)
                ]}
                onChange={(_date, dateString) => onChangeDate(dateString)}
              />
            </div>
          )
        }
      }
    ]
    const tooltipProps = {
      onRenderContent: () => (
        <Stack>
          <h2 className='detail-tip-h2'>{t('pages.QAManagement.detail.tooltipHeader')}</h2>
          <ul className='detail-tip-ul'>
            <li className='detail-tip-li-right'><p>How long will the transport take?</p></li>
            <li className='detail-tip-li-left'><p>Your answer.</p></li>
            <li className='detail-tip-li-left'>
              <p>Your answer.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx.</p>
              <p className='detail-tip-p-question'>What's your shipping policy？</p>
              <p className='detail-tip-p-question'>Whem can I receive my goods?</p>
            </li>
          </ul>
        </Stack>
      ),
    }

    return (
      <Stack>
        <StackItem styles={stackItemStyles}>   
          <Label styles={labelStyles}>{t('pages.QAManagement.detail.labelAnswerContent')}</Label>
          <AnswersList 
            responseBlocks={answerCurrent?.responseBlocks || []}
            onChange={(blocks) => onUpdateBlocks(blocks)}
          />
        </StackItem>
        <StackItem styles={stackItemStyles}>
          <Label styles={labelStyles}>{t('pages.QAManagement.detail.labelAnswerTimeLimit')}</Label>
          <ChoiceGroup
            selectedKey={answerCurrent?.alwaysActive ? 'alwaysActive' : 'date'}
            options={options}
            onChange={(e: any, option) => onChangeDateType(e, option)}
          />
        </StackItem>
        <StackItem styles={stackItemStyles}>   
          <Label styles={labelStyles}>
            <TooltipHost tooltipProps={tooltipProps}>
              <Icon iconName="Unknown" />
            </TooltipHost>
            <span style={{marginLeft: '5px'}}>{t('pages.QAManagement.detail.labelRelatedQuestion')}</span>
          </Label>
          <div>
            {answerCurrent?.relatedQuestionIds.map((relateId, index) => {
              return (
                <TextField styles={textFieldStyles} value={renderRelateDict(relateId)} readOnly key={index} />
              )
            })}
            <ActionButton
              iconProps={{ iconName: 'AddTo' }}
              styles={ActionStyles}
              onClick={() => showQuestionModal(answerCurrent?.relatedQuestionIds)}
            >
              {t('pages.QAManagement.detail.btnAddRelatedQuestion')}
            </ActionButton>
          </div>
        </StackItem>
      </Stack>
    )
  }

  const showQuestionModal = (relatedQuestionIds: any) => {
    setIsModalOpen(true)
    setTableLoading(true)
    getByMerchantId()
      .then((res: any) => {
        setModalData(res.questionAndAnswerList)
        setSelectedRowKeys(relatedQuestionIds)
      })
      .catch(err => globalMessage(err))
      .finally(() => setTableLoading(false))
  }


  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey)
  }
// 增加答案
  const addTab = () => {
    const newActiveKey = `newTab${newTabIndex.current++}`
    const newAnswers = _.cloneDeep(answers)
    newAnswers.push({
      id: newActiveKey,
      alwaysActive: true,
      effectiveDate: "",
      expirationDate: "",
      responseBlocks: [],
      relatedQuestionIds: []
    })
    setAnswers(newAnswers)
    setActiveKey(newActiveKey)
  }
// 删除答案
  const remove = (targetKey: string) => {
    let newActiveKey = activeKey
    let lastIndex = -1
    panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1
      }
    })
    const newAnswers = _.cloneDeep(answers)
    newAnswers.splice(lastIndex + 1, 1)
    if (newAnswers.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newAnswers[lastIndex].id || 'newTab1'
      } else {
        newActiveKey = newAnswers[0].id || 'newTab1'
      }
    }
    setActiveKey(newActiveKey)
    setAnswers(newAnswers)
  }
// 改变答案数量
  const onEdit = (targetKey: string, action: 'add' | 'remove') => {
    if (action === 'add') {
      addTab()
    } else {
      remove(targetKey)
    }
  }
// 保存问答结果
  const onSave = () => {
    const questionMsg = questions.filter(q => q.languageTextDict[q.inputLanguage] !== '')
    const newMessage = {
      topic: topic === '' ? t('pages.QAManagement.detail.messageTopicError') : '',
      questions: questionMsg.length === 0 ? t('pages.QAManagement.detail.messageQuestionsError') : ''
    }
    setMessage(newMessage)
    const errorMsg = Object.values(newMessage).find(msg => msg !== '')

    if(!errorMsg) {
      let newAnswers = _.cloneDeep(answers)
      newAnswers = newAnswers.map((answer: IAnswer) => {
        const answerId = answer.id || ''
        if (answerId.indexOf('newTab') > -1) {
          delete answer.id
        }
        if (answer.effectiveDate === '') {
          delete answer.effectiveDate
          delete answer.expirationDate
        }
        return answer
      })
      
      if (detailId !== '') {
        const params = {
          id: detailId,
          topic,
          questionType,
          functionType,
          questions: questionMsg,
          answers: newAnswers
        }
        editQuestionAndAnswer(params)
        .then((_res: any) =>{
          globalMessage("success", MessageBarType.success);
          navigate('/DEVA/QAManagement')
        })
        .catch(err => globalMessage(err))
      } else {
        const params = {
          topic,
          questionType,
          functionType,
          questions: questionMsg,
          answers: newAnswers
        }
        createQuestionAndAnswer(params)
        .then((_res: any) =>{
          globalMessage("success", MessageBarType.success);
          navigate('/DEVA/QAManagement')
        })
        .catch(err => globalMessage(err))
      }
    }
  }

  const onBack = () => {
    navigate('/DEVA/QAManagement')
  }

  const hideModal = () => {
    setIsModalOpen(false)
  }

  const saveModal = () => {
    const newAnswers = _.cloneDeep(answers)
    const answerIndex = newAnswers.findIndex(ans => ans.id === activeKey)
    newAnswers[answerIndex].relatedQuestionIds = selectedRowKeys
    const newRelateDict: any = _.cloneDeep(relateDict)
    modalData.forEach((data: any) => {
      if (selectedRowKeys.includes(data.id)) {
        newRelateDict[data.id] = data
      }
    })
    setAnswers(newAnswers)
    setRelateDict(newRelateDict)
    setIsModalOpen(false)
  }

  const onChangeSearch = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) : void => {
    setSearchValue(newValue || '')
  }

  const onSearch = (newValue: string) => {
    setTableLoading(true)
    if (newValue === '') {
      getByMerchantId()
        .then((res: any) => {
          setModalData(res.questionAndAnswerList)
        })
        .catch(err => globalMessage(err))
        .finally(() => setTableLoading(false))
    } else {
      getSearchByQuestionAndTopic(newValue)
        .then((res: any) =>{
          setModalData(res)
        })
        .catch(err => globalMessage(err))
        .finally(() => setTableLoading(false))
    }
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const columns = [
    {
      title: t('pages.QAManagement.setting.listQuestionContentTheme'),
      dataIndex: 'topic',
    },
    {
      title: t('pages.QAManagement.setting.listQuestionContentQuestion'),
      dataIndex: 'questions',
      render: (_text: any, record: { questions: any[] }) => {
        const questionsText = record.questions.map(question => {
          return question.languageTextDict[question.inputLanguage]
        })
        return (
          <div>{questionsText.join(' / ')}</div>
        )
      }
    }
  ]

  return loading ? (
    <Spinner
      label={t('page_loading') || ''}
      size={SpinnerSize.large}
      styles={{ root: { height: '100%' } }}
    />
  ) : (
    <Stack styles={{root: {padding: '0px 38px'}}}>
      <Stack horizontal styles={stackWrapperStyles}>
        <Stack styles={stackContainerStyles}>
          <div>{t('pages.QAManagement.detail.headerQuestion')}</div>
          <Stack styles={stackStyles}>
            <StackItem styles={stackItemStyles}>
              <Label styles={labelStyles}>{t('pages.QAManagement.detail.labelQuestionType')}</Label>
              <div>
                <Dropdown
                  options={functionOptions}
                  selectedKey={functionType}
                  styles={dropdownStyles}
                  onChange={onChangeFunctionType}
                />
              </div>
            </StackItem>
            <StackItem styles={stackItemStyles}>
              <Label styles={labelStyles}>{t('pages.QAManagement.detail.labelTopic')}</Label>
              <div>
                <TextField
                  styles={textFieldStyles}
                  value={topic}
                  onChange={(e, newValue) => setTopic(newValue || '')}
                  errorMessage={message.topic}
                />
              </div>
            </StackItem>
            <StackItem styles={stackItemStyles}>
              <Label styles={labelStyles}>{t('pages.QAManagement.detail.labelQuestions')}</Label>
              <div>
                {questions.map((question, index) => {
                  return (
                    <div style={{display: 'flex'}} key={index}>
                      <TextField
                        styles={textFieldStyles}
                        value={question.languageTextDict[question.inputLanguage]}
                        onChange={(e, newValue) => changeQuestion(index, newValue)}
                        errorMessage={index === 0 ? message.questions : ''}
                      />
                      <IconButton iconProps={{ iconName: 'Delete' }} title="delete" onClick={() => deleteQuestion(index)} />
                    </div>
                  )
                })}
                <ActionButton iconProps={{ iconName: 'AddTo' }} styles={ActionStyles} onClick={AddNewQuestion}>
                  {t('pages.QAManagement.detail.btnAddSimilarQuestion')}
                </ActionButton>
              </div>
            </StackItem>
          </Stack>
        </Stack>
        <Stack styles={stackContainerStyles}>
          <div>{t('pages.QAManagement.detail.headerAnswer')}</div>
          <Stack styles={stackStyles}>
            <Tabs
              type="editable-card"
              onChange={onChange}
              activeKey={activeKey}
              onEdit={(e: any, action) => onEdit(e, action)}
              items={panes}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 40 }}>
        <DefaultButton text={t('pages.QAManagement.detail.btnCancel') || ''} onClick={onBack} />
        <PrimaryButton text={t('pages.QAManagement.detail.btnSave') || ''} onClick={onSave} />
      </Stack>
      <Modal
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <label>{t('pages.QAManagement.detail.btnAddRelatedQuestion')}</label>
          <IconButton
            styles={iconButtonStyles}
            iconProps={{ iconName: 'Cancel' }}
            onClick={hideModal}
          />
        </div>
        <div className={contentStyles.body}>
          <Stack
            horizontal
            styles={{root: {marginBottom: '28px', width: '50%'}}}
          >
            <SearchBox
              placeholder={t('pages.QAManagement.setting.searchBoxPlaceholder') || ''}
              onSearch={newValue => onSearch(newValue)}
              onChange={onChangeSearch}
              styles={searchboxStyles}
            />
            <DefaultButton
              styles={{
                root: {
                  padding: '0px',
                  minWidth: '40px',
                  borderLeft: '0px',
                  borderColor: '#ccc'
                }
              }}
              iconProps={{ iconName: 'Forward' }}
              onClick={() => onSearch(searchValue)}
            />
          </Stack>
          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            rowKey="id"
            columns={columns}
            dataSource={modalData}
            loading={tableLoading}
          />
        </div>
        <div className={contentStyles.footer}>
          <DefaultButton onClick={hideModal}>{t('pages.QAManagement.detail.btnCancel')}</DefaultButton>
          <PrimaryButton
            type="submit"
            onClick={saveModal}
            styles={{ root: { marginLeft: '10px' } }}
          >
            {t('pages.QAManagement.detail.btnSave')}
          </PrimaryButton>
        </div>
      </Modal>
    </Stack>
  )
}
