import React from 'react'
import { Header } from '../header'
import { Navigator } from '../nav'
import { Breadcrumber } from '../breadcrumb'
import { Outlet, useLocation } from 'react-router-dom'
import { ReactFlowProvider } from 'reactflow'

export const Layout: React.FunctionComponent = () => {
  const location = useLocation()
  return (
    <div>
      <Header />
      <div className='body-wrapper'>
        <Navigator />
        <div className="content-wrapper">
          <Breadcrumber {...location} />
          <div className='container-wrapper'>
            <ReactFlowProvider>
            <Outlet />
            </ReactFlowProvider>
          </div>
        </div>
      </div>
    </div>
  )
}
