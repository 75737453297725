class VisemeElement {

    constructor(id, duration) {
        this.id = id;
        this.duration = duration;
    }
};

class VisemeController {

    constructor(visemeMap, morphTarget) {
        //Store viseme element [VisemeElement1, VisemeElement2, ...]
        this.visemeQue = [];
        //Map standard visemeId to avatar visemeId(morphTarget)  
        //visemeMap[standardVisemeId] = avatarVisemeId
        //visemeMap.length = 22
        this.visemeMap = visemeMap;

        /* Filter Parameter */
        //Max viseme duration
        this.maxDuration = 0.2;
        //Min viseme duration
        this.minDuration = 0.066;
        //standard visemeIdleId 0
        this.visemeIdleId = 0;

        /* Render Parameter */
        //To Calibrate time
        this.supposedOffset = 0;
        this.currTotalOffset = 0;

        //Track offset inside a duration
        this.currOffset = 0;
        //Current duration
        this.currDuration = 0;

        //For Close
        this.currVisemeId = -1;
        //For Open
        this.nextVisemeId = -1;
        //Maximum open weight
        this.maxVisemeOpen = 1;

        //Blendshape on avatar
        this.morphTarget = morphTarget;

        this.isTalk = false;

    }

    //Process the raw viseme sequence and add to visemeQue
    //From {[visemeId, offset], ...} to {[visemeId, duration], ...}
    //Add Rules to filter and merge the states  
    async addVisemeQue(rawVisemeSequence) {

        var visemeIdleId = 0;

        var currAudioOffset = 0;

        var visemeDurationList = []
        /*First round of processing*/
        //From offset to duration
        //Map standard visemeId
        //Filter too short states 
        for (let state of rawVisemeSequence.visemeInfoList) {

            var tempVisemeId = state.visemeId;
            var tempAudioOffset = state.audioOffset;

            if (tempAudioOffset - currAudioOffset > this.minDuration) {

                await visemeDurationList.push(new VisemeElement(this.visemeMap[tempVisemeId], tempAudioOffset - currAudioOffset));
                currAudioOffset = tempAudioOffset;
            }
        }
        console.log(visemeDurationList.concat());

        /*Second round of processing*/
        //Replace too large states with Idle states
        //Merge continuous same states
        for (let state of visemeDurationList) {
            if (state.visemeId !== 0) {

                if (this.visemeQue.length > 0) {

                    if (state.duration > this.maxDuration) {
                        await this.visemeQue.push(new VisemeElement(this.visemeMap[visemeIdleId], state.duration))
                        continue;
                    }

                    var preViseme = this.visemeQue[this.visemeQue.length - 1];

                    if (preViseme.id === state.id) {
                        this.visemeQue[this.visemeQue.length - 1].duration += state.duration;
                        continue;
                    }
                }
            }

            await this.visemeQue.push(new VisemeElement(state.id, state.duration));
        }

        await console.log("---- Add to visemeQue ----");
        await console.log(this.visemeQue.concat());
        await console.log(this.morphTarget);

        // var sum = 0
        // await this.visemeQue.forEach(res => {sum += res.duration})
        // console.log(sum)
        //await console.log(visemeQue)
        //return "yes"
    }

    //Call per frame!
    updateViseme(delta) {

        if (this.isTalk) {
            this.currOffset += delta;
            this.currTotalOffset += delta;
        }
        //To Calibrate time 
        if (this.currTotalOffset - this.supposedOffset > delta) {
            this.currOffset += delta;
        }

        //Transfer state
        if (this.currOffset >= this.currDuration && this.isTalk) {
            //Update supposed offset when finished a state
            this.supposedOffset += this.currDuration;
            this.currOffset = 0;

            //Transfer state when visemeQue is not null
            if (this.visemeQue.length > 0) {
                //From idle to talk
                //Update nextViseme
                if (this.currVisemeId === -1 && this.nextVisemeId === -1) {

                    let tempVisemeElement = this.visemeQue.shift();
                    this.nextVisemeId = tempVisemeElement.id;
                    this.currDuration = tempVisemeElement.duration;

                } else {//From talk to talk      

                    this.setVisemeZero(this.currVisemeId);

                    let tempVisemeElement = this.visemeQue.shift();
                    this.currVisemeId = this.nextVisemeId;
                    this.nextVisemeId = tempVisemeElement.id;
                    this.currDuration = tempVisemeElement.duration;
                }
            } else if (this.currVisemeId !== -1) { //Handle the last state

                this.setVisemeZero(this.currVisemeId);

                this.currVisemeId = this.nextVisemeId;
                this.nextVisemeId = -1;
                //this.currDuration = this.tempVisemeElement.duration;
            }
        }
        //UpdateVisemeWeight
        this.updateVisemeWeight();

        if (this.currVisemeId === -1 && this.nextVisemeId === -1 && this.visemeQue.length === 0) {
            this.isTalk = false;
            //console.log(this.supposedOffset)
            //console.log(this.currTotalOffset)
            this.currTotalOffset = 0;
            this.supposedOffset = 0;
        }
    }

    setVisemeZero(visemeId) {
        if (visemeId !== -1) {
            this.morphTarget.morphTargetList.forEach(influence => {
                influence[visemeId] = 0;
            });
        }
    }

    updateVisemeWeight() {
        //Close currViseme
        if (this.currVisemeId !== -1) {
            let weight = this.getCloseWeight(this.currOffset, this.nextVisemeId, this.currDuration, this.maxVisemeOpen);
            this.morphTarget.morphTargetList.forEach(influence => {
                influence[this.currVisemeId] = weight;
            });
        }
        //Open nextViseme
        if (this.nextVisemeId !== -1) {
            let weight = this.getOpenWeight(this.currOffset, this.currDuration, this.maxVisemeOpen);
            this.morphTarget.morphTargetList.forEach(influence => {
                influence[this.nextVisemeId] = weight;
            });
        }
    }

    getOpenWeight(currOffset, visemeDuration, maxVisemeOpen) {

        return Math.min(maxVisemeOpen * (currOffset / visemeDuration), maxVisemeOpen);
    }

    getCloseWeight(currOffset, nextVisemeId, visemeDuration, maxVisemeOpen) {
        // if the next Viseme is Idle, the curr Viseme should close quickly(in 0.2s)
        if (nextVisemeId === -1) {
            return Math.max(maxVisemeOpen * (1 - currOffset / 0.2), 0);
        }

        return Math.max(maxVisemeOpen * (1 - currOffset / visemeDuration), 0);
    }
    //[Public Method]
    prepareTalk(rawVisemeSequence) {
        this.addVisemeQue(rawVisemeSequence);

    }

    //[Public Method]
    idle() {
        this.visemeQue = [];
        this.visemeQue.push(new VisemeElement(-1, 0.2));

    }
    //[Public Method]
    talk() {
        this.isTalk = true;
    }
};

export { VisemeController, VisemeElement };