import React from 'react'
import {
  Text,
  ITextProps,
  TextField,
  Stack,
  IStackItemStyles,
} from '@fluentui/react'
interface EditableTextProps {
  item: any
  setText:Function
}

export const EditableText: React.FunctionComponent<EditableTextProps> = (
  props,
) => {
  const { item,setText } = props
  const [innerText, setInnerText] = React.useState(item.content)
  const [editing, setEditing] = React.useState(false)

  React.useEffect(() => {
    setInnerText(item.content)
  }, [item.content])

  const CardTextStyle: IStackItemStyles = {
    root: {
      padding: 10,
      fontSize: 12,
    },
  }
  const onEnter = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Enter') {
      setEditing(false)
    }
  }
  let showContent = !editing ? (
    <Stack.Item styles={CardTextStyle} onClick={() => setEditing(true)}>
      <Text variant={'smallPlus' as ITextProps['variant']} block>
        {innerText}
      </Text>
    </Stack.Item>
  ) : (
    <TextField
      multiline
      autoAdjustHeight
      autoFocus
      defaultValue={innerText}
      onBlur={() => setEditing(false)}
      onChange={(ev, newValue) => {
        setInnerText(newValue as string)
        setText(newValue as string,item.itemId)
      }}
      onKeyDown={onEnter}
    />
  )
  return showContent
}
