import request from "SRC/utils/request";

export function fetchScenarioData(merchantId: string) {
  return request({
    url: "/flowcontrol/api/WFmgmt/workflows/" + merchantId,
    method: "get",
  });
}

export function changeActivateStatus(
  merchantId: string,
  scenarioName: string,
  status: boolean
) {
  return request({
    url: "/flowcontrol/api/WFmgmt/workflow/" + merchantId + "/" + scenarioName,
    method: "put",
    data: {
      sessionId: merchantId + "_" + scenarioName + "_" + status,
      isActive: status,
    },
  });
}

export function fetchScenarioDetail(merchantId: string, scenarioName: string) {
  return request({
    url: "/flowcontrol/api/WFmgmt/workflow/" + merchantId + "/" + scenarioName,
    method: "get",
  });
}

export function deleteScenarioByName(merchantID: string, scenarioName: string) {
  return request({
    url: "/flowcontrol/api/WFmgmt/workflow/" + merchantID + "/" + scenarioName,
    method: "delete",
  });
}

export function createNewScenario(
  merchantId: string,
  scenarioName: string,
  tag: string,
  languageCode: string,
  predefinedScenarioType: string,
  periodType: string,
  triggerEventType: string,
  items: any,) {
  return request({
    url: "/flowcontrol/api/WFmgmt/workflow/" + merchantId,
    method: "post",
    data: 
    {
      sessionId: merchantId + "_" + scenarioName,
      scenarioName: scenarioName,
      isActive: true,
      tag: tag,
      languageCode: languageCode,
      predefinedScenarioType: predefinedScenarioType,
      periodType: periodType,
      triggerEventType: triggerEventType,
      items: items,
    }
  });
}

export function modifyScenario(
  merchantId: string,
  scenarioName: string,
  newScenarioName: string,
  tag: string,
  languageCode: string,
  predefinedScenarioType: string,
  periodType: string,
  triggerEventType: string,
  items: any,
  cookies: any = null
) {
  
}

export function uploadImage(file: any) {
  const formData = new FormData();
  formData.append("files", file);
  return request({
    url: "/mediastorage/api/MediaFile",
    method: "post",
    data: formData,
  });
}

export function mediaURLPrefix() {
  return request({
    url: "/mediastorage/api/MediaFile/URLPrefix",
    method: "get",
  });
}
