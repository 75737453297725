import request from 'SRC/utils/request'

const smartkgApi = {
    DataStoreMgmt: '/smartkg/api/DataStoreMgmt',
    AllData: '/smartkg/api/DataStoreMgmt/Scenario',
    uploadScenario: '/smartkg/api/DataStoreMgmt/preprocess/upload',
    downloadScenario: '/smartkg/api/DataStoreMgmt/scenario/download',
    GraphScenarios: "/smartkg/api/Graph/scenarios",
    GraphSearchProperty: "/smartkg/api/Graph/Search/property",
    GraphSearch: "/smartkg/api/Graph/Search",
    GraphRelations: "/smartkg/api/Graph/relations",
    ConfigEntitycolor: "/smartkg/api/Config/entitycolor",
    GraphVisulize: "/smartkg/api/Graph/visulize",
    Bot: "/smartkg/api/bot",
}

// 获取数据库
export function getDataStoreMgmt() {
    return request({
        url: smartkgApi.DataStoreMgmt,
        method: 'get',
    })
}

export function createDataStoreMgmt(datastoreName: string) {
    return request({
        url: smartkgApi.DataStoreMgmt,
        method: 'post',
        data:{
            datastoreName: datastoreName
        }
    })
}

export function deleteDataStoreMgmt(datastoreName: string) {
    return request({
        url: `${smartkgApi.DataStoreMgmt}`,
        method: 'delete',
        data:{
            datastoreName: datastoreName
        }
    })
}

export function getAllGraphScenarios() {
    return request({
        url: smartkgApi.AllData,
        method: 'get'
    })
}

export function searchGraphScenarios(datastoreName: string) {
    return request({
        url: `${smartkgApi.AllData}?query=${datastoreName}`,
        method: 'get'
    })
}

export function deleteGraphScenarios(datastoreName: string, scenarioName: string) {
    return request({
        url: `${smartkgApi.AllData}`,
        method: 'delete',
        data: {
            datastoreName: datastoreName,
            scenarioName: scenarioName
        }
    })
}

export function uploadScenario(data:any) {
    return request({
        url: `${smartkgApi.uploadScenario}`,
        method: 'post',
        data: data
    })
}

export function downloadScenario(datastoreName: string, scenarioName: string) {
    return request({
        url: `${smartkgApi.downloadScenario}`,
        method: 'post',
        data:{
            datastoreName: datastoreName,
            scenarioName: scenarioName
        },
        responseType: 'blob'
    })
}

export function getGraphScenarios(data) {
    return request({
        url: `${smartkgApi.GraphScenarios}?datastoreName=${data}`,
        method: 'get'
    })
}

export function getGraphSearchProperty(name: string, value, datastoreName: string) {
    return request({
        url: `${smartkgApi.GraphSearchProperty}?name=${encodeURI(name)}&value=${encodeURI(value)}&datastoreName=${datastoreName}`,
        method: 'get'
    })
}

export function getGraphSearch(datastoreName: string, scenarioName: string, keyword) {
    return request({
        url: `${smartkgApi.GraphSearch}?scenarioName=${encodeURI(scenarioName)}&keyword=${encodeURI(keyword)}&datastoreName=${datastoreName}`,
        method: 'get'
    })
}


export function getGraphRelations(id, datastoreName: string) {
    return request({
        url: `${smartkgApi.GraphRelations}/${id}?datastoreName=${datastoreName}`,
        method: 'get',
    })
}

export function getConfigEntitycolor(datastoreName: string, scenarioName: string) {
    return request({
        url: `${smartkgApi.ConfigEntitycolor}?datastoreName=${encodeURI(datastoreName)}&scenarioName=${encodeURI(scenarioName)}`,
        method: 'get',
    })
}

export function getGraphVisulize(datastoreName: string, scenarioName: string) {
    return request({
        url: `${smartkgApi.GraphVisulize}?datastoreName=${encodeURI(datastoreName)}&scenarioName=${encodeURI(scenarioName)}`,
        method: 'get',
    })
}

export function postSmartkgBot(parameter: any) {
    return request({
        url: smartkgApi.Bot,
        method: 'post',
        data: parameter,
    })
}

export function postConfigEntitycolor(datastoreName: string, scenarioName: string, parameter: any) {
    return request({
        url: `${smartkgApi.ConfigEntitycolor}?datastoreName=${encodeURI(datastoreName)}&scenarioName=${encodeURI(scenarioName)}`,
        method: 'post',
        data: parameter,
    })
}
