import React, { memo } from 'react'
import { Handle, Position, NodeToolbar } from 'reactflow'
import { useTranslation } from 'react-i18next'
import { Icon } from '@fluentui/react'
import { nodeTable } from 'SRC/pages/scenarioManagement/configurate/nodeTable'
interface vsNodeProps {
  data: any
}
const VSNode: React.FunctionComponent<vsNodeProps> = ({ data }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'nodes' })
  const tipText = <span>{t(nodeTable[data.label].tipText)}</span>
  const innerIcon = <Icon iconName={nodeTable[data.label].iconName} />

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        onConnect={(params) => console.log('handle onConnect', params)}
        style={{ background: '#fff' }}
        isConnectable={false}
      />
      <Handle
        type="source"
        position={Position.Right}
        onConnect={(params) => console.log('handle onConnect', params)}
        style={{ background: '#fff' }}
        isConnectable={false}
      />
      {innerIcon}
      <NodeToolbar isVisible={true} position={Position.Bottom}>
        {tipText}
      </NodeToolbar>
    </>
  )
}

export default memo(VSNode)
