import React, { useState, useEffect } from "react";
import { Form, Input, Button, Divider } from "antd";
import { MobileLoginIcon, UserLoginIcon } from "SRC/assets/icons/icons";
import { ATPLogin, VerificationCode } from "SRC/api/login";
import { setExpire, setexpireTime } from "SRC/utils/request";
import { setUserInfo, setApps, setNickName } from "SRC/utils/slice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { globalMessage } from "SRC/components/messagebar";
import "./index.css";

const phoneValidateRule = /^1[345789]\d{9}$/;

export const Login: React.FunctionComponent = () => {
  const resetSeconds = 60;
  const [form] = Form.useForm();
  const [displayState, setDisplayState] = useState("userlogin");
  const [countDown, setCountDown] = useState(resetSeconds);
  const [countDowning, setCountDowning] = useState(false);
  const [phoneMessage, setPhoneMessage] = useState<any>({
    help: "",
    status: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onVerClick = () => {
    const phoneNumber = form.getFieldValue("phone");
    console.log(form.getFieldValue("verification"), phoneNumber);
    const newMessage = {
      help: phoneValidateRule.test(phoneNumber) ? "" : "请输入正确的手机号！",
      status: phoneValidateRule.test(phoneNumber) ? "" : "error",
    };
    setPhoneMessage(newMessage);
    if (newMessage.status === "") {
      setCountDowning(true);
      const verificationForm = {
        phone_number: form.getFieldValue("phone"),
      };
      VerificationCode(verificationForm)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          globalMessage(err.response.data.message);
        });
    }
  };

  const onUserLoginClick = () => {
    const loginForm = {
      phone_number: form.getFieldValue("username"),
      password: form.getFieldValue("password"),
      type: "password",
    };
    ATPLogin(loginForm)
      .then((res: any) => {
        const expTime = setexpireTime(
          new Date(new Date().getTime() + 4 * 60 * 60 * 1000)
        );
        setExpire("isTokenExpired", "true", expTime);
        navigate("/ScenarioManagement");
        dispatch(setUserInfo(form.getFieldValue("username")));
        dispatch(setApps(res.atptp_adv_perm));
        dispatch(setNickName(res.nick_name));
      })
      .catch((err) => {
        console.log(err);
        globalMessage(err.response.data.message);
      });
  };

  const onMobileLoginClick = () => {
    const loginForm = {
      phone_number: form.getFieldValue("phone"),
      code: form.getFieldValue("verification"),
      type: "code",
    };
    ATPLogin(loginForm)
      .then((res: any) => {
        const expTime = setexpireTime(
          new Date(new Date().getTime() + 4 * 60 * 60 * 1000)
        );
        setExpire("isTokenExpired", "true", expTime);
        navigate("/ScenarioManagement");
        dispatch(setUserInfo(form.getFieldValue("phone")));
        dispatch(setApps(res.atptp_adv_perm));
        dispatch(setNickName(res.nick_name));
      })
      .catch((err) => {
        console.log(err);
        globalMessage(err.response.data.message);
      });
  };

  const onValidateMobile = ({ values, errorFields, outOfDate }) => {
    console.log({ values, errorFields, outOfDate });
    const phoneError = errorFields.find((field) =>
      field.name.includes("phone")
    );
    if (phoneError) {
      setPhoneMessage({ status: "error", help: phoneError.errors[0] });
      return false;
    } else {
      setPhoneMessage({ status: "", help: "" });
      return true;
    }
  };

  useEffect(() => {
    if (countDown > 0 && countDowning) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else if (countDown === 0 && countDowning) {
      setCountDowning(false);
    } else if (!countDowning) {
      setCountDown(resetSeconds);
    }
  }, [countDown, countDowning]);

  const userLoginContent = (
    <>
      <Form form={form} layout="vertical" onFinish={onUserLoginClick}>
        <Form.Item
          label="手机号"
          name="username"
          rules={[
            { required: true, message: "请输入手机号！" },
            {
              pattern: phoneValidateRule,
              message: "请输入正确的手机号！",
            },
          ]}
        >
          <Input style={{ width: 500, height: 60, borderRadius: 10 }} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "请输入密码！" }]}
          label={
            <div
              style={{
                width: 500,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>密码</span>
              <Button
                type="link"
                className="forgetPassword"
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                  paddingTop: 0,
                  marginTop: 0,
                  height: 22,
                }}
                onClick={() => {
                  setDisplayState("mobilelogin");
                }}
              >
                忘记密码?
              </Button>
            </div>
          }
          name="password"
        >
          <Input.Password
            style={{ width: 500, height: 60, borderRadius: 10 }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: 500,
              height: 60,
              borderRadius: 30,
              background: "#0D0C21",
            }}
          >
            登录
          </Button>
        </Form.Item>
      </Form>
      <Divider orientationMargin={14}>或者使用其他登录</Divider>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          flexWrap: "wrap",
          cursor: "pointer",
        }}
        onClick={() => {
          setDisplayState("mobilelogin");
        }}
      >
        <MobileLoginIcon
          style={{ marginBottom: 12, display: "inline-block" }}
        />
        <span>验证码登录</span>
      </div>
    </>
  );

  const mobileLoginContent = (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onMobileLoginClick}
        onFinishFailed={onValidateMobile}
      >
        <Form.Item
          label="手机号"
          name="phone"
          rules={[
            { required: true, message: "请输入手机号！" },
            {
              pattern: phoneValidateRule,
              message: "请输入正确的手机号！",
            },
          ]}
          validateStatus={phoneMessage.status}
          help={phoneMessage.help}
        >
          <Input
            style={{ width: 500, height: 60, borderRadius: 10 }}
            onChange={(e) => {
              console.log(
                phoneValidateRule.test(e.target.value),
                e.target.value
              );
              if (phoneValidateRule.test(e.target.value)) {
                setPhoneMessage({ status: "", help: "" });
              } else {
                setPhoneMessage({
                  status: "error",
                  help: "请输入正确的手机号！",
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label={
            <div
              style={{
                width: 500,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>验证码</span>
            </div>
          }
          name="verification"
          rules={[{ required: true, message: "请输入验证码！" }]}
        >
          <div style={{ height: 60 }}>
            <Input style={{ width: 350, height: 60, borderRadius: 10 }} />
            <Button
              onClick={onVerClick}
              disabled={countDowning}
              style={{
                width: 135,
                height: 60,
                borderRadius: 10,
                marginLeft: 15,
              }}
            >
              {countDowning ? countDown.toString() + "秒后重试" : "获取验证码"}
            </Button>
          </div>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: 500,
              height: 60,
              borderRadius: 30,
              background: "#0D0C21",
            }}
          >
            登录
          </Button>
        </Form.Item>
      </Form>
      <Divider orientationMargin={14}>或者使用其他登录</Divider>
      <div
        style={{
          width: 100,
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          flexWrap: "wrap",
          cursor: "pointer",
          userSelect: "none",
        }}
        onClick={() => setDisplayState("userlogin")}
      >
        <UserLoginIcon style={{ marginBottom: 12, display: "inline-block" }} />
        <span style={{ userSelect: "none" }}>密号登录</span>
      </div>
    </>
  );

  return (
    <div className="loginWrapper">
      <div className="loginLeftPane">
        <img
          height={"100%"}
          src={require("SRC/assets/imgs/login_banner.png")}
          alt="login banner"
        />
      </div>
      <div className="loginRightPane">
      {/* <img
          height={"auto"}
          width={200}
          
          src={require("SRC/assets/imgs/gglogo.png")}
          alt="login banner"
        /> */}
        <h1 className="loginTitle">实践平台</h1>
        <div
          className="loginContainer"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 500,
          }}
        >
          {displayState === "userlogin" ? userLoginContent : mobileLoginContent}
        </div>
      </div>
    </div>
  );
};
