interface INodeTable {
    [key:string]:{
        iconName:string,
        tipText:string,
        style:string,
    }
}

export const nodeTable:INodeTable={
    onFirstVisit: {
        iconName: 'Home',
        tipText: 'On First Visit',
        style: 'trigger',
    },
    onEvent:{
        iconName: 'Event',
        tipText: 'On Event',
        style: 'trigger',
    },
    Text:{
        iconName: 'Comment',
        tipText: 'Send Message',
        style: 'action',
    },
    Card:{
        iconName: 'Ticket',
        tipText: 'Send Card',
        style: 'action',
    }

}