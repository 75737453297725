import request from 'SRC/utils/request'

const FAQRestApi = {
  QuestionAndAnswers: '/merchantmanagement/api/QuestionAndAnswers',
  ByMerchantId: '/merchantmanagement/api/QuestionAndAnswers/ByMerchantId',
  SearchByQuestion: '/merchantmanagement/api/QuestionAndAnswers/SearchByQuestion',
  SearchByAnswer: '/merchantmanagement/api/QuestionAndAnswers/SearchByAnswer',
  SearchByQuestionAndTopic: '/merchantmanagement/api/QuestionAndAnswers/SearchByQuestionAndTopic',
  UploadImg: '/mediastorage/api/MediaFile',
  UploadImgUrlPrefix: '/mediastorage/api/MediaFile/UrlPrefix',
}

export function getUploadImgUrlPrefix () {
  return request({
    url: FAQRestApi.UploadImgUrlPrefix,
    method: 'get'
  })
}

export function uploadImage (parameter: any) {
  return request({
    url: FAQRestApi.UploadImg,
    method: 'post',
    data: parameter
  })
}

export function getQuestionAndAnswer(id = '') {
  return request({
    url: `${FAQRestApi.QuestionAndAnswers}?id=${id}`,
    method: 'get'
  })
}

export function deleteQuestionAndAnswer(id = '') {
  return request({
    url: `${FAQRestApi.QuestionAndAnswers}/${id}`,
    method: 'delete'
  })
}

export function editQuestionAndAnswer (parameter: any) {
  return request({
    url: FAQRestApi.QuestionAndAnswers,
    method: 'put',
    data: parameter
  })
}

export function createQuestionAndAnswer (parameter: any) {
  return request({
    url: FAQRestApi.QuestionAndAnswers,
    method: 'post',
    data: parameter
  })
}

export function getByMerchantId(parameter = '') {
  return request({
    url: `${FAQRestApi.ByMerchantId}${parameter}`,
    method: 'get'
  })
}

export function getSearchByQuestion(query = '') {
  return request({
    url: `${FAQRestApi.SearchByQuestion}?query=${query}`,
    method: 'get'
  })
}

export function getSearchByAnswer(query = '') {
  return request({
    url: `${FAQRestApi.SearchByAnswer}?query=${query}`,
    method: 'get'
  })
}

export function getSearchByQuestionAndTopic(query = '') {
  return request({
    url: `${FAQRestApi.SearchByQuestionAndTopic}?query=${query}`,
    method: 'get'
  })
}