import React from 'react'
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb'
import {
  Stack,
  mergeStyleSets,
} from '@fluentui/react'
import {
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Bcstyles = mergeStyleSets({
  root: {
    height: 44,
    marginTop: 0,
    marginLeft: 32,
    display: 'flex',
    background: '#FFFFFF',
  },
  itemLink: {
    color: '#909399 !important',
    fontWeight: '400 !important',
    fontSize: 14,
    lineHeight: '44px !important',
  },
})

export const Breadcrumber: React.FunctionComponent = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  let items: IBreadcrumbItem[] = []

  const matchRoute: any = (path: string) => {
    let route = path.slice(1).split('/')
    let items: IBreadcrumbItem[] = []
    let navRoute = ''
    route.forEach((item) => {
      if (!Object.values(params).includes(item)) {
        navRoute += '/' + item
        items.push({
          text: t('navigator.' + item),
          key: navRoute,
          onClick: (ev, i) => {
            navigate(i?.key || 'error')
          },
        })
      }
    })
    return items
  }
  items = matchRoute(location.pathname)

  return (
    <React.Fragment>
      <Stack>
        <Breadcrumb
          items={items}
          maxDisplayedItems={5}
          ariaLabel="Breadcrumb Navigation"
          overflowAriaLabel="More links"
          focusZoneProps={{ handleTabKey: 0 }}
          styles={Bcstyles}
        />
        <div
          style={{ height: '1px', background: '#F3F2F1' }}
        ></div>
      </Stack>
    </React.Fragment>
  )
}
