import * as dat from 'dat.gui'
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { VisemeController } from './lib/visemeController.js';
import { AnimationController } from './lib/AnimationController.js';

let config, gui, model, guiData

function ThreejsAvatar(solutionConfig, onReadyHandler, onPercent, datGui, onGetActions, onGetMorphTarget,
    decoderPath = 'https://wictest.blob.core.chinacloudapi.cn/vafrontendtest/magicWomanWithSpeech/magics-woman-threejs/lib/') {
        
    console.log(solutionConfig);
    config = solutionConfig
    // 保存需求修改的相关数据的对象
    guiData = config.gui || {
        meshX: 0,
        meshY: 0,
        meshZ: 0,
        scaleX: 1,
        scaleY: 1,
        scaleZ: 1
    }

    var scene,
        //-- Scene --
        renderer,
        camera,
        //-- Render -- 
        clock = new THREE.Clock(),
        delta = 0,

        interval = config.interval,
        modelUrl = config.modelUrl,
        visemeMap = config.visemeMap,
        actionIndices = config.actionIndices,
        blendshapeMesh = config.blendshapeMesh,
        //-- Avatar --
        // model,
        morphTarget,
        visemeController, // visemeController
        animationController,// animationController

        currReqFrame,
        //-- APP --
        isAppReady = false;
        console.log(isAppReady);

    //loaderAnim = document.getElementById('js-loader');
    Main();

    function Main() {
        console.log("-- On Init --");
        //Init Scene: Scene, Camera, Renderer, Light
        initScene();
        //Init Gui: Change style
        if (datGui) {
            initGui();
        }
        //Init Model: Model, MorphTarget, Anims
        //Trigger Update once loaded
        initModel(modelUrl);
    }

    function initScene() {
        console.log("--- On Scene Init ---")
        //Init the scene
        scene = new THREE.Scene();

        //Init the renderer
        const canvas = document.querySelector('#c');
        renderer = new THREE.WebGLRenderer({ canvas, antialias: true, alpha: true });

        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        renderer.toneMapping = THREE.LinearToneMapping;
        renderer.setPixelRatio(window.devicePixelRatio);
        //document.body.appendChild(renderer.domElement);

        //Init the camera
        camera = new THREE.PerspectiveCamera(
            50,
            window.innerWidth / window.innerHeight,
            0.1,
            1000);

        camera.position.set(0, 5, 35);
        camera.lookAt(0, 0, -10);

        //Init lights
        //Add hemisphere light
        let hemiLight = new THREE.HemisphereLight(0xffffff, 0x080820, 1);
        hemiLight.position.set(0, 50, 0);
        scene.add(hemiLight);

        //Add directional light0 to scene
        let dirLight = new THREE.DirectionalLight(0xffffff, 0.2);
        dirLight.shadow.bias = -0.0001;
        dirLight.position.set(1, 4.5, 8);
        dirLight.castShadow = true;
        dirLight.shadow.mapSize = new THREE.Vector2(1024 * 2, 1024 * 2);
        scene.add(dirLight);

        //Add directional light1 to scene
        let dirLight1 = new THREE.DirectionalLight(0xddefff, 0.3);
        dirLight1.position.set(-10, -20, 20);
        scene.add(dirLight1);

        let spotLight = new THREE.SpotLight(0xfff5e7, 0.2);
        spotLight.position.set(25, -50, 50);
        spotLight.castShadow = false;
        //spotlight.shadow.bias = -0.0001;
        scene.add(spotLight);
        window.scene = scene;
    }

    function initGui() {
        if (gui) {
            gui.destroy()
        }
        gui = new dat.GUI({autoPlace: false, width: 100})
        // 设置GUI位置
        gui.domElement.style = 'position: absolute; top:0px; right: 0px;'
        const guiContainer = document.getElementById('currentAvatar')
        guiContainer.appendChild(gui.domElement)
        // 创建模型位置子菜单
        const modelPositionFolder = gui.addFolder('模型位置');
        modelPositionFolder.add(guiData, 'meshX').name('X').onChange(value => {
            model.position.x = value
        })
        modelPositionFolder.add(guiData, 'meshY').name('Y').onChange(value => {
            model.position.y = value
        })
        modelPositionFolder.add(guiData, 'meshZ').name('Z').onChange(value => {
            model.position.z = value
        })
        // 创建模型缩放子菜单
        const modelScalenFolder = gui.addFolder('模型缩放');
        modelScalenFolder.add(guiData, 'scaleX').name('X').onChange(value => {
            model.scale.x = value
        })
        modelScalenFolder.add(guiData, 'scaleY').name('Y').onChange(value => {
            model.scale.y = value
        })
        modelScalenFolder.add(guiData, 'scaleZ').name('Z').onChange(value => {
            model.scale.z = value
        })
    }

    function initModel(modelUrl) {
        console.log("--- On Model Init ---");

        var loader = new GLTFLoader();
        var dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath(decoderPath);
        loader.setDRACOLoader(dracoLoader);
        loader.load(
            modelUrl,
            function (gltf) {
                model = gltf.scene;
                let fileAnimations = gltf.animations;
                if (onGetActions) {
                    onGetActions(fileAnimations)
                }
                model.traverse(o => {
                    if (o.isMesh) {
                        o.castShadow = true;
                        o.receiveShadow = true;
                        var prevMaterial = o.material;
                        o.material = new THREE.MeshLambertMaterial();
                        if (o.material.map) {
                            o.material.map.anisotropy = 16;
                        }
                        THREE.MeshBasicMaterial.prototype.copy.call(o.material, prevMaterial);
                    }
                });

                //Load Morph Target
                morphTarget = loadMorphTarget(model, blendshapeMesh);
                console.log("---- Load Morph Target ----");
                console.log(morphTarget);
                if (onGetMorphTarget) {
                    onGetMorphTarget(morphTarget)
                }
                visemeController = new VisemeController(visemeMap, morphTarget);

                //Load Animations
                console.log("---- Load Animations ----");
                console.log(fileAnimations);
                animationController = new AnimationController(
                    model,
                    fileAnimations,
                    actionIndices,
                    interval);

                //Set model position
                model.scale.set(guiData.scaleX, guiData.scaleY, guiData.scaleZ);
                model.position.set(guiData.meshX, guiData.meshY, guiData.meshZ);
                scene.add(model);
                //Triger rendering
                onAppReady();
                renderer && update();
                console.log("-- Begin Rendering --");
                window.render = renderer
            },
            onProgress,
            function (error) {
                console.error(error);
            });
    }

    function onAppReady() {
        isAppReady = true;
        console.log('--- Load Model Ready ---');
        if (onReadyHandler) {
            onReadyHandler();
        }
    }

    // 加载过程回调函数-可以获得加载进度
    function onProgress(xhr) {
        if (onPercent) {
            onPercent(xhr.loaded, xhr.total);
        }
    }

    function update() {
        delta += clock.getDelta();

        if (delta > interval) {
            visemeController.updateViseme(delta);

            if (animationController.mixer) {
                animationController.updateAnimation(delta);
            }
      
            if (resizeRendererToDisplaySize(renderer)) {
                const canvas = renderer.domElement;
                camera.aspect = canvas.clientWidth / canvas.clientHeight;
                camera.updateProjectionMatrix();
            }
            delta = 0;
            renderer.render(scene, camera);
            //console.log("before:", renderer.info.programs.length);
        }
        window.c = currReqFrame;
        currReqFrame = requestAnimationFrame(update);
    }

    function resizeRendererToDisplaySize(renderer) {
        const canvas = renderer.domElement;
        let width = window.innerWidth;
        let height = window.innerHeight;
        let canvasPixelWidth = canvas.width / window.devicePixelRatio;
        let canvasPixelHeight = canvas.height / window.devicePixelRatio;

        const needResize =
            canvasPixelWidth !== width || canvasPixelHeight !== height;

        if (needResize) {
            renderer.setSize(width, height, false);
        }

        return needResize;
    }

    this.prepareTalk = (visemeRes) => {
        console.log("--- Process Raw Viseme Sequence ---");
        return visemeController.prepareTalk(visemeRes);

    }
    this.stopTalk = () => {
        console.log("--- Stop Talking ---");
        visemeController.idle();
        animationController.idle();
    }

    this.beginTalk = () => {
        console.log("--- Begin Talking ---");
        visemeController.talk();
        animationController.talk();
    }

    this.animAct = (index) => {
        console.log("--- Act ---");
        console.log(index);
        animationController.act(index);
    }

    this.disposeThis = () => {
        // dispose texture; material; geometery
        // stop the rendering frame
        // clean scene & other obj
        scene.traverse(o => {
            // clean group
            if (o.type === 'Group') {
                clearGroup(o)
                scene.remove(o)
            }
        })
        clearScene();
    }

    function clearScene() {
        // stop rendering frame
        cancelAnimationFrame(currReqFrame);
        scene.traverse((child) => {
            if (child.material) {
                child.material.dispose();
            }
            if (child.geometry) {
                child.geometry.dispose();
            }
            child = null;
        });
        //renderer.forceContextLoss();
        renderer.dispose();
        scene.clear();
        //scene = null;
        camera = null;
        morphTarget = null;
        visemeController = null;
        animationController = null;
        renderer.domElement = null;
        renderer = null;
        console.log('clearScene');
    }

    function clearGroup(group) {
        const clearCache = (item) => {
            if (item.material) {
                item.material.dispose();
            }
            if (item.geometry) {
                item.geometry.dispose();
            }
        }

        const removeObj = (obj) => {
            let arr = obj.children.filter((x) => !!x);
            arr.forEach((item) => {
                if (item.children.length) {
                    removeObj(item);
                } else {
                    clearCache(item);
                    item.clear();
                }
            });
            obj.clear();
            arr = null;
        }
        
        removeObj(group);
    }
    //window.s = stopRender
    //Bind function to window
    // window.prepareTalk = this.prepareTalk;
    // window.stopTalk = this.stopTalk;
    // window.beginTalk = this.beginTalk;
    // window.act = this.animAct;

    // window.prepareTalk = prepareTalk;
    // window.stopTalk = stopTalk;
    // window.beginTalk = beginTalk;
    // window.act = animAct;

    // window.addEventListener('message', function (event) {
    //     console.log('received response:  ', event.data);
    //     const { type, data } = event.data;
    //     if (window[type]) {
    //         window[type](data);
    //     }
    // }, false)
}

//load blendshape to morph target 
function loadMorphTarget(model, blendshapeMeshName) {
    let head = model.getObjectByName(blendshapeMeshName);
    let morphTarget = { 'morphTargetDictionary': undefined, 'morphTargetList': [] };
    if (head) {
        head.traverse(o => {
            if (o.type === 'SkinnedMesh' && o.morphTargetDictionary !== undefined) {

                if (morphTarget.morphTargetDictionary === undefined) {
                    morphTarget.morphTargetDictionary = o.morphTargetDictionary;
                }

                morphTarget['morphTargetList'].push(o.morphTargetInfluences);
            }
        });
        return morphTarget;
    } else {
        return false
    }
}

// 获取新的MorphTarget
function updateMorphTarget (blendshapeMesh) {
    const morphTarget = loadMorphTarget(model, blendshapeMesh)
    return morphTarget
}

// 返回thressjsConfig
function onGetConfig () {
    return {
        ...config,
        gui: guiData
    }
}

export { ThreejsAvatar, updateMorphTarget, onGetConfig }