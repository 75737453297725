import React from "react";
import "./index.less";


export const Home: React.FunctionComponent = () => {

  return (
    <div className="home-wrapper">
      <div className="home-content">
        <h1>欢迎使用实践平台</h1>
        <h3>Welcome to the Training Platform!</h3>
      </div>
      <div className="background-img">
        <img src={require("SRC/assets/imgs/home_background.png")} />
      </div>
    </div>
  );
};
