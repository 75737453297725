import { nodestyles } from './nodestyles'
import { MarkerType } from 'reactflow'
export const scenarioGraphData = {
  username: 'vs@microsoft.com',
  scenarioGraphs: [
    {
      predefinedScenarioType: 'Promotion',
      nodes: [
        {
          id: '1',
          type: 'VSNode',
          position: { x: 500, y: 350 },
          data: { label: 'onEvent' },
          style: nodestyles.trigger,
        },
        {
          id: 'CardNode',
          type: 'VSNode',
          position: { x: 650, y: 350 },
          data: { label: 'Card' },
          style: nodestyles.action,
        },
        {
          id: 'AcceptNode',
          type: 'VSNode',
          position: { x: 800, y: 350 },
          data: { label: 'Text' },
          style: nodestyles.action,
        },
        {
          id: 'RefuseNode',
          type: 'VSNode',
          position: { x: 800, y: 500 },
          data: { label: 'Text' },
          style: nodestyles.action,
        },
      ],
      edges: [
        {
          id: 'e1-2',
          source: '1',
          target: 'CardNode',
          markerEnd: { type: MarkerType.ArrowClosed, width: 20 ,height:20},
        },
        {
          id: 'Accept',
          source: 'CardNode',
          target: 'AcceptNode',
          markerEnd: { type: MarkerType.ArrowClosed, width: 20 ,height:20},
        },
        {
          id: 'Refuse',
          source: 'CardNode',
          target: 'RefuseNode',
          markerEnd: { type: MarkerType.ArrowClosed, width: 20 ,height:20},
        },
      ],
    },
    {
      predefinedScenarioType: 'Welcome',
      nodes: [
        {
          id: '1',
          type: 'VSNode',
          position: { x: 500, y: 350 },
          data: { label: 'onFirstVisit' },
          style: nodestyles.trigger,
        },
        {
          id: "MessageNode",
          type: 'VSNode',
          position: { x: 800, y: 350 },
          data: { label: 'Text' },
          style: nodestyles.action,
        },
      ],
      edges: [
        {
          id: 'e1-2',
          source: '1',
          target: "MessageNode",
          markerEnd: { type: MarkerType.ArrowClosed, width: 20 ,height:20},
        },
      ],
    },
  ],
}
