import React from 'react'
import { Stack } from '@fluentui/react'
import { EditableText } from '../editabletext'
import { IStackItemStyles } from '@fluentui/react'

interface TextPanelProps {
  items: any[]
  setText: Function
  nodeId: string
}

export const TextPanel: React.FunctionComponent<TextPanelProps> = (
  props: TextPanelProps,
) => {
  const findNode: any = (items: any[], id: string) => {
    return items.filter((item) => item.nodeId === id)
  }
  const { items, setText, nodeId } = props
  const [showItem, setShowItem] = React.useState(findNode(items, nodeId))
  const CardTextStyle: IStackItemStyles = {
    root: {
      width: 300,
      margin: 10,
      backgroundColor: '#fff',
    },
  }

  React.useEffect(() => {
    setShowItem(findNode(items, nodeId))
  }, [items,nodeId])

  return (
    <Stack.Item styles={CardTextStyle}>
      {showItem.map((item: any, index: any) => (
        <EditableText item={item} setText={setText} key={index} />
      ))}
    </Stack.Item>
  )
}
