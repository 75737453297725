import React from 'react'
import {
  Dropdown,
  IDropdownOption,
  Stack,
} from '@fluentui/react'
import { useTranslation } from 'react-i18next'

interface eventPanelProps {
  Event: string
  TriggerCondition: string
  setEvent: Function
  setTriggerCondition: Function
}

export const EventPanel: React.FunctionComponent<eventPanelProps> = (props) => {
  const {
    Event,
    TriggerCondition,
    setEvent,
    setTriggerCondition,
  } = props
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.scenarioConfigurate.eventPanel',
  })
  const dropDownStyles = {
    dropdown: { width: 250 },
  }
  const eventOptions: IDropdownOption[] = [
    { key: 'ADDTOCART', text: t('Shopify add to cart') },
    { key: 'REMOVEFROMCART', text: t('Shopify remove from cart') },
  ]
  const conditionOptions: IDropdownOption[] = [
    { key: 'ALWAYS', text: t('Always') },
    { key: 'ONETIME', text: t('Once per user') },
    { key: 'EVERYDAY', text: t('Once per 24 hours')},
  ]
  return (
    <Stack>
      <Dropdown
        placeholder={t('Select an event')}
        defaultSelectedKey={Event}
        label={t('Event type')}
        options={eventOptions}
        styles={dropDownStyles}
        onChange={(event, option) =>
          setEvent((option?.key as string))
        }
      />
      <Dropdown
        placeholder={t('Select trigger condition')}
        defaultSelectedKey={TriggerCondition}
        label={t('Scenario trigger condition')}
        options={conditionOptions}
        styles={dropDownStyles}
        onChange={(event, option) =>
          setTriggerCondition(
            (option?.key as string),
          )
        }
      />
    </Stack>
  )
}
