import { CopyOutlined, EditOutlined, FileTwoTone, RedoOutlined, RobotOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Input, Tooltip } from "antd";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { xonokai } from "react-syntax-highlighter/dist/esm/styles/prism";
import { IMessageContent } from "SRC/api/gptdalle";
import "./index.less"

const { TextArea } = Input;

interface UserChatContentProps {
  content: IMessageContent;
  mid: any;
  handleContentEdit: (mid: any, defaultContent: any) => void;
  editedContent: string;
  handleContentChange: (newContent: string) => void;
  onEditCancel: () => void;
  onReSend: () => void;
  contentEditingId: string;
}
  
interface BotChatContentProps {
  content: string;
  isLastItem: boolean;
  handleReGenerate: () => void;
}

export const MemoizedUserChatContent:React.FC<UserChatContentProps> = React.memo(({ content, mid, handleContentEdit, editedContent, handleContentChange, onEditCancel, onReSend, contentEditingId }) => {
  const handleChange = (event) => {
    const newContent = event.target.value;
    handleContentChange(newContent);
  };
  switch (content.type) {
    case 'text':
      return (
        mid === contentEditingId ? (
          <div className="chat-user">
            <div className="chat-user-input-wrap">
              <TextArea
                defaultValue={content.text}
                onChange={handleChange}
                value={editedContent}
                autoSize={{ minRows: 2, maxRows: 20 }}
                className="chat-user-edit-input"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    onReSend();
                  }
                }}
                onPressEnter={undefined}
              />
              <div>
                <Button className="chat-user-edit-button" onClick={onEditCancel}>取消</Button>
                <Button className="chat-user-edit-button" onClick={onReSend}>发送</Button>
              </div>
            </div>
            <div className="chat-user-icon">
              <UserOutlined />
            </div>
          </div>
        ) : (
          <div className="chat-user">
            <Tooltip title="编辑并重新发送">
              <div className="chat-user-edit-icon" onClick={() => handleContentEdit(mid, content.text)}>
                <EditOutlined />
              </div>
            </Tooltip>  
            <div className="chat-user-input">{content.text}</div>
            <div className="chat-user-icon">
              <UserOutlined />
            </div>
          </div>
        )
      )
    case 'image_url':
      return (
        <div className="chat-user">
          <img className="chat-user-image" src={content.imageUrl?.url} alt="image" />
          <div className="chat-user-icon">
            <UserOutlined />
          </div>
        </div>
      )
    case 'attachment':
      return (
        <div className="chat-user">
          <div>
            <FileTwoTone style={{ fontSize: '30px' }} />
          </div>
          {content.fileName}
          <div className="chat-user-icon">
            <UserOutlined />
          </div>
        </div>
      )
    default:
      return null;
  }
}, (prevProps, nextProps) => {
  return prevProps.content === nextProps.content && prevProps.contentEditingId === nextProps.contentEditingId && prevProps.editedContent === nextProps.editedContent;
});

// Memoized Bot Chat Content Component
export const MemoizedBotChatContent:React.FC<BotChatContentProps> = React.memo(({ content, isLastItem, handleReGenerate }) => {
  const Code = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "language-python");
      return !inline && match ? (
        <div style={{ position: 'relative' }}>
          <SyntaxHighlighter
            style={xonokai}
            language={match[1]}
            customStyle={{ paddingTop: '30px' }}
            PreTag="div"
            children={String(children).replace(/\n$/, "")}
            {...props}
          />
          <Tooltip title="复制">
            <Button
              type="default"
              size="small"
              style={{ position: 'absolute', top: 5, right: 5 }}
              className="copy-btn"
              icon={<CopyOutlined />}
              onClick={() => {
                navigator.clipboard.writeText(children);
              }}
            />
          </Tooltip>
        </div>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
  };
  return (
    <div className="chat-bot-wrapper">
      <div className="chat-bot">
        <div className="chat-bot-icon">
          <RobotOutlined />
        </div>
        <div className="chat-bot-input">
          <ReactMarkdown components={Code} children={content} />
        </div>
      </div>
      <div className="chat-bot-copy">
        <Tooltip title="复制">
          <Button
            type="default"
            size="small"
            className="copy-btn"
            icon={<CopyOutlined />}
            onClick={() => {
              navigator.clipboard.writeText(content || "");
            }}
          />
        </Tooltip>
        {isLastItem && (
          <Tooltip title="重新生成">
            <Button
              type="default"
              size="small"
              className="copy-btn"
              icon={<RedoOutlined />}
              onClick={handleReGenerate}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.content === nextProps.content && prevProps.isLastItem === nextProps.isLastItem;
});