import request from 'SRC/utils/request'

const AvatarApi = {
  Avatars: '/avatarmanagement/avatar/api/Avatar/merchant/avatars',
  Voices: '/avatarmanagement/avatar/api/Speech/voices',
  VirtualSales: '/avatarmanagement/avatar/api/VirtualSales',
  Merchant: '/avatarmanagement/avatar/api/Avatar/merchant',
  Update: '/avatarmanagement/avatar/api/VirtualSales/merchant/update',
  Upload: '/avatarmanagement/avatar/api/Avatar/customization/upload',
  VoiceConfig: '/avatarmanagement/avatar/api/Avatar/merchant/voice/config',
  ThreejsConfig: '/avatarmanagement/avatar/api/Avatar/merchant',
  Avatar: '/avatarmanagement/avatar/api/Avatar/merchant/avatar'
}

export function getAvatars () {
  return request({
    url: AvatarApi.Avatars,
    method: 'get'
  })
}

export function getAvatarVoices () {
  return request({
    url: AvatarApi.Voices,
    method: 'get'
  })
}

export function getVirtualSales () {
  return request({
    url: AvatarApi.VirtualSales,
    method: 'get'
  })
}

export function getMerchant (avatarId = '') {
  return request({
    url: `${AvatarApi.Merchant}/${avatarId}`,
    method: 'get'
  })
}

export function updateAvatar (parameter: any) {
  return request({
    url: AvatarApi.Update,
    method: 'post',
    data: parameter
  })
}

export function getPbiToken() {
  return request({
    url: '/pbi/api/EmbedInfo/MerchantId884fcd93-b2ad-4e6f-9278-a59a73346deb',
    method:'get',
  })
}

export function uploadAvatar (parameter: any) {
  return request({
    url: AvatarApi.Upload,
    method: 'post',
    data: parameter
  })
}

export function getVoiceConfig (gender: string) {
  return request({
    url: `${AvatarApi.VoiceConfig}?gender=${gender}`,
    method: 'get'
  })
}

export function getThreejsConfig (solution = 'threejs') {
  return request({
    url: `${AvatarApi.Merchant}/${solution}/config`,
    method: 'get'
  })
}

export function deleteAvatar(id: string) {
  return request({
    url: `${AvatarApi.Avatar}?id=${id}`,
    method: 'delete'
  })
}