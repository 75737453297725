import * as React from 'react'
import { Toggle } from '@fluentui/react/lib/Toggle'
import { Stack } from '@fluentui/react/lib/Stack'
import {
  DetailsHeader,
  IDetailsHeaderStyles,
  DetailsList,
  SelectionMode,
  IColumn,
  IDetailsListStyles,
  ICellStyleProps,
  DetailsRow,
  IDetailsRowStyles,
} from '@fluentui/react/lib/DetailsList'
import { useTranslation } from 'react-i18next'
import { Icon } from '@fluentui/react/lib/Icon'
import { useNavigate } from 'react-router-dom'
import { changeActivateStatus, fetchScenarioData } from 'SRC/api/scenario'
import './index.css'
import { globalMessage } from 'SRC/components/messagebar'
import { useSelector } from 'react-redux'

export interface IScenario {
  scenarioName: string
  triggerEventType: string
  predefinedScenarioType: string
  isActive: boolean
  periodType: string
  tag: string
  itemDict:any
  cookieDict:any
}

interface dataToggleProps {
  initChecked: boolean
  scenarioName: string
}

const DataToggle: React.FunctionComponent<dataToggleProps> = (props) => {
  const merchantID = useSelector((state: any) => state.userInfo.value)
  const { scenarioName, initChecked } = props
  const [checked, setChecked] = React.useState(initChecked)
  const toggle = () => {
    changeActivateStatus(
      merchantID,
      scenarioName,
      !checked,
    ).then((res) => setChecked(!checked))
  }
  return <Toggle checked={checked} onChange={toggle} />
}

const detailsListStyles: Partial<IDetailsListStyles> = {
  root: {
    marginLeft: 90,
    justifyContent: 'space-between',
    width: 1000,
    overflow: 'hidden',
  },
  contentWrapper: {},
}

const detailsHeaderStyle: Partial<IDetailsHeaderStyles> = {
  root: {
    padding: '0px !important',
  },
}

const cellStyle: ICellStyleProps = {
  cellLeftPadding: 10,
  cellRightPadding: 0,
  cellExtraRightPadding: 0,
}

const rowStyle: Partial<IDetailsRowStyles> = {
  cell: {
    textIndent: '0px',
  },
}

export const DetailsListDocumentsExample: React.FunctionComponent = () => {
  const { t } = useTranslation()
  let navigate = useNavigate()
  const [items, setItems] = React.useState([])
  const merchantID = useSelector((state: any) => state.userInfo.value)

  React.useEffect(() => {
    fetchScenarioData(
      merchantID,
    ).then((res: any) => {
      if (res.responseMesssage) {
        globalMessage(res.responseMesssage)
      } else {
        setItems(res.wfRecords)
      }
    }
  )}, [])

  const tagTypes:any = {
    'Promotion':t('pages.scenarioConfigurate.IncreaseSales'),
    'Welcome':t('pages.scenarioConfigurate.UserService'),
    'GetUserInfo':t('pages.scenarioConfigurate.GetUserInfo'),
  }

  const triggerTypes:any = {
    'ADDTOCART':t('pages.scenarioConfigurate.eventPanel.Shopify add to cart'),
    'REMOVEFROMCART':t('pages.scenarioConfigurate.eventPanel.Shopify remove from cart'),
    'VISITWELCOMEPAGE':t('pages.scenarioConfigurate.eventPanel.Visit welcome page'),
  }

  const columns: IColumn[] = [
    {
      key: 'scenarioName',
      name: t('pages.scenarioSetting.name'),
      fieldName: 'scenarioName',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      data: 'string',
    },
    {
      key: 'triggerEventType',
      name: t('pages.scenarioSetting.trigger'),
      fieldName: 'triggerEventType',
      minWidth: 200,
      maxWidth: 200,
      data: 'string',
      onRender: (item: IScenario) => {
        return triggerTypes[item.triggerEventType]
    },
    },
    {
      key: 'tag',
      name: t('pages.scenarioSetting.category'),
      fieldName: 'tag',
      minWidth: 200,
      maxWidth: 200,
      data: 'string',
      onRender: (item: IScenario) => {
        return tagTypes[item.tag]
      }
    },
    {
      key: 'isActive',
      name: t('pages.scenarioSetting.active'),
      fieldName: 'isActive',
      minWidth: 200,
      maxWidth: 200,
      data: 'boolean',
      onRender: (item: IScenario) => {
        return (
          <DataToggle
            initChecked={item.isActive}
            scenarioName={item.scenarioName}
          />
        )
      },
    },
    {
      key: 'configurate',
      name: t('pages.scenarioSetting.configurate'),
      fieldName: 'configurate',
      minWidth: 200,
      data: 'boolean',
      onRender: (item: IScenario) => {
        return (
          <Icon
            onClick={() => {
              navigate('Configurate', {
                state: {
                  scenarioName: item.scenarioName,
                  triggerEventType: item.triggerEventType,
                  predefinedScenarioType: item.predefinedScenarioType,
                  periodType: item.periodType,
                  tag: item.tag,
                },
              })
            }}
            iconName="Edit"
          />
        )
      },
    },
  ]

  return (
    <Stack className="scenarioSettingWrapper">
      <h1 className="scenarioSettingHeader">{t('navigator.ScenarioManagement')}</h1>
      <DetailsList
        items={items.reverse()}
        columns={columns}
        styles={detailsListStyles}
        selectionMode={SelectionMode.none}
        setKey="none"
        cellStyleProps={cellStyle}
        onRenderDetailsHeader={(props: any) => {
          return <DetailsHeader {...props} styles={detailsHeaderStyle} />
        }}
        onRenderRow={(props: any) => {
          return <DetailsRow {...props} styles={rowStyle} />
        }}
        isHeaderVisible={true}
      />
    </Stack>
  )
}
