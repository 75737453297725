import React from 'react'
import {
  Link,
  TextField,
  Stack,
  IStackItemStyles,
} from '@fluentui/react'
interface EditableTextProps {
  item: any
  setText:Function
}

export const EditableButton: React.FunctionComponent<EditableTextProps> = (
  props,
) => {
  const { item,setText } = props
  const [editing, setEditing] = React.useState(false)
  const [innerText, setInnerText] = React.useState(item.content)
  const CardButtonStyle: IStackItemStyles = {
    root: {
      padding: 10,
      textAlign: 'center',
    },
  }
  let showContent = !editing ? (
    <Stack.Item styles={CardButtonStyle} onClick={() => setEditing(true)}>
            <Link  styles={{ root: { fontSize: 12 } }}>
              {innerText}
            </Link>
          </Stack.Item>
  ) : (
    <TextField
      autoAdjustHeight
      autoFocus
      defaultValue={innerText}
      onBlur={() => setEditing(false)}
      onChange={(ev, newValue) => {
        setInnerText(newValue as string)
        setText(newValue as string,item.itemId)
      }}
    />
  )
  return showContent
}