import request from 'SRC/utils/request'

const LoginAPI = {
    Login: '/api/user/atptp_login',
    Verification: '/api/user/verification_code',
    Token: '/api/user/atptp_refresh',
    Verify: '/api/user/atptp_verify',
    Logout:'/api/user/atptp_logout'
}

interface ATPLoginData{
    phone_number: string,
    password?: string,
    code?: string
    type: string
}

export function ATPLogin (data: ATPLoginData)  {
    return request({
        url: LoginAPI.Login,
        method: 'post',
        data:data
    })
}

export const VerificationCode = (data: {phone_number: string}) => {
    return request({
        url: LoginAPI.Verification+`?phone_number=${data.phone_number}`,
        method: 'get',
        data:data
    })
}

export const RefreshToken = () => {
    return request({
        url: LoginAPI.Token,
        method: 'post',
    })
}

export const Verify = () =>{
    return request({
        url: LoginAPI.Verify,
        method: 'get',
    })
}

export const Logout = () =>{
    return request({
        url: LoginAPI.Logout,
        method: 'get',
    })
}