import React, { useEffect, useRef, useState } from "react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import {
  Stack,
  TextField,
  Label,
  DefaultButton,
} from "@fluentui/react";
import { Button, Drawer } from "antd";
import {
  LeftCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./index.less";

const buttonStyles = {
  root: {
    width: '100%',
    height: '48px',
    borderRadius: '5px',    
    background: "#F0F2F5",
    border: '1px solid #F0F2F5',
    marginTop: '30px',
    selectors: {
      ':hover': {
        border: '1px solid #0078D4',
        background: '#fff !important'
      },
      ':hover .ms-Button-label, :hover .ms-Icon' : {
        color: '#0078D4'      
      }
    }
  },
  label: {
    fontSize: '18px',
    fontWeight: '400'
  },
  icon: {
    fontSize: '20px'
  }
}

const selectedButtonStyles = {
  ...buttonStyles,
  root: {
    ...buttonStyles.root,
    border: '1px solid #0078D4',
    background: '#fff'
  },
  label: {
    ...buttonStyles.label,
    color: '#0078D4'
  },
  icon: {
    ...buttonStyles.icon,
    color: '#0078D4'
  },
}

const isCopyButtonStyles = {
  ...buttonStyles,
  root: {
    ...buttonStyles.root,
    border: '1px solid #a19f9d !important',
    background: '#fff',
    selectors: {
      ':hover': {
        border: '1px solid #a19f9d',
        background: '#fff !important'
      },
      ':hover .ms-Button-label, :hover .ms-Icon' : {
        color: '#a19f9d'      
      }
    }
  },
  label: {
    ...buttonStyles.label,
    color: '#a19f9d'
  },
  icon: {
    ...buttonStyles.icon,
    color: '#a19f9d'
  },
}

export const Trial: React.FunctionComponent = () => {
  // https://atptp-staging.chinanorth3.cloudapp.chinacloudapi.cn/static/js/main.ec3d96a5d5.js
  const { t } = useTranslation();
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const buttonId = useId("callout-button");
  const labelId = useId("callout-label");
  const descriptionId = useId("callout-description");
  const codeText = `<div id="body"><script defer="defer" src="https://${
    window.location.host
  }/customerchatwidget/static/js/atptp-chat-widget.js?BotId=${useSelector(
    (state: any) => state.userInfo.value
  )}"></script></div>`;

  let container = useRef(null);

  const [open, setOpen] = useState(false);
  const [type, setType] = useState('shop');
  const [isCopy, setIsCopy] = useState(false)

  useEffect(() => {
    const bodyElement = document.querySelector("#body");
    if (!bodyElement) {
      let div = document.createElement("div");
      div.id = "body";
      document.body.appendChild(div);
      let script = document.createElement("script");
      script.id = "script";
      // script.type = "text/javascript";
      script.defer = true;
      script.src = "/customerchatwidget/static/js/atptp-chat-widget.js";
      document.body.appendChild(script);
    }
    return removeChatBot
  }, []);

  const removeChatBot = () => {
    const script = document.getElementById("script");
    const bodyElement = document.querySelector("#body");
    if (bodyElement && script) {
      document.body.removeChild(bodyElement)
      document.body.removeChild(script)
    }
  }

  const copy = async () => {
    if ("clipboard" in navigator) {
      setIsCopy(true)
      setTimeout(() => setIsCopy(false), 1000)
      return await navigator.clipboard.writeText(codeText);
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div
      ref={container}
      style={{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "url(" + require("../../assets/imgs/eBusiness.jpg") + ")",
      }}
    >
      <div className="record-wrapper">
        <Button
          icon={<LeftCircleOutlined />}
          type="primary"
          style={{ position: 'static' }}
          onClick={showDrawer}
        />
      </div>
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
        getContainer={false}
      >
        <div className="drawer-header" onClick={onClose}>
          <RightCircleOutlined className="drawer-icon-right" />
          <span className="drawer-header-text">折叠工具栏</span>
        </div>
        <div className="drawer-content">
          <Stack tokens={{ childrenGap: "20" }}>
            <DefaultButton
              iconProps={{ iconName: 'Shop' }}
              text="切换背景图—电商"
              onClick={() => {
                (container.current as any).style.backgroundImage =
                  "url(" + require("../../assets/imgs/eBusiness.jpg") + ")";
                  setType('shop')
              }}
              styles={type === 'shop' ? selectedButtonStyles : buttonStyles}
            />
            <DefaultButton
              iconProps={{ iconName: 'Education' }}
              text="切换背景图—教育"
              onClick={() => {
                (container.current as any).style.backgroundImage =
                  "url(" + require("../../assets/imgs/edu.jpg") + ")";
                  setType('education')
              }}
              styles={type === 'education' ? selectedButtonStyles : buttonStyles}
            />
            <DefaultButton
              iconProps={{ iconName: 'CannedChat' }}
                text="切换背景图—社交"
                onClick={() => {
                  (container.current as any).style.backgroundImage =
                    "url(" + require("../../assets/imgs/social.jpg") + ")";
                    setType('chat')
                }}
                styles={type === 'chat' ? selectedButtonStyles : buttonStyles}
              />
          </Stack>
          <Label styles={{root: {marginTop: 40, fontSize: 20}}}>嵌入方式</Label>
          <Stack tokens={{ childrenGap: "70" }}>
            <label style={{marginTop: 20}}>如需嵌入请在网页加入以下代码：</label>
            <TextField
              multiline
              rows={10}
              disabled
              defaultValue={codeText}
              resizable={false}
              styles={{root: {marginTop: '20px!important'}}}
            />
            <DefaultButton
              iconProps={{ iconName: 'Copy' }}
              text={isCopy ? "已复制" : "一键复制代码"}
              onClick={copy}
              styles={isCopy ? isCopyButtonStyles : selectedButtonStyles}
              disabled={isCopy}
            />
          </Stack>
        </div>
      </Drawer>
    </div>
  );
};
