import React from "react";
import { IButtonStyles, PrimaryButton } from "@fluentui/react";
import "./index.less";

const buttonStyles: IButtonStyles = {
  root: {
    marginTop: 10,
    border: "none",
    borderRadius: "2px",
    width: 108,
    height: 32,
  },

  label: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Microsoft YaHei-Regular, Microsoft YaHei",
  },
};

export const MLStudio: React.FunctionComponent = () => {
  return (
    <div className="mlstudio-wrapper">
      <div className="mlstudio-content">
        <h1>Welcome to the Azure Machine Learning Studio</h1>
        <h3>欢迎使用Azure Machine Learning Studio</h3>
        <p>点击下方按钮，立即体验！</p>
        <a href="https://ml.azure.com/" target="_blank" rel="noopener noreferrer">
          <PrimaryButton styles={buttonStyles}>
            立即体验
          </PrimaryButton>
        </a>
      </div>
    </div>
  );
};
