import * as React from 'react';
import {
    Text,
    FocusTrapCallout,
    FocusZone,
    FocusZoneTabbableElements,
    Stack,
    PrimaryButton,
    DefaultButton,
    mergeStyleSets,
    FontWeights
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';

const styles = mergeStyleSets({
    callout: {
      width: 320,
      padding: '20px 24px',
    },
    title: {
      marginBottom: 12,
      fontWeight: FontWeights.semilight,
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
    },
  });
const buttonStyles = {
    root: {
        minWidth: 50,
        padding: '0px 6px'
    }
}

interface IDeleteCallout {
    buttonRender?: any,
    title?: string,
    content: string,
    doneText?: string,
    cancelText?: string,
    doneFunction?: Function,
    cancelFuntion?: Function,
    renderStyles?: any
}

export const DeleteCallout: React.FunctionComponent<IDeleteCallout> = (props: IDeleteCallout) => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    const { buttonRender, title, content, doneText, cancelText, doneFunction, cancelFuntion, renderStyles } = props

    const onDone = async () => {
        if (doneFunction) {
            await doneFunction()
        }
        toggleIsCalloutVisible()
    }

    const onCancel = async () => {
        if (cancelFuntion) {
            await cancelFuntion()
        }
        toggleIsCalloutVisible()
    }

    return (
        <>
            <Stack
                id={buttonId}
                onClick={toggleIsCalloutVisible}
                styles={{
                    ...renderStyles,
                    root: {
                        ...renderStyles?.root,
                        display: 'inline-block'
                    }
                }}>
                {buttonRender() || <DefaultButton text="Delete" />}
            </Stack>
            {isCalloutVisible ? (
                <FocusTrapCallout
                    role="alertdialog"
                    className={styles.callout}
                    gapSpace={0}
                    target={`#${buttonId}`}
                    onDismiss={toggleIsCalloutVisible}
                    setInitialFocus
                >
                    {title && (
                        <Text block variant="xLarge" className={styles.title}>
                            {title}
                        </Text>
                    )}
                    {content && (
                        <Text block variant="medium">
                            {content}
                        </Text>
                    )}
                    <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                        <Stack className={styles.buttons} gap={8} horizontal>
                        <PrimaryButton styles={buttonStyles} onClick={onDone}>{doneText || 'Done'}</PrimaryButton>
                        <DefaultButton styles={buttonStyles} onClick={onCancel}>{cancelText || 'Cancel'}</DefaultButton>
                        </Stack>
                    </FocusZone>
                </FocusTrapCallout>
            ) : null}
        </>
    )
}