import React from 'react'
import { Image, IImageProps, ImageFit, Stack,Icon } from '@fluentui/react'
import { uploadImage,mediaURLPrefix } from 'SRC/api/scenario'
import './index.css'
interface UploadableImageProps {
  item: any
  setImage: Function
}
export const UploadableImage: React.FunctionComponent<UploadableImageProps> = (
  props,
) => {
  const { item, setImage } = props
  const hiddenFileInput = React.useRef<any>(null)
  const [imageUrl, setImageUrl] = React.useState(item.content!=='null'?item.content:'https://wictest.blob.core.chinacloudapi.cn/fakeapiserver/addImage.jpg')
  const [imageUploaded,setImageUploaded]=React.useState(item.content!=='null'?true:false)
  const [imageURLPrefix,setImageURLPrefix]=React.useState('')

  React.useEffect(() => {
    mediaURLPrefix().then((res:any)=>setImageURLPrefix(res))
  }, [])

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0]
    uploadImage(fileUploaded).then((res:any) => 
    {
    setImageUrl(res[0].url)
    setImage(res[0].url,item.itemId)
  })
    setImageUploaded(true)
  }
  const handleClick = (event: any) => {
    if (!imageUploaded){
    hiddenFileInput.current.click()
    }
  }
  const onDelete=(event:any)=>{
    setImageUrl('https://wictest.blob.core.chinacloudapi.cn/fakeapiserver/addImage.jpg')
    setImageUploaded(false)
    setImage('null',item.itemId)
    }
  const onReUpload=(event:any)=>{
    hiddenFileInput.current.click()
    }
  const imageProps: IImageProps = {
    imageFit: ImageFit.contain,
    src: imageUrl,
    className: 'cardImage',
    shouldStartVisible: true,
    styles: {
      root: {
        width: 300,
        borderRadius: '0px 10px 0px 0px',
        cursor: 'pointer',
        transition:' .5s ease',
        backfaceVisibility: 'hidden',
      },
    },
  }
 
  const cardImageFunctionalBarStyle = {
    root:{
        padding:40,
        fontSize:40,
        cursor:'pointer',
        hidden:!imageUploaded,
    }
  }

  const cardImageFunctionBarWrapperStyle = {
    root:{ 
        transition: ".5s ease",
        opacity: 0,
        position: "absolute",
        bottom: imageUploaded?0:"50%",
        top:imageUploaded?"50%":0,
        left: "50%",
        transform:" translate(-50%, -50%)",
        msTransform: "translate(-50%, -50%)",
        textAlign: "center",
        justifyContent: "space-between",
        zIndex: imageUploaded?1:-1,
      }
    }
  return (
    <div className={imageUploaded?'cardImageContainer':undefined}>
      <Image {...imageProps} onClick={handleClick} />
      <Stack horizontal className='cardImageFunctionBar' styles={cardImageFunctionBarWrapperStyle as any}>
        <Stack.Item onClick={onReUpload} styles={cardImageFunctionalBarStyle}>
            <Icon iconName='Photo2Add'/>
        </Stack.Item>
        <Stack.Item onClick={onDelete} styles={cardImageFunctionalBarStyle}>
            <Icon iconName='Delete'/>
        </Stack.Item>
      </Stack>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </div>
  )
}
