import { configureStore } from '@reduxjs/toolkit'
import { isAdminSlice, userInfoSlice, appsSlice, nickNameSlice } from './slice';

export default configureStore({
  reducer: {
    isAdmin: isAdminSlice.reducer,
    userInfo: userInfoSlice.reducer,
    apps: appsSlice.reducer,
    nickName: nickNameSlice.reducer,
  },
})