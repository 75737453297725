import React from "react";
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import "./index.css";
import {
  IMessageBarStyleProps,
  IMessageBarStyles,
  IStyleFunctionOrObject,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";

const Message: React.FC<{
  message: string;
  type?: number;
  onDismiss?: () => void;
  styleTop?: number;
}> = (props) => {
  const { message, type, onDismiss, styleTop } = props;
  const { t } = useTranslation();
  const messageBarStyle: IStyleFunctionOrObject<
    IMessageBarStyleProps,
    IMessageBarStyles
  > = {
    root: {
      position: "absolute",
      top: `${styleTop || "50"}px`,
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 10000001,
      maxWidth: "523px",
      height: "44px",
      borderRadius: "5px",
    },
  };
  return (
    <MessageBar
      messageBarType={type || MessageBarType.error}
      isMultiline={false}
      onDismiss={onDismiss}
      dismissButtonAriaLabel="Close"
      styles={messageBarStyle}
    >
      {t(message)}
    </MessageBar>
  );
};

Message.defaultProps = {
  onDismiss: () => {},
};

export default Message;

export const globalMessage = (
  message: string,
  type?: number,
  onDismiss?: () => void,
  styleTop?: number
) => {
  let messageDomWrapper = document.querySelector("#messageDomWrapper");
  if (!messageDomWrapper) {
    messageDomWrapper = document.createElement("div");
    messageDomWrapper.setAttribute("id", "messageDomWrapper");
    document.body.appendChild(messageDomWrapper);
  }
  const onDismissNew =
    onDismiss ??
    (() => {
      unmountComponentAtNode(messageDomWrapper as any);
    });
  ReactDOM.render(
    <Message
      message={message}
      onDismiss={onDismissNew}
      type={type}
      styleTop={styleTop}
    />,
    messageDomWrapper
  );
  setTimeout(() => {
    messageDomWrapper?.remove();
  }, 2000);
};
