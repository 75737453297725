import moment from "moment";

export const getTimeGroup = (utcTimestamp) => {
  // 将UTC时间戳转换成本地时区的时间对象
  const setTimeObj = moment(utcTimestamp).local();
  // 创建一个表示今天的时间对象
  const today = moment().startOf("day");
  // 创建一个表示昨天的时间对象
  const yesterdayLocalTimeObj = moment().subtract(1, "days").startOf("day");

  if (setTimeObj.isSame(today, "d")) {
    return "today";
  } else if (setTimeObj.isSame(yesterdayLocalTimeObj, "d")) {
    return "yesterday";
  } else {
    return "earlier";
  }
};