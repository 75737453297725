import React, { useState, useEffect } from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import {
  Table,
  Space,
  Button,
  Typography,
  Input,
  UploadProps,
  Upload,
  Popconfirm,
  Tooltip,
  Form,
  ColorPicker,
  Modal,
  Flex,
} from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  UploadOutlined,
  BgColorsOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  SearchBox,
  ISearchBoxStyles,
  IStackStyles,
  DefaultButton,
  PrimaryButton,
  Dialog,
  DialogType,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { globalMessage } from "SRC/components/messagebar";
import {
  getAllGraphScenarios,
  deleteDataStoreMgmt,
  deleteGraphScenarios,
  uploadScenario,
  downloadScenario,
  searchGraphScenarios,
  getConfigEntitycolor,
  postConfigEntitycolor,
} from "SRC/api/smartkg";
import "./index.less";
import { FileIcon, FolderIcon } from "SRC/assets/icons/icons";
import { createDataStoreMgmt } from "SRC/api/smartkg";

type DatabaseProps = {
  name: string;
};

type DataProps = {
  name: string;
  father: string;
};

const stackWrapperStyles: IStackStyles = {
  root: {
    padding: "18px 38px",
  },
};

const stackStyles: IStackStyles = {
  root: {
    background: "#fff",
    padding: "10px",
  },
};

const searchboxStyles: Partial<ISearchBoxStyles> = {
  root: {
    width: "calc(100% - 120px)",
    borderColor: "#ccc",
    ":hover": {
      borderColor: "#ccc",
    },
  },
};

interface IDataDialogProps {
  newDataDialog: boolean;
  setNewDataDialog: Function;
  datastoreName: any;
  allScenarios: any;
  setAllScenarios: Function;
}

const DataDialog: React.FunctionComponent<IDataDialogProps> = (props) => {
  const {
    newDataDialog,
    setNewDataDialog,
    datastoreName,
    allScenarios,
    setAllScenarios,
  } = props;
  const [scenarioNames, setScenarioNames] = useState("");
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loadingModal, setLoadingModal] = useState(false)

  const uploadProps: UploadProps = {
    name: "UploadFile",
    maxCount: 1,
    multiple: false,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        globalMessage(`${info.file.name} file uploaded successfully`, 4);
      } else if (info.file.status === "error") {
        globalMessage(`${info.file.name} file upload failed.`, 0);
      }
    },
    beforeUpload: (file) => false,
  };

  const onSubmit = (values: any) => {
    let uploadform = new FormData();
    uploadform.append("Scenario", scenarioNames);
    uploadform.append("UploadFile", values.UploadFile[0].originFileObj);
    uploadform.append("DatastoreName", datastoreName);
    console.log(uploadform);
    setLoadingModal(true)
    uploadScenario(uploadform)
      .then((res:any) => {
        if (res.success)
        {
        globalMessage("上传成功", 4);
        let newAllScenarios = { ...allScenarios };
        newAllScenarios[datastoreName].push(scenarioNames);
        setAllScenarios(newAllScenarios);
        setNewDataDialog(true);
        }
        else
        {
          globalMessage("已经存在同名数据，请修改名称", 1);
        }
      })
      .catch((err) => {
        globalMessage("已经存在同名数据，请修改数据名称", 1);
      })
      .finally(() => {
        setScenarioNames("");
        setLoadingModal(false)
      });
  };

  const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Dialog
      key="CreateNewData"
      hidden={newDataDialog}
      onDismiss={() => setNewDataDialog(true)}
      dialogContentProps={{
        type: DialogType.normal,
        title: String(`上传${datastoreName}数据`),
        closeButtonAriaLabel: "Close",
        subText: String("请选择数据文件"),
      }}
      modalProps={{
        isBlocking: true,
        styles: {
          main: { maxWidth: "450px !important", width: "450px !important" },
        },
      }}
    >
      <Form form={form} onFinish={onSubmit}>
        <Form.Item
          name="Scenario"
          rules={[{ required: true, message: "请输入场景名称" }]}
        >
          <Input
            placeholder="请输入场景名称"
            onChange={(e) => setScenarioNames(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="UploadFile"
          rules={[{ required: true, message: "请选择文件" }]}
          getValueFromEvent={normFile}
        >
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />} type="primary">
              选择文件
            </Button>
          </Upload>
        </Form.Item>
        <div className="dialog-footer" style={{ marginTop: 20 }}>
          <Form.Item>
            <Button
              loading={loadingModal}
              style={{ marginRight: "10px" }}
              type="primary"
              htmlType="submit"
            >
              {t("pages.QAManagement.setting.dialogBtnConfirm") as string}
            </Button>
          </Form.Item>
          <Button
            onClick={() => {
              setNewDataDialog(true);
              setScenarioNames("");
            }}
          >
            {t("pages.QAManagement.setting.dialogBtnCancel") as string}
          </Button>
        </div>
      </Form>
    </Dialog>
  );
};

export const Database: React.FunctionComponent = () => {
  const { Title } = Typography;
  const { t } = useTranslation();
  dayjs.extend(utc);

  const [loadingData, setLoadingData] = useState(false);
  const [newDatabaseDialog, setNewDatabaseDialog] = useState(true);
  const [newDataDialog, setNewDataDialog] = useState(true);
  const [colorPickerDialog, setColorPickerDialog] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [allScenarios, setAllScenarios] = useState({});
  const [newStoreName, setNewStoreName] = useState("");
  const [currentDialog, setCurrentDialog] = useState("");
  const [entityColorConfig, setEntityColorConfig] = useState({});
  const [entityConfig, setEntityConfig] = useState({
    datastoreName: "",
    scenarioName: "",
  });
  const [loadingModal, setLoadingModal] = useState(false);

  useEffect(() => {
    if (searchValue === "") {
      setLoadingData(true);
      getAllGraphScenarios()
        .then((res) => {
          setAllScenarios(res.data);
        })
        .catch((err) => {
          globalMessage("获取数据失败", 1);
        })
        .finally(() => {
          setLoadingData(false);
        });
    }
  }, [searchValue]);

  const DatabaseColumn: ColumnsType<DatabaseProps> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => (
        <>
          <FolderIcon style={{ marginRight: 8 }} />
          {text}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="上传数据">
            <Button
              type="primary"
              shape="circle"
              icon={<UploadOutlined />}
              onClick={() => {
                setCurrentDialog(record.name);
                setNewDataDialog(false);
              }}
            />
          </Tooltip>
          <Popconfirm
            placement="left"
            title="删除数据后无法恢复，确认删除？"
            description="请确认删除该数据。"
            onConfirm={(e) => {
              e?.stopPropagation();
              setLoadingData(true);
              deleteDataStoreMgmt(record.name)
                .then((res) => {
                  globalMessage("删除成功", 4);
                  setAllScenarios((allScenarios) => {
                    let newAllScenarios = { ...allScenarios };
                    delete newAllScenarios[record.name];
                    return newAllScenarios;
                  });
                })
                .catch((err) => {
                  globalMessage("删除失败", 1);
                })
                .finally(() => {
                  setLoadingData(false);
                });
            }}
            onCancel={(e: any) => e.stopPropagation()}
            okText="确认"
            cancelText="取消"
          >
            <Tooltip title="删除数据">
              <Button
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                danger
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const DataCoulumn: ColumnsType<DataProps> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => (
        <>
          <FileIcon style={{ marginRight: 8 }} />
          {text}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="设置颜色">
            <Button
              type="primary"
              shape="circle"
              icon={<BgColorsOutlined />}
              onClick={() => {
                setLoadingData(true);
                getConfigEntitycolor(record.father, record.name)
                  .then((res: any) => {
                    if (res.success) {
                      setEntityColorConfig(res.entityColorConfig);
                      setColorPickerDialog(true);
                      setEntityConfig({
                        datastoreName: record.father,
                        scenarioName: record.name,
                      });
                    } else {
                      globalMessage(res.responseMessage);
                    }
                  })
                  .catch((err) => {
                    globalMessage(err);
                  })
                  .finally(() => {
                    setLoadingData(false);
                  });
              }}
            />
          </Tooltip>
          <Tooltip title="下载数据">
            <Button
              type="primary"
              shape="circle"
              icon={<DownloadOutlined />}
              onClick={() => {
                setLoadingData(true);
                downloadScenario(record.father, record.name)
                  .then((res: any) => {
                    console.log(res);
                    console.log(res.data);
                    const url = window.URL.createObjectURL(res.data);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                      "download",
                      `${record.father}_${record.name}.xlsx`
                    ); // 指定下载的文件名
                    document.body.appendChild(link);
                    link.click();

                    // 清理资源
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                  })
                  .catch((err) => {
                    globalMessage(err);
                  })
                  .finally(() => {
                    setLoadingData(false);
                  });
              }}
            />
          </Tooltip>
          <Popconfirm
            placement="left"
            title="删除数据后无法恢复，确认删除？"
            description="请确认删除该数据。"
            onConfirm={(e) => {
              e?.stopPropagation();
              setLoadingData(true);
              deleteGraphScenarios(record.father, record.name)
                .then((res) => {
                  globalMessage("删除成功", 4);
                  setAllScenarios((allScenarios) => {
                    let newAllScenarios = { ...allScenarios };
                    newAllScenarios[record.father] = newAllScenarios[
                      record.father
                    ].filter((item) => item !== record.name);
                    return newAllScenarios;
                  });
                })
                .catch((err) => {
                  globalMessage("删除失败", 1);
                })
                .finally(() => {
                  setLoadingData(false);
                });
            }}
            onCancel={(e: any) => e.stopPropagation()}
            okText="确认"
            cancelText="取消"
          >
            <Tooltip title="删除数据">
              <Button
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                danger
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const expandableDataRender = (record) => {
    return (
      <Table
        rowKey="id"
        showHeader={false}
        columns={DataCoulumn}
        dataSource={allScenarios[record.name].map((item: any) => ({
          name: item,
          father: record.name,
        }))}
        pagination={false}
      />
    );
  };

  const onChangeSearch = (
    event?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string
  ): void => {
    setSearchValue(newValue || "");
  };

  const onSearch = (newValue: string) => {
    setLoadingData(true);
    searchGraphScenarios(searchValue)
      .then((res) => {
        setAllScenarios(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const onCreateNewDatabase = () => {
    setLoadingModal(true)
    createDataStoreMgmt(newStoreName)
      .then((res:any) => {
        if (res.success)
        {
          setNewDatabaseDialog(true);
          setAllScenarios({ ...allScenarios, [newStoreName]: [] });
          globalMessage("创建成功", 4);
        }
        else
        {
          globalMessage("已存在同名数据库", 1);
        }
      })
      .catch((err) => {
        globalMessage("已存在同名数据库", 1);
      })
      .finally(() => setLoadingModal(false));
  };

  const onSaveEntityColorConfig = () => {
    setLoadingModal(true);
    postConfigEntitycolor(
      entityConfig.datastoreName,
      entityConfig.scenarioName,
      entityColorConfig
    )
      .then((res: any) => {
        if (res.success) {
          onCancelModal();
          globalMessage("颜色设置成功", 4);
        } else {
          globalMessage(res.responseMessage);
        }
      })
      .catch((err) => {
        globalMessage(err);
      })
      .finally(() => setLoadingModal(false));
  };

  const onCancelModal = () => {
    setColorPickerDialog(false);
  };

  return (
    <Stack styles={stackWrapperStyles}>
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{ root: { marginBottom: "28px" } }}
      >
        <Stack horizontal styles={{ root: { width: "50%" } }}>
          <SearchBox
            placeholder={t("pages.SmartKG.searchBoxPlaceholder") as string}
            onSearch={(newValue) => onSearch(newValue)}
            onChange={onChangeSearch}
            styles={searchboxStyles}
          />
          <DefaultButton
            styles={{
              root: {
                padding: "0px",
                minWidth: "40px",
                borderLeft: "0px",
                borderColor: "#ccc",
              },
            }}
            iconProps={{ iconName: "Forward" }}
            onClick={() => onSearch(searchValue)}
          />
        </Stack>
        <Stack>
          <PrimaryButton
            text={t("pages.SmartKG.createNewDatabase") as string}
            onClick={() => setNewDatabaseDialog(false)}
          />
        </Stack>
      </Stack>
      <Stack styles={stackStyles}>
        <Title level={5} style={{ marginTop: 8 }}>
          数据仓库
        </Title>
        <Table
          rowKey="name"
          expandable={{
            expandedRowRender: expandableDataRender,
            defaultExpandedRowKeys: ["0"],
          }}
          showHeader={false}
          columns={DatabaseColumn}
          dataSource={Object.keys(allScenarios).map((key) => ({ name: key }))}
          pagination={{ pageSize: 4 }}
          loading={loadingData}
        />
      </Stack>
      <Dialog
        key="CreateNewDatabase"
        hidden={newDatabaseDialog}
        onDismiss={() => setNewDatabaseDialog(true)}
        dialogContentProps={{
          type: DialogType.normal,
          title: String("新建数据库"),
          closeButtonAriaLabel: "Close",
          subText: String("请输入数据库名称"),
        }}
        modalProps={{
          isBlocking: true,
          styles: {
            main: { maxWidth: "450px !important", width: "450px !important" },
          },
        }}
      >
        <Input
          style={{ marginBottom: 20 }}
          onChange={(e) => {
            setNewStoreName(e.target.value);
          }}
        />
        <div className="dialog-footer">
          <Button
            loading={loadingModal}
            onClick={onCreateNewDatabase}
            style={{ marginRight: "10px" }}
            type="primary"
          >
            {t("pages.QAManagement.setting.dialogBtnConfirm") as string}
          </Button>
          <Button onClick={() => setNewDatabaseDialog(true)}>
            {t("pages.QAManagement.setting.dialogBtnCancel") as string}
          </Button>
        </div>
      </Dialog>
      <DataDialog
        newDataDialog={newDataDialog}
        setNewDataDialog={setNewDataDialog}
        datastoreName={currentDialog}
        allScenarios={allScenarios}
        setAllScenarios={setAllScenarios}
      />
      <Modal
        title="设置颜色"
        open={colorPickerDialog}
        centered
        footer={null}
        onCancel={onCancelModal}
      >
        <Flex
          style={{ maxHeight: "60vh", overflow: "auto", margin: "30px 0" }}
          justify="space-between"
          wrap="wrap"
          gap="middle"
        >
          {Object.keys(entityColorConfig).map((key) => (
            <Flex style={{ width: 220 }} justify="space-between" align="center">
              <div style={{ width: 95 }}>{key}</div>
              <ColorPicker
                style={{ width: 125 }}
                showText
                value={entityColorConfig[key]}
                onChange={(e, value) =>
                  setEntityColorConfig((entityColorConfig) => {
                    const newEntityColorConfig = { ...entityColorConfig };
                    newEntityColorConfig[key] = value;
                    return newEntityColorConfig;
                  })
                }
              />
            </Flex>
          ))}
        </Flex>
        <div className="dialog-footer">
          <Button
            loading={loadingModal}
            onClick={onSaveEntityColorConfig}
            style={{ marginRight: "10px" }}
            type="primary"
          >
            {t("pages.QAManagement.setting.dialogBtnConfirm") as string}
          </Button>
          <Button onClick={onCancelModal}>
            {t("pages.QAManagement.setting.dialogBtnCancel") as string}
          </Button>
        </div>
      </Modal>
    </Stack>
  );
};
